import { Button } from 'antd';
import styled from 'styled-components';

export const PageWrapper = styled.div`
    padding: 8px 16px;
`

export const TabItemWrapper = styled.div`
    margin-top: 8px;
`

export const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`