import $api from '../http';

export default class PlanningService {

    static async get(data) {
        try {
            return await $api.post('/get-planning', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async createOrEdit(data) {
        try {
            return await $api.post('/create-or-edit-planning', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async getTypes(data) {
        try {
            
            return await $api.post('/get-planning-type', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async createNewType(data) {
        try {
            
            return await $api.post('/create-planning-type', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async deleteType(data) {
        try {
            return await $api.post('/delete-planning-type', data);
        } catch(e) {
            console.log(e)
        }
    }
}