import { Modal, Tooltip } from 'antd';
import React from 'react';
import { IconButton } from '../StyledComponents/styled';
import { X } from 'react-bootstrap-icons';

export const TooltipDelete = ({ deleteFunction, openNotification, id }) => {    
    return (
        <Tooltip title="Удалить">
            <IconButton onClick={() => {
                Modal.confirm({
                    onOk: () => {
                        deleteFunction(id);
                        openNotification('Успешно!', 'Удалено!', 'info')
                    },
                    title: 'Удалить',
                    content: 'Подтвердите удаление',
                    footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                            <CancelBtn />
                            <OkBtn />
                        </>
                    ),
                })
            }} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                icon={
                    <X style={{ 'color': '#ef423e', 'width': '18px', 'height': '18px' }} />
                } />
        </Tooltip>
    );
};

export default TooltipDelete;