import { Form, Input } from 'antd';
import React from 'react';
import driversService from '../../services/DriverService';
import { ButtonPrimary } from '../../styles/buttons';
import dayjs from 'dayjs';
import moment from 'moment';

const EditDebt = ({ setOpen, openPayment, getPaymentsInfo }) => {

    const onFinish = async (values) => {

        let newPayment = Object.assign({}, openPayment);
        newPayment.sum = +values.sum;
        newPayment.type = 'debt';
        newPayment.comment = `Списание части долга от ${openPayment.date} (${openPayment.comment})`;
        newPayment.date = moment();
        delete newPayment._id;
        await driversService.addPayment(newPayment)
        openPayment.sum =  openPayment.sum - values.sum
        openPayment.date =  dayjs(openPayment.date, 'DD/MM/YYYY');
        await driversService.editPayment(openPayment)
        setOpen(false);
        await getPaymentsInfo();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div>
            <Form
                name="editPayment"
                layout='horizontal'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={{
                    ...openPayment,
                }}

            >
                <Form.Item
                    label="Сумма"
                    name="sum"
                >
                    <Input type='number' />
                </Form.Item>

                <ButtonPrimary type="primary" htmlType="submit">
                    Сохранить
                </ButtonPrimary>
            </Form>
        </div>
    );
};

export default EditDebt;