import React, { useEffect, useState } from 'react';

import { ButtonsWrapper, ButtonPrimary } from '../../styles/buttons';
import CompaniesTable from './CompaniesTable';

const Сompanies = ({ openNotification }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        document.title = "Мои компании - ГрузДок - сервис учета грузоперевозок"
     }, []);

    const showModal = () => {
        setOpen(true);
    };

    return (
        <>
            <ButtonsWrapper>
                <ButtonPrimary onClick={showModal}
                >
                    Добавить
                </ButtonPrimary>
            </ButtonsWrapper>
            <CompaniesTable setOpen={setOpen} open={open} openNotification={openNotification}/>
        </>
    )
};

export default Сompanies;