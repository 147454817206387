import React, { useState, useMemo, useEffect } from 'react';
import { X } from 'react-bootstrap-icons';
import agentsService from '../../services/AgentsService';
import { Modal, Space, Table, Tooltip, Button, Tabs } from 'antd';
import AddNewAgent from './AddNewAgent';
import EditAgent from './EditAgent';
import projectService from '../../services/ProjectService';
import EditProject from './EditProject';
import Project from './Project';
import styled from 'styled-components';

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`

const Wrapper = styled.div`
    padding: 0 15px;
`

const AgentsTable = ({ setOpen, open, setOpenNewProject, openNewProject, userCompany, openNotification }) => {

    const [agents, setAgents] = useState('');
    const [projects, setProjects] = useState('');
    const [openEdit, setOpenEdit] = useState(false);
    const [openAgent, setOpenAgent] = useState('')
    const [openProject, setOpenProject] = useState('')
    const [openEditProject, setOpenEditProject] = useState('')

    useEffect(() => {
        getAgents();
        getProjects()
    }, [])

    const handleCancel = () => {
        setOpen(false);
    }

    const handleCancelNewProject = () => {
        setOpenNewProject(false);
    }

    const handleCancelEdit = () => {
        setOpenEdit(false);
    }

    const getAgents = async () => {
        const agents = await agentsService.get({ company_id: userCompany })
        setAgents(agents.data.map(el => ({ ...el, key: el._id })))
    }

    const deleteCompany = async (data) => {
        await agentsService.delete({_id: data});
        await getAgents();
    }

    const deleteProject = async (data) => {
        await projectService.delete({ _id: data });
        const project = await projectService.get({ company_id: userCompany })
        setProjects(project.data)
    }

    const getProjects = async () => {
        const res = await projectService.get({ company_id: userCompany });
        setProjects(res.data);
    }

    const columnsofficial = useMemo(
        () => [
            {
                title: 'Наименование',
                dataIndex: 'shortName',
                key: 'shortName',
                width: 40,
            },
            {
                title: 'ИНН',
                dataIndex: 'inn',
                key: 'inn',
                width: 40,
            },
            {
                title: 'КПП',
                dataIndex: 'kpp',
                key: 'kpp',
                width: 40,
            },
            {
                title: 'Адрес',
                dataIndex: 'adress',
                key: 'adress',
                width: 40,
            },
            {
                title: 'Вид оплаты',
                dataIndex: 'paymentType',
                key: 'paymentType',
                width: 40,
            },
            {
                title: 'Комментарии',
                dataIndex: 'comment',
                key: 'comment',
                width: 40,
            },
            {
                title: 'Действия',
                dataIndex: 'operation',
                width: 60,
                render: (_, record) => (
                    <Space>
                        <Tooltip title="Удалить">
                            <IconButton onClick={() => {
                                Modal.confirm({
                                    onOk: () => {
                                        deleteCompany(record._id);
                                        openNotification('Успешно!', 'Контрагент был удален!', 'info')
                                    },
                                    title: 'Удалить',
                                    content: 'Подтвердите удаление',
                                    footer: (_, { OkBtn, CancelBtn }) => (
                                        <>
                                            <CancelBtn />
                                            <OkBtn />
                                        </>
                                    ),
                                })
                            }} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                                icon={
                                    <X style={{ 'color': '#ef423e', 'width': '18px', 'height': '18px' }} />
                                } />
                        </Tooltip>
                    </Space>
                ),
            }
        ],
        [],
    );

    const columnsPhysical = useMemo(
        () => [
            {
                title: 'Имя',
                dataIndex: 'name',
                key: 'name',
                width: 40,
            },
            {
                title: 'Фамилия',
                dataIndex: 'secondName',
                key: 'secondName',
                width: 40,
            },
            {
                title: 'Отчество',
                dataIndex: 'surName',
                key: 'surName',
                width: 40,
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                key: 'phone',
                width: 40,
            },
            {
                title: 'Комментарии',
                dataIndex: 'comment',
                key: 'comment',
                width: 40,
            },
            {
                title: 'Действия',
                dataIndex: 'operation',
                width: 60,
                render: (_, record) => (
                    <Space>
                        <Tooltip title="Удалить">
                            <IconButton onClick={() => {
                                Modal.confirm({
                                    onOk: () => {
                                        deleteCompany(record._id);
                                    },
                                    title: 'Удалить',
                                    content: 'Подтвердите удаление',
                                    footer: (_, { OkBtn, CancelBtn }) => (
                                        <>
                                            <CancelBtn />
                                            <OkBtn />
                                        </>
                                    ),
                                })
                            }} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                                icon={
                                    <X style={{ 'color': '#ef423e', 'width': '18px', 'height': '18px' }} />
                                } />
                        </Tooltip>
                    </Space>
                ),
            }
        ],
        [],
    );


    const pojectCol = useMemo(
        () => [
            {
                header: 'Название',
                accessorKey: 'name', //simple accessorKey pointing to flat data
                size: 25, //small column
            },
            {
                header: 'Менеджеры',
                accessorKey: 'managers', //simple accessorKey pointing to flat data
                size: 200, //small column
            },
            {
                header: 'Комментарий',
                accessorKey: 'comment', //simple accessorKey pointing to flat data
                size: 200, //small column
            },
        ],
        [],
    );

    const items = [
        {
            key: '1',
            label: 'ООО или ИП',
            children: <Table
                columns={columnsofficial}
                dataSource={agents ? agents.filter(el => el.type === 'official') : null}
                rowClassName={(record, index) => index % 2 === 0 ? 'even-row-table' : 'odd-row-table'}
                onRow={(record) => {
                    return {
                        onDoubleClick: async () => {
                            setOpenAgent(record);
                            setOpenEdit(true)
                        },
                    };
                }}
                expandable={{
                    expandedRowRender: (record) => (
                        <p
                            style={{
                                margin: 0,
                            }}
                        >
                            {record.description}
                        </p>
                    ),
                    rowExpandable: (record) => record.isProject,
                }}
            />,
        },
        {
            key: '2',
            label: 'Физ. лицо',
            children: <Table
                columns={columnsPhysical}
                dataSource={agents ? agents.filter(el => el.type === 'physics') : null}
                rowClassName={(record, index) => index % 2 === 0 ? 'even-row-table' : 'odd-row-table'}
                onRow={(record) => {
                    return {
                        onDoubleClick: async () => {
                            setOpenAgent(record);
                            setOpenEdit(true)
                        },
                    };
                }}
                expandable={{
                    expandedRowRender: (record) => (
                        <p
                            style={{
                                margin: 0,
                            }}
                        >
                            {record.description}
                        </p>
                    ),
                    rowExpandable: (record) => record.isProject,
                }}
            />,
        },
    ];

    return (
        <>

            {
                agents &&
                <Wrapper>
                    <Tabs
                        defaultActiveKey="1"
                        items={items}
                        destroyInactiveTabPane
                    />
                    <Modal
                        title="Новая компания"
                        open={open}
                        onCancel={handleCancel}
                        footer={null}
                        destroyOnClose={true}
                        width={800}
                        style={{ top: 10 }}
                        afterClose={getAgents}
                    >
                        <AddNewAgent setOpen={setOpen} userCompany={userCompany} />
                    </Modal>

                    <Modal
                        title="Редактировать компанию"
                        open={openEdit}
                        destroyOnClose={true}
                        onCancel={handleCancelEdit}
                        footer={null}
                        width={800}
                        style={{ top: 10 }}
                        afterClose={getAgents}
                    >
                        <EditAgent setOpen={setOpenEdit} openAgent={openAgent} userCompany={userCompany} />
                    </Modal>

                    <Modal
                        title="Редактировать проект"
                        open={openEditProject}
                        destroyOnClose={true}
                        onCancel={handleCancelEdit}
                        footer={null}
                        width={800}
                        style={{ top: 10 }}
                        afterClose={getProjects}
                    >
                        <EditProject setOpen={setOpenEditProject} agents={agents} openProject={openProject} userCompany={userCompany} />
                    </Modal>


                    <Modal
                        title="Добавить проект"
                        open={openNewProject}
                        onCancel={handleCancelNewProject}
                        footer={null}
                        destroyOnClose={true}
                        width={800}
                        style={{ top: 10 }}
                        afterClose={getProjects}
                    >
                        <Project setOpenNewProject={setOpenNewProject} userCompany={userCompany} />
                    </Modal>
                </Wrapper>
            }
        </>
    );
};

export default AgentsTable;