import $api from '../http';

export default class DriverService {

    static async createOrEdit(data) {
        try {
            return await $api.post('/create-driver', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async delete(data) {
        try {
            return await $api.post('/delete-driver', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async recover(data) {
        try {
            return await $api.post('/recover-driver', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async get(data) {
        try {
            return await $api.post('/get-driver', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async getPayment(data) {
        try {
            return await $api.post('/get-payment-driver', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async editPayment(data) {
        try {
            return await $api.post('/edit-payment-driver', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async addPayment(data) {
        try {
            return await $api.post('/add-payment-driver', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async deletePayment(data) {
        try {
            return await $api.post('/delete-payment-driver', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async addPaymentMonth(data) {
        try {
            return await $api.post('/add-driver-month-payment', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async editPaymentMonth(data) {
        try {
            return await $api.post('/edit-driver-month-payment', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async getPaymentMonth(data) {
        try {
            return await $api.post('/get-driver-month-payment', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async getSalaryInfo(data) {
        try {
            return await $api.post('/get-salary-info', data);
        } catch(e) {
            console.log(e)
        }
    }

    
}