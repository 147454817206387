import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import BucketOrders from './BucketOrders';
import BucketCars from './BucketCars';

const Wrapper = styled.div`
    padding: 0 15px;
`

const Bucket = ({ openNotification }) => {

    useEffect(() => {
        document.title = "Корзина - ГрузДок - сервис учета грузоперевозок"
    }, [])

    const items = [
        {
          key: '1',
          label: 'Заказы',
          children: <BucketOrders openNotification={ openNotification } />,
        },
        // {
        //   key: '2',
        //   label: 'ТС',
        //   children: <BucketCars openNotification={ openNotification } />,
        // },
      ];

    return (
        <Wrapper>
            <Tabs defaultActiveKey="1" items={items} />
        </Wrapper>
    );
};

export default Bucket;