import React, { useRef } from 'react';
import { DatePicker, Space, ConfigProvider } from 'antd';
import { ButtonPrimary } from '../../styles/buttons';
import ordersService from '../../services/OrdersService';
import ru_RU from 'antd/lib/locale/ru_RU';

const ChooseDate = ({ data, closeModalmodalDate, openNotification }) => {

    const dateRef = useRef();

    const setDate = (data) => {
        dateRef.current = data;
    }

    const createOrder = async () => {
        if (dateRef.current) {
            data.date = dateRef.current;
            await ordersService.createOrEdit(data);
            closeModalmodalDate();
            openNotification('Успешно!', 'Заказ добавлен!', 'info')
        } else {
            openNotification('Ошибка!', 'Не выбрана дата!', 'error')
        }
    }

    return (
        <Space>
            <ConfigProvider locale={ru_RU}>
                <DatePicker onChange={setDate}></DatePicker>
            </ConfigProvider>
            <ButtonPrimary onClick={createOrder}>Создать</ButtonPrimary>
        </Space>
    );
};

export default ChooseDate;