import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';




const Wrapper = styled.div`
    display: flex;
    jusitfy-content: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 16px;
`

const Examples = ({ data }) => {
    return (
        <Wrapper>
            <TextArea defaultValue={`Организация перевозок грузов согласно реестру выполненных транспортных услуг № ${data.numberForDocuments} от ${moment(data.date).format('DD.MM.YYYY')}г. Исполнителем ООО «АВТОКАСЫМ» для Заказчика ${data.agent.shortName} по договору № ${data.agent.contractNumber} от ${moment(data.agent.contractDate, "DD.MM.YYYY").format('DD.MM.YYYY')} за ${moment(data.date).format('DD.MM.YYYY')}г.`}>

            </TextArea>
        </Wrapper>
    );
};

export default Examples;