import $api from '../http';

export default class ProjectService {

    static async createOrEdit(data) {
        try {
            return await $api.post('/create-project', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async delete(data) {
        try {
            return await $api.post('/delete-project', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async get() {
        try {
            return await $api.post('/get-projects');
        } catch(e) {
            console.log(e)
        }
    }
}