import React from 'react';
import CarsTable from '../CarsList/CarsTable';

const CarsSell = ({ openNotification }) => {
    return (
        <div>
            <CarsTable type={'sell'} openNotification={openNotification} />
        </div>
    );
};

export default CarsSell;