import $api from '../http';

export default class RegistryService {

    static async createOrEdit(data) {
        try {
            return await $api.post('/create-registry', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async delete(data) {
        try {
            return await $api.post('/delete-registry', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async get(data) {
        try {
            return await $api.post('/get-registry', data);
        } catch(e) {
            console.log(e)
        }
    }
}