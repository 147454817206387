import $api from '../http';

export default class DriversSettings {

    static async create(data) {
        try {
            return await $api.post('/create-driver-type', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async get(data) {
        try {
            return await $api.post('/get-driver-type', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async delete(data) {
        try {
            return await $api.post('/delete-driver-type', data);
        } catch(e) {
            console.log(e)
        }
    }
}