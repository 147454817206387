import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import { logoutReducer } from '../../redux/reducers/userReducer';
import { useDispatch, useSelector } from 'react-redux';
import CompaniesService from '../../services/CompaniesService';

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
    color: #fff;
    padding: 16px 16px;
`

const Button = styled.div`
    color: #fff;
    font-size: 1.5em;
    cursor: pointer;
    &:hover {
        color: #1677ff;
    }
`

const ButtonDefaultStyle = styled.button`
    display: flex;
    align-items: center;
    max-height: 30px;
    padding: 8px 16px;
    border: none;
    color: #fff;
`

const ButtonPrimary = styled(ButtonDefaultStyle)`
    background-color: #001529;
    transition: 0.5s all;
    &:hover{
        outline: 1px solid #001529;
        background-color: #fff; 
        color: #001529;
    }
`

const ProfileButton = ({ setIsLoggin, setUser }) => {

    const [companyName, setCompanyName] = useState('');

    const dispatch = useDispatch()
    const userCompany = useSelector((state) => state?.userInfo?.user?.user?.company_id ? state?.userInfo?.user?.user?.company_id : 'Не найдено');

    useEffect(() => {
        getMainCompany()
    }, [])

    const getMainCompany = async() => {
        const mainCompany = await CompaniesService.getMainCompany({ _id: userCompany});
        setCompanyName(mainCompany.data[0].shortName);
    }

    return (
        <ButtonsWrapper>
            {companyName}
            <Link to='/user'>
                <Button>
                    <i className="bi bi-person"></i>
                </Button>
            </Link>
            <ButtonPrimary onClick={() => {
                dispatch(logoutReducer());
                AuthService.logout()
                setUser({})
                setIsLoggin(false)
            }}
            >
                Выйти
            </ButtonPrimary>
        </ButtonsWrapper>

    );
};

export default ProfileButton;