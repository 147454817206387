import React, { useState, useEffect, useRef, useCallback } from 'react';

import { XCircleFill, ExclamationCircleFill, HouseFill, Check2Circle, Infinity, Check, X, ArrowClockwise, Download } from 'react-bootstrap-icons';
import { Modal, Tooltip, DatePicker, ConfigProvider, Space, Switch, Table } from 'antd';
import { ButtonsFlex, InvoicePaid, InvoiceIssued, ReadyForInvoicing, IconButton, DocsOffice, Attention, Submited } from './components/styles';
import { ButtonsWrapper } from '../../styles/buttons';
import { removeDuplicates } from '../../components/getters';

import ordersService from '../../services/OrdersService';
import AddNewOrder from './AddNewOrder';
import EditSavedOrders from './SavedOrders';
import SaveRouteModal from './SaveRouteModal';
import CreateSaved from './CreateSaved';
import RegistryService from '../../services/RegistryService';

import { getOrders } from '../../components/getters';
import ru_RU from 'antd/lib/locale/ru_RU';
import { OrdersTable } from './OrdersTable';
import { changeDocsStatus, changeStatus } from './components/OrderStatus';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import dayjs from 'dayjs';
import ChooseDate from './ChooseDate';
import Spinner from '../../components/Supply/Spinner';
const { RangePicker } = DatePicker;


const TextPlace = styled.div`
    max-width: 150px;
    max-height: 60px;
    overflow: auto;
`

const StatusWrapper = styled.div`
    display: flex;
    justify-content: center;
`

const Orders = ({ socket, openNotification }) => {

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const [orders, setOrders] = useState('');
    const [orderToEdit, setOrderToEdit] = useState();
    const [switchWithoutDate, setSwitchWithoutDate] = useState(false);
    const [dateForDateRange, setDateForDateRange] = useState(
        [dayjs().subtract(1, 'month'), dayjs().add(10, 'days')]
    )
    const [modalDate, setOpenModalDate] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openSave, setOpenSave] = useState(false);
    const [openCreateSaved, setOpenCreateSaved] = useState(false);

    const [spinning, setSpinning] = useState(false);

    const createAgainData = useRef([])

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [openSaveRoute, setOpenSaveRoute] = useState(false);
    const [saveRoute, setSaveRoute] = useState('');

    const setStatus = async (type, variant) => {
        for (const item of selectedRowKeys) {
            variant === 'status' ? await changeStatus(orders.find(el => el._id === item)._id, type) :
                await changeDocsStatus(item, type);
        }
        setSwitchWithoutDate(false)
        await gettingOrders(true);
        setSelectedRowKeys([]);
    }

    const roomName = `Orders ${userCompany}`;

    const gettingOrders = useCallback( async (isUpdate = false, query = { date: { $gte: dayjs(dateForDateRange[0]).startOf('day'), $lte: dayjs(dateForDateRange[1]).endOf('day') }, status: {$ne : 'deleted'} }) => {
        const res = await getOrders(query);
        setOrders(res.map(el => ({
            ...el,
            key: el._id,
        })).sort((a, b) => b.innerSort - a.innerSort));

        if (isUpdate) {
            socket.emit('message', {
                text: 'update',
                room: roomName,
                id: `${socket.id}${Math.random()}`,
                socketID: socket.id,
            });
        }
    }, [dateForDateRange, roomName, socket])

    const getSocketConnect = useCallback( async () => {
        socket.on('update', async (data) => {
            setSwitchWithoutDate(false)
            await gettingOrders(false);
        });
    }, [gettingOrders, socket])

    useEffect(() => {
        gettingOrders(false);
        socket.emit('enterRoom', roomName);
        getSocketConnect();
        document.title = "Заказы - ГрузДок - сервис учета грузоперевозок"
        return () => {
            socket.emit('leaveRoom', roomName);
        };
        
    }, [getSocketConnect, gettingOrders, roomName, socket])





    const handleCancel = () => {
        setOpen(false);
    }

    const handleCancelEdit = () => {
        setOpenEdit(false);
    }

    const deleteOrders = async () => {
        Modal.confirm({
            onOk: () => handleOkDeleteOrders(),
            title: 'Удалить',
            content: 'Подтвердите удаление',
            footer: (_, { OkBtn, CancelBtn }) => (
                <>
                    <CancelBtn />
                    <OkBtn />
                </>
            ),
        })
    }

    const handleOkDeleteOrders = async () => {
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const currentOrder = orders.find(el => el._id === selectedRowKeys[i]);
            if (currentOrder.status === 'Created') {
                await ordersService.delete({ _id: selectedRowKeys[i] });
            } else {
                openNotification('Ошибка!', `Заказ №${currentOrder.order} используется в документах/реестре`, 'error')
            }
        }
        await gettingOrders(true);
        setSwitchWithoutDate(false)
        setSelectedRowKeys([]);
    }

    const showModalSaved = () => {
        setOpenSave(true)
    }

    const showModalCreateSaved = () => {
        setOpenCreateSaved(true)
    }



    const closeModalmodalDate = () => {
        setOpenModalDate(false);
    }


    const closeModalCreateSaved = () => {
        setOpenCreateSaved(false);
    }


    const closeModalSaved = () => {
        setOpenSave(false)
    }

    const addSaveRoute = (data) => {
        setSaveRoute(data)
        setOpenSaveRoute(true)
    }

    const closeModalSaveRoute = () => {
        setOpenSaveRoute(false);
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: '_id', //simple accessorKey pointing to flat data
            key: '_id',
            hidden: true,
        },
        Table.EXPAND_COLUMN,
        {
            title: '№',
            dataIndex: 'order',
            key: 'order',
            width: 40,
        },
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text) => text ? <TextPlace><b>{moment(text).format('DD/MM/YY')}</b></TextPlace> : null,
            width: 67,
        },
        {
            title: 'Время',
            dataIndex: 'startTime',
            key: 'startTime',
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
            width: 50,
        },
        {
            title: 'Контрагент',
            dataIndex: 'agent',
            key: 'agent',
            filteredValue: filteredInfo.agent || null,
            filterSearch: true,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.agent.shortName, value: el.agent._id }))) : null,
            onFilter: (value, record) => record.agent._id.startsWith(value),
            render: (text, row) => text?.shortName ? <TextPlace>{text.shortName}</TextPlace> : null,
            width: 150,

        },
        {
            title: 'Водитель',
            dataIndex: 'driver',
            key: 'driver',
            filteredValue: filteredInfo.driver || null,
            filterSearch: true,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.driver?.name, value: el.driver?.name }))) : null,
            onFilter: (value, record) => {
                const result = record.driver ? record.driver.name.startsWith(value) : null;
                return result;
            },
            filterMode: 'tree',
            render: (text, row) => text?.name ? <TextPlace>{text.name}</TextPlace> : null,
            width: 100,
        },
        {
            title: 'ГОС',
            dataIndex: 'number',
            key: 'number',
            filteredValue: filteredInfo.number || null,
            filterSearch: true,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.number?.number, value: el.number?._id }))) : null,
            onFilter: (value, record) => record.number._id.startsWith(value),
            render: (text, row) => text?.number ? <TextPlace>{text.number}</TextPlace> : null,
            width: 50,
        },
        {
            title: 'Док.',
            dataIndex: 'docStatus',
            key: 'docStatus',
            filteredValue: filteredInfo.docStatus || null,
            filterSearch: true,
            filters: orders ? removeDuplicates(orders.map(el => ({
                text: el.status === 'Attention' ?
                    'Обратить внимание' :
                    el.status === 'Submit' ? 'Документы сданы' :
                        el.status === 'Office' ? 'В офисе' :
                            'Без статуса'
                , value: el.status
            }))) : null,
            onFilter: (value, record) => record.status.startsWith(value),
            render: (text) =>
            (
                <StatusWrapper>
                    {
                        text === 'Attention' ? <Attention></Attention> :
                            text === 'Submit' ? <Submited></Submited> :
                                text === 'Office' ? <DocsOffice></DocsOffice> :
                                    null
                    }
                </StatusWrapper>
            ),
            width: 20,
        },
        {
            title: 'Оплата',
            dataIndex: 'company',
            key: 'company',
            filteredValue: filteredInfo.company || null,
            filterSearch: true,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.company?.shortName, value: el.company?._id }))) : null,
            onFilter: (value, record) => record.company._id.startsWith(value),
            render: (text, row) =>
            (
                <TextPlace>
                    {
                        row.status === 'Paid' ? <InvoicePaid>{text.shortName}</InvoicePaid> :
                            row.status === 'InvoiceIssued' ? <InvoiceIssued>{text.shortName}</InvoiceIssued> :
                                row.status === 'ReadyForInvoicing' ? <ReadyForInvoicing>{text.shortName}</ReadyForInvoicing> :
                                    text.shortName
                    }
                </TextPlace>
            ),
            width: 150,
        },
        {
            title: 'Ставка',
            dataIndex: 'bet',
            key: 'bet',
            width: 70,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.bet, value: el.bet }))) : null,
            filteredValue: filteredInfo.bet || null,
            onFilter: (value, record) => record.bet.startsWith(value),
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
        },
        {
            title: 'т/м3/п',
            dataIndex: 'weight',
            key: 'weight',
            width: 60,
            filteredValue: filteredInfo.weight || null,
            filters: orders ? removeDuplicates(orders.map(el => ({ text: el.weight, value: el.weight }))) : null,
            onFilter: (value, record) => record.weight.startsWith(value),
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
        },
        {
            title: 'Загрузка',
            dataIndex: 'routes',
            key: 'routeA',
            render: (text, row) => <TextPlace>{text[0][0]}</TextPlace>,
            width: 150,
        },
        {
            title: 'Выгрузка',
            dataIndex: 'routes',
            key: 'routeB',
            render: (text, row) => <TextPlace>{text[text.length - 1][1]}</TextPlace>,
            width: 150,
        },
        {
            title: 'Часы',
            dataIndex: 'time',
            key: 'time',
            width: 40,
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
        },
        {
            title: 'Ставка водителя',
            dataIndex: 'driver_bet',
            key: 'driver_bet',
            width: 40,
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
        },
        {
            title: 'Комментарии',
            dataIndex: 'comment',
            key: 'comment',
            render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
            width: 100,
        },
        {
            title: <Tooltip title="Кругорейс"><ArrowClockwise /></Tooltip>,
            dataIndex: 'isRound', //simple accessorKey pointing to flat data
            key: 'isRound',
            render: (text, row) => (
                text === true ? <Check style={{ 'color': 'green' }} /> :
                    <X style={{ 'color': 'red' }} />
            )

        },
        {
            title: <Tooltip title="Периодичный"><Infinity /></Tooltip>,
            dataIndex: 'isFrequency', //simple accessorKey pointing to flat data
            key: 'isFrequency',
            render: (text, row) => (
                text === true ? <Check style={{ 'color': 'green' }} /> :
                    <X style={{ 'color': 'red' }} />
            )

        },
        {
            title: 'Сортировка',
            dataIndex: 'innerSort', //simple accessorKey pointing to flat data
            key: 'innerSort',
            hidden: true,
        },
        {
            title: 'Действия',
            dataIndex: 'operation',
            render: (_, record) => (
                <Space>
                    <Tooltip title="Повторить">
                        <IconButton onClick={() => createAgain(record._id)} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                            icon={
                                <ArrowClockwise style={{ 'color': 'green', 'width': '12px', 'height': '12px' }} />
                            } />
                    </Tooltip>
                    <Tooltip title="Сохранить">
                        <IconButton onClick={() => addSaveRoute(record)} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                            icon={
                                <Download style={{ 'color': 'green', 'width': '12px', 'height': '12px' }} />
                            } />
                    </Tooltip>
                </Space>
            ),
        },
    ].filter(item => !item.hidden);

    const createAgain = async (data) => {
        const order = await ordersService.getOrder({ _id: data });
        const create = {
            agent: order.data.agent._id,
            company: order.data.company,
            bet: order.data.bet,
            weight: order.data.weight,
            routes: order.data.routes,
            time: order.data.time,
            company_id: order.data.company_id,
            isRound: order.data.isRound,
            startTime: order.data.startTime
        }
        createAgainData.current = create;
        setOpenModalDate(true);
    }

    const setDateRange = async (value) => {
        if (value) {
            setDateForDateRange(value)
            setSwitchWithoutDate(false)
            gettingOrders(false);
        }
    }

    function removeDuplicatesRegistry(array) {
        return array.filter((obj, index, self) =>
            obj._id && index === self.findIndex((t) => (
                t._id === obj._id
            ))
        );
    }

    const createRegistry = async () => {
        const date = new Date();
        const agents = [];
        const paymentType = [];
        const ordersToRegistry = [];
        setSpinning(true);
        for (let i = 0; i < selectedRowKeys.length; i++) {

            if (orders.find(el => el._id === selectedRowKeys[i]).date === undefined) {
                setSpinning(false);
                openNotification('Ошибка!', `В маршруте №${orders.find(el => el._id === selectedRowKeys[i]).date} не указана дата`, 'error')
                return;
            }

            if (orders.find(el => el._id === selectedRowKeys[i]).driver === undefined) {
                setSpinning(false);
                openNotification('Ошибка!', `В маршруте №${orders.find(el => el._id === selectedRowKeys[i]).order} не выбран водитель`, 'error')
                return;
            }

            if (orders.find(el => el._id === selectedRowKeys[i]).number === undefined) {
                setSpinning(false);
                openNotification('Ошибка!', `В маршруте №${orders.find(el => el._id === selectedRowKeys[i]).order} не выбран гос. номер`, 'error')
                return;
            }

            if (orders.find(el => el._id === selectedRowKeys[i]).bet === undefined) {
                setSpinning(false);
                openNotification('Ошибка!', `В маршруте №${orders.find(el => el._id === selectedRowKeys[i]).date} не указана ставка за рейс`, 'error')
                return;
            }

            if (orders.find(el => el._id === selectedRowKeys[i]).status === 'InvoiceIssued') {
                setSpinning(false);
                openNotification('Ошибка!', 'Выбранные маршруты уже есть в реестре!', 'error')
                return;
            }
        }

        for (const item of selectedRowKeys) {
            ordersToRegistry.push({ _id: item });
            paymentType.push(orders.find(el => el._id === item).company);
            agents.push(orders.find(el => el._id === item).agent);
        }
        const uniquePaymentType = removeDuplicatesRegistry(paymentType)
        const uniqueArray = removeDuplicatesRegistry(agents);

        if (uniqueArray.length === 1) {
            if (uniquePaymentType.length === 1) {
                const result = {
                    agent: uniqueArray[0]._id,
                    orders: ordersToRegistry,
                    date: date,
                    paymentType: uniquePaymentType[0]._id,
                    company_id: userCompany,
                };
                const res = await RegistryService.createOrEdit(result);
                await gettingOrders(true);
                setSelectedRowKeys([]);
                setSpinning(false);
                openNotification(res.data.status, res.data.statusInfo, res.data.statusType)
                socket.emit('message', {
                    text: 'update',
                    room: `Registry ${userCompany}`,
                    id: `${socket.id}${Math.random()}`,
                    socketID: socket.id,
                });

            } else {
                setSpinning(false);
                openNotification('Ошибка!', 'Выбрано несколько видов оплат!', 'error')
            }
        } else {
            console.log(uniqueArray)
            setSpinning(false);
            openNotification('Ошибка!', 'Выбрано несколько контрагентов!', 'error')
        }
    }

    const onChangeSwitch = (checked) => {
        if (checked) {
            setSwitchWithoutDate(true)
            gettingOrders(false, { date: { $exists: false } });
        } else {
            setSwitchWithoutDate(false)
            gettingOrders(false);
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };

    return (
        <>
            {
                spinning ?
                    <Spinner spinning={spinning} />
                    :
                    <>
                        <ButtonsFlex>
                            <ButtonsWrapper>

                            </ButtonsWrapper>
                            <ButtonsWrapper>
                                Без даты: <Switch checked={switchWithoutDate} onChange={onChangeSwitch} />
                                <ConfigProvider locale={ru_RU}>
                                    <RangePicker
                                        format={'DD-MM-YYYY'} onChange={setDateRange} defaultValue={dateForDateRange} allowClear={false}
                                    />
                                </ConfigProvider>
                            </ButtonsWrapper>
                            <ButtonsWrapper>
                                <Tooltip title="Документов нет">
                                    <IconButton onClick={() => setStatus('Nothing', 'docks')} style={{ 'color': '#000' }} icon={<XCircleFill />}></IconButton>
                                </Tooltip>
                                <Tooltip title="В офисе">
                                    <IconButton onClick={() => setStatus('Office', 'docks')} style={{ 'color': '#5f67c3' }} icon={<HouseFill />}></IconButton>
                                </Tooltip>
                                <Tooltip title="Документы сданы">
                                    <IconButton onClick={() => setStatus('Submit', 'docks')} style={{ 'color': '#9cb996' }} icon={<Check2Circle />}></IconButton>
                                </Tooltip>
                                <Tooltip title="Обратить внимание">
                                    <IconButton onClick={() => setStatus('Attention', 'docks')} style={{ 'color': '#cd304c' }} icon={<ExclamationCircleFill />}></IconButton>
                                </Tooltip>
                            </ButtonsWrapper>
                        </ButtonsFlex>
                        {
                            orders &&
                            <>
                                <OrdersTable
                                    columns={columns}
                                    data={orders}
                                    setOpen={setOpen}
                                    createRegistry={createRegistry}
                                    showModalSaved={showModalSaved}
                                    showModalCreateSaved={showModalCreateSaved}
                                    setOrderToEdit={setOrderToEdit}
                                    setOpenEdit={setOpenEdit}
                                    setSelectedRowKeys={setSelectedRowKeys}
                                    selectedRowKeys={selectedRowKeys}
                                    deleteOrders={deleteOrders}
                                    handleChange={handleChange}
                                    filteredInfo={filteredInfo}
                                />

                                <Modal
                                    title="Новый заказ"
                                    open={open}
                                    onCancel={handleCancel}
                                    footer={null}
                                    destroyOnClose={true}
                                    width={850}
                                    style={{ top: 10 }}
                                >
                                    <AddNewOrder setOpen={setOpen} gettingOrders={gettingOrders} userCompany={userCompany} openNotification={openNotification} />
                                </Modal>

                                <Modal
                                    title="Редактирование заказа"
                                    open={openEdit}
                                    onCancel={handleCancelEdit}
                                    footer={null}
                                    destroyOnClose={true}
                                    width={850}
                                    style={{ top: 10 }}
                                >
                                    <AddNewOrder setOpen={setOpenEdit} gettingOrders={gettingOrders} userCompany={userCompany} orderToEdit={orderToEdit} openNotification={openNotification} />
                                </Modal>

                                <Modal
                                    title="Сохраненные маршруты"
                                    open={openSave}
                                    destroyOnClose={true}
                                    onCancel={closeModalSaved}
                                    footer={null}
                                    width='100%'
                                    style={{ top: 10 }}
                                >
                                    <EditSavedOrders gettingOrders={gettingOrders} closeModal={closeModalSaved} userCompany={userCompany} openNotification={openNotification} />
                                </Modal>

                                <Modal
                                    title="Сохранить маршрут"
                                    open={openSaveRoute}
                                    onCancel={closeModalSaveRoute}
                                    footer={null}
                                    destroyOnClose={true}
                                    width={450}
                                    style={{ top: 10 }}
                                >
                                    <SaveRouteModal saveRoute={saveRoute} setOpenSaveRoute={setOpenSaveRoute} openNotification={openNotification} userCompany={userCompany} />
                                </Modal>

                                <Modal
                                    title="Загрузка маршрутов"
                                    open={openCreateSaved}
                                    onCancel={closeModalCreateSaved}
                                    footer={null}
                                    destroyOnClose={true}
                                    width={350}
                                    style={{ top: 10 }}
                                >
                                    <CreateSaved setOpenCreateSaved={setOpenCreateSaved} gettingOrders={gettingOrders} closeModalCreateSaved={closeModalCreateSaved} userCompany={userCompany} />
                                </Modal>

                                <Modal
                                    title="Выберите дату"
                                    open={modalDate}
                                    onCancel={closeModalmodalDate}
                                    footer={null}
                                    destroyOnClose={true}
                                    width={350}
                                    style={{ top: 10 }}
                                    afterClose={gettingOrders}
                                >
                                    <ChooseDate data={createAgainData.current} closeModalmodalDate={closeModalmodalDate} openNotification={openNotification} />
                                </Modal>
                            </>
                        }
                    </>
            }






        </>
    );
};

export default Orders;