import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input } from 'antd';
import { ButtonsWrapper, ButtonPrimary } from '../../styles/buttons';
import CompaniesService from '../../services/CompaniesService';

const AddNewCompanyModal = ({ setOpenAddCompany, getCompanies, openNotification }) => {

    const InnWrapper = styled.div`
    display: flex;
    justify-content: start;
    width: 300px;
`

    const [inn, setInn] = useState();

    const [initVal, setInitVal] = useState();

    const findByInn = async (number) => {
        const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
        const token = "6d61202bda78cdeb99169219c1c628858a32c6e7";
        const query = number;

        const options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({ query: query })
        }

        return fetch(url, options)
            .then(response => response.json())
            .then(result =>
                setInitVal({
                    fullName: result.suggestions[0].data.name.full_with_opf,
                    shortName: result.suggestions[0].data.name.short_with_opf,
                    inn: result.suggestions[0].data.inn,
                    kpp: result.suggestions[0].data.kpp,
                    adress: result.suggestions[0].data.address.value,
                    ogrn: result.suggestions[0].data.ogrn,
                    okpo: result.suggestions[0].data.okpo,
                    okved: result.suggestions[0].data.okved,
                    boss: result.suggestions[0].data?.management ? result.suggestions[0].data.management.name : null,
                    bossJob: result.suggestions[0].data?.management ? result.suggestions[0].data.management.post : null,
                    accountier: result.suggestions[0].data?.management ? result.suggestions[0].data.management.name : null,
                })
            )
            .catch(error => console.log("error", error));
    }


    const addCompany = async (values) => {
        await CompaniesService.createOrEditMainCompany({...values});
        await getCompanies();
        setOpenAddCompany(false);
        openNotification('Успех!', 'Компания была добавлена!', 'info')
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const change = (value) => {
        setInn(value)
    }


    return (
        <>
            <ButtonsWrapper>
                <InnWrapper>
                    <Input placeholder='Поиск по ИНН' onChange={(e) => change(e.target.value)} /><ButtonPrimary onClick={() => findByInn(inn)}>Поиск</ButtonPrimary>
                </InnWrapper>
            </ButtonsWrapper>
            {
                initVal &&
                <Form
                    name="basic"
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                    labelAlign='left'
                    style={{
                        width: 550,
                    }}
                    initialValues={{
                        ...initVal
                    }}
                    layout='horizontal'
                    onFinish={addCompany}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"

                >
                    <Form.Item
                        label="Полное наименование"
                        name="fullName"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Краткое наименование"
                        name="shortName"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="ИНН"
                        name="inn"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="КПП"
                        name="kpp"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Юридический адрес"
                        name="adress"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="ОГРН"
                        name="ogrn"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="ОКПО"
                        name="okpo"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="ОКВЭД"
                        name="okved"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Банк"
                        name="bank"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="БИК"
                        name="bik"
                    >
                        <Input type="number" />
                    </Form.Item>

                    <Form.Item
                        label="Р/с"
                        name="billOne"
                    >
                        <Input type="number" />
                    </Form.Item>

                    <Form.Item
                        label="К/с"
                        name="billTwo"
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        label="Руководитель"
                        name="boss"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Должность Руководителя"
                        name="bossJob"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Бухгалтер"
                        name="accountier"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Телефон"
                        name="phone"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 20,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Добавить
                        </Button>
                    </Form.Item>
                </Form>
            }
        </>
    );
};

export default AddNewCompanyModal;