import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { fetchRegistration } from '../../redux/reducers/userReducer';
import RolesService from '../../services/RolesService';
import CompaniesService from '../../services/CompaniesService';
import { ButtonPrimary } from '../../styles/buttons';

const WrapperLogin = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10%;
  max-width: 100%;
`

const AddNewUserModal = ({ openNotification, setOpenAddUser, getUsers }) => {

    const [roles, setRoles] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [isCompanySelect, setIsCompanySelect] = useState(false);

    const dispatch = useDispatch()

    const [formCreate] = Form.useForm();

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {

        const companies = await CompaniesService.getMainCompany();

        setCompanies(companies.data);
    }

    const registration = async (values) => {
        const res = await dispatch(fetchRegistration({ ...values }));
        if (res.error) {
            openNotification('Ошибка!', 'Обратитесь к админу!', 'error')
        } else {
            openNotification('Успех!', 'Пользователь добавлен!', 'info')
            setOpenAddUser(false)
            await getUsers();
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const setCompany = async (id) => {
        if (id) {
            const roles = await RolesService.get({ company_id: id });
            setIsCompanySelect(true)
            setRoles(roles.data);
        } else {
            setIsCompanySelect(false)
        }
    }

    const generatePassword = () => {
        const charset = "!@#$%^&*()0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

        let newPassword = '';
        for (let i = 0; i < 8; i++) {
            newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        formCreate.setFieldValue('password', newPassword)
    }


    return (
        <>
            <ButtonPrimary
                onClick={
                    () => generatePassword()
                }>
                Сгенерировать пароль
            </ButtonPrimary>
            <WrapperLogin>
                <Form
                    name="basic"
                    form={formCreate}
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 22,
                    }}
                    labelAlign='left'
                    style={{
                        width: 400,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    layout='horizontal'
                    onFinish={registration}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"

                >
                    <Form.Item
                        label="Имя"
                        name="name"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Фамилия"
                        name="surName"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Пароль"
                        name="password"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Компания"
                        name="company_id"
                    >
                        <Select showSearch
                            placeholder="Выберите роль"
                            optionFilterProp="children"
                            onChange={setCompany}
                            options={companies && companies.map(el => { return { value: el._id, label: el.shortName } })}
                        />
                    </Form.Item>
                    {
                        isCompanySelect ?
                            <Form.Item
                                label="Роль"
                                name="role"
                            >
                                <Select showSearch
                                    placeholder="Выберите роль"
                                    optionFilterProp="children"
                                    options={roles && roles.map(el => { return { value: el._id, label: el.roleName } })}
                                />
                            </Form.Item> : null
                    }

                    <Form.Item
                        wrapperCol={{
                            offset: 20,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Добавить
                        </Button>

                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 20,
                            span: 16,
                        }}
                    >


                    </Form.Item>

                </Form>
            </WrapperLogin>
        </>

    );
};

export default AddNewUserModal;