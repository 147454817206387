import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const MainPage = ({  openNotification }) => {

    useEffect(() => {
        document.title = "Главная страница - ГрузДок - сервис учета грузоперевозок"
     }, []);

    return (
        <>
        Что бы полноценно начать пользоваться сервисом необходимо выполнить следующие шаги:
        <br />
        <br />
        1. Перейти в <Link to='/companies'>Мои компании</Link> и создать компанию, которая будет выставлять счет на оплату.
        <br />2. Перейти в <Link to='/settings'>Настройки</Link> и создать тип водителей.
        <br />3. Перейти в <Link to='/agents'>Контрагенты</Link> и добавить контрагента.
        <br />4. Перейти в <Link to='/cars'>Транспортные средства</Link> и добавить автомобили.
        <br />5. Перейти в <Link to='/drivers'>Водители</Link> и добавить водителя.
        <br />5. Перейти в <Link to='/orders'>Заказы</Link> и вы готовы добавить свой первый заказ.
        </>
    );
};

export default MainPage;