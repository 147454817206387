import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { IconButton, InvoicePaid, InvoiceIssued, ReadyForInvoicing } from '../Orders/components/styles';
import { Modal, Space, Table, Tooltip } from 'antd';
import { XCircle, ChevronCompactDown, ChevronCompactUp, ArrowClockwise, Check, X } from 'react-bootstrap-icons';

import AddNewOrder from '../Orders/AddNewOrder';
import RegistryService from '../../services/RegistryService';
import OrdersService from '../../services/OrdersService';
import { removeDuplicates } from '../../components/getters';

import styled from 'styled-components';
import moment from 'moment';

const TextPlace = styled.div`
max-width: 150px;
max-height: 60px;
overflow: auto;
`

const RegistryItemsModal = ({ data, socket, closeRegistryModal, getRegistry }) => {

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const [openEdit, setOpenEdit] = useState(false);
    const [ordersData, setOrdersData] = useState();
    const [filteredInfo, setFilteredInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageSize, setCurrentPageSize] = useState(50)

    const getOrders = useCallback( async (update = false, isOnce = false) => {
        if (!isOnce) {
            const res = await OrdersService.get({ registryRef: data._id });
            let ordersToData = res.data.map(el => ({ ...el, key: el._id }));
            ordersToData = ordersToData.sort((a, b) => a.innerSort - b.innerSort);
            setOrdersData(ordersToData);
        }
        if (update) {
            socket.emit('message', {
                text: 'update',
                room: `Orders ${userCompany}`,
                id: `${socket.id}${Math.random()}`,
                socketID: socket.id,
            });
        }
    }, [data._id, socket, userCompany])

    const orderToEdit = useRef([]);

    useEffect(() => {
        getOrders()
    }, [getOrders])

    const handleCancelEdit = () => {
        setOpenEdit(false);
    }

    const columns = [
            Table.EXPAND_COLUMN,
            {
                title: '№',
                dataIndex: 'order',
                key: 'order',
                width: 40,
            },
            {
                title: 'Дата',
                dataIndex: 'date',
                key: 'date',
                render: (text) => text ? <TextPlace><b>{moment(text).format('DD/MM/YY')}</b></TextPlace> : null,
                width: 67,
            },
            {
                title: 'Время',
                dataIndex: 'startTime',
                key: 'startTime',
                render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
                width: 50,
            },
            {
                title: 'Контрагент',
                dataIndex: 'agent',
                key: 'agent',
                render: (text, row) => text?.shortName ? <TextPlace>{text.shortName}</TextPlace> : null,
                width: 150,

            },
            {
                title: 'Водитель',
                dataIndex: 'driver',
                key: 'driver',
                filteredValue: filteredInfo.driver || null,
                filterSearch: true,
                filters: ordersData ? removeDuplicates(ordersData.map(el => ({ text: el.driver?.name, value: el.driver?.name }))) : null,
                onFilter: (value, record) => {
                    const result = record.driver ? record.driver.name.startsWith(value) : null;
                    return result;
                },
                render: (text, row) => text?.name ? <TextPlace>{text.name}</TextPlace> : null,
                width: 100,
            },
            {
                title: 'ГОС',
                dataIndex: 'number',
                key: 'number',
                filteredValue: filteredInfo.number || null,
                filterSearch: true,
                filters: ordersData ? removeDuplicates(ordersData.map(el => ({ text: el.number?.number, value: el.number?._id }))) : null,
                onFilter: (value, record) => record.number._id.startsWith(value),
                render: (text, row) => text?.number ? <TextPlace>{text.number}</TextPlace> : null,
                width: 50,
            },
            {
                title: 'Оплата',
                dataIndex: 'company',
                key: 'company',
                filteredValue: filteredInfo.company || null,
                filterSearch: true,
                filters: ordersData ? removeDuplicates(ordersData.map(el => ({ text: el.company?.shortName, value: el.company?._id }))) : null,
                onFilter: (value, record) => record.company._id.startsWith(value),
                render: (text, row) =>
                (
                    <TextPlace>
                        {
                            row.status === 'Paid' ? <InvoicePaid>{text.shortName}</InvoicePaid> :
                                row.status === 'InvoiceIssued' ? <InvoiceIssued>{text.shortName}</InvoiceIssued> :
                                    row.status === 'ReadyForInvoicing' ? <ReadyForInvoicing>{text.shortName}</ReadyForInvoicing> :
                                        text.shortName
                        }
                    </TextPlace>
                ),
                width: 150,
            },
            {
                title: 'Ставка',
                dataIndex: 'bet',
                key: 'bet',
                filters: ordersData ? removeDuplicates(ordersData.map(el => ({ text: el.bet, value: el.bet }))) : null,
                filteredValue: filteredInfo.bet || null,
                onFilter: (value, record) => record.bet.startsWith(value),
                render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
            },
            {
                title: 'Загрузка',
                dataIndex: 'routes',
                key: 'routeA',
                render: (text, row) => <TextPlace>{text[0][0]}</TextPlace>,
                width: 150,
            },
            {
                title: 'Выгрузка',
                dataIndex: 'routes',
                key: 'routeB',
                render: (text, row) =>  <TextPlace>{text[text.length - 1][1]}</TextPlace>,
                width: 150,
            },
            {
                title: 'Ставка водителя',
                dataIndex: 'driver_bet',
                key: 'driver_bet',
                width: 40,
                render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
            },
            {
                title: 'Комментарии',
                dataIndex: 'comment',
                key: 'comment',
                render: (text, row) => text ? <TextPlace>{text}</TextPlace> : null,
                width: 100,
            },
            {
                title: <Tooltip title="Кругорейс"><ArrowClockwise /></Tooltip>,
                dataIndex: 'isRound', //simple accessorKey pointing to flat data
                key: 'isRound',
                render: (text, row) => (
                    text === true ? <Check style={{ 'color': 'green' }} /> :
                        <X style={{ 'color': 'red' }} />
                )
    
            },
            {
                title: 'ID',
                dataIndex: '_id', //simple accessorKey pointing to flat data
                key: '_id',
                hidden: true,
            },
            {
                title: 'Сортировка',
                dataIndex: 'innerSort', //simple accessorKey pointing to flat data
                key: 'innerSort',
                hidden: true,
            },
            {
                title: 'Действия',
                dataIndex: 'operation',
                render: (_, record) => (
                    <Space>
                        <Tooltip title="Удалить">
                            <IconButton onClick={() => deleteOrder(record._id)} style={{ 'width': '20px', 'height': '20px' }}
                                icon={
                                    <XCircle style={{ 'color': '#c83734', 'width': '18px', 'height': '18px' }} />
                                } />
                        </Tooltip>
                    </Space>
                ),
            },
        ].filter(item => !item.hidden);

    const deleteOrder = async (id) => {
        data.orders = data.orders.filter(el => el._id !== id);
        if (data.orders.length === 0) {
            await RegistryService.delete({ _id: data._id })
            await OrdersService.changeStatus(id, 'Created');
            await getOrders(true, true);
            await getRegistry();
            closeRegistryModal();
        } else {
            data.agent = data.agent._id;
            data.orders = data.orders.map(el => ({
                ...el,
                agent: el.agent._id,
                company: el.company._id,
                driver: el.driver._id,
                number: el.number._id,
            }))
            await OrdersService.changeStatus(id, 'Created');
            await RegistryService.createOrEdit(data);
            await getOrders(true);
        }
    }

    const expandedRowRender = (record) => {
        const tableArr = record.routes;
        const arrayFromHtmlCollection = Array.from(document.querySelector('.registryModalItems').querySelectorAll('tr')[1].querySelectorAll('td'));
        let firstTwelveElements = arrayFromHtmlCollection.slice(1, 9);
        let sumWidth = firstTwelveElements.reduce((acc, current) => {
            let elementWidth = current.clientWidth; // получаем ширину элемента
            return acc + elementWidth;
        }, 0);

        const columns = [
            {
                title: 'Загрузка',
                dataIndex: 'space',
                key: 'space',
                width: sumWidth + 3,
            },
            {
                title: 'Загрузка',
                dataIndex: 'routes',
                key: 'routeA',
                render: (text, row) => row[0],
                width: '125.83px',
            },
            {
                title: 'Выгрузка',
                dataIndex: 'routes',
                render: (text, row) => row[1],
                key: 'routeB',
                width: '125.83px',
            },
        ];
        return <Table columns={columns} dataSource={tableArr} pagination={false} showHeader={false} style={{ display: 'flex' }} />;
    };

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };

    const onChangePage = (currentPage, currentPageSize) => {
        setCurrentPage(currentPage)
        setCurrentPageSize(currentPageSize)
    }

    const expandIcon = ({ expanded, onExpand, record }) => {
        if (record.routes.length > 1 ) {
            return expanded ? (
                <ChevronCompactUp style={{ width: '18px', height: '18px' }} onClick={e => onExpand(record, e)} />
            ) : (
                <ChevronCompactDown style={{ width: '18px', height: '18px' }} onClick={e => onExpand(record, e)} />
            )
        } else {
            return null;
        }

    }

    return (
        <>
            <Table
                className='registryModalItems'
                dataSource={ordersData}
                columns={columns}
                size="small"
                onChange={handleChange}
                expandable={{
                    expandedRowRender,
                    expandIcon,
                }}
                pagination={{
                    onChange: onChangePage,
                    current: currentPage,
                    pageSize: currentPageSize,
                    pageSizeOptions: [50, 100, 150],
                    position: ['topRight', 'bottomRight']
                }}
                bordered={true}
                rowClassName={(record, index) => index % 2 === 0 ? 'even-row-table' : 'odd-row-table'}
                onRow={(record) => {
                    return {
                        onDoubleClick: async () => {
                            orderToEdit.current = record;
                            setOpenEdit(true);
                        },
                    };
                }}
            />
            <Modal
                title="Редактирование заказа"
                open={openEdit}
                onCancel={handleCancelEdit}
                footer={null}
                destroyOnClose={true}
                width={850}
                style={{ top: 10 }}
            >
                <AddNewOrder setOpen={setOpenEdit} orderToEdit={orderToEdit.current} isFromRegistry={true} getOrders={getOrders} socket={socket} />
            </Modal>
        </>

    );
};

export default RegistryItemsModal;