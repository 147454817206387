import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import Users from './Users';
import Drivers from './Drivers';
import UsersRoles from './UsersRoles';
import Others from './Others';
import PlanningSettings from './PlanningSettings';
import CarsDocs from './CarsDocs';

const Settings = ({ openNotification }) => {

  useEffect(() => {
    document.title = "Настройки - ГрузДок - сервис учета грузоперевозок"
 }, []);

  const items = [
    {
      key: '1',
      label: 'Пользователи',
      children: <Users openNotification={openNotification} />,
    },
    {
      key: '2',
      label: 'Роли пользователей',
      children: <UsersRoles openNotification={openNotification} />,
    },
    {
      key: '3',
      label: 'Водители',
      children: <Drivers openNotification={openNotification} />,
    },
    {
      key: '4',
      label: 'Планирование',
      children: <PlanningSettings openNotification={openNotification} />,
    },
    {
      key: '5',
      label: 'Документы ТС',
      children: <CarsDocs openNotification={openNotification} />,
    },
    {
      key: '6',
      label: 'Общие',
      children: <Others openNotification={openNotification} />,
    },
  ];


  return (
    <div>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Settings;