import { Button, Checkbox, Form, Input, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import UsersService from '../../services/UserServices';
import RolesService from '../../services/RolesService';

const EditUser = ({ setOpen, initialUser, setInitialUser, getUsers, userCompany }) => {

    const [form] = Form.useForm();

    const [roles, setRoles] = useState();

    const getRoles = useCallback( async () => {
        const res = await RolesService.get({ company_id: userCompany });
        const initialRole = res.data.find(el => el.roleName === initialUser.role)
        form.setFieldsValue({
            ...initialUser,
            isActivated: initialUser.isActivated === 'Да' ? true : false,
            role: initialRole?._id
        })
        setRoles(res.data)
    }, [userCompany, form, initialUser])

    useEffect(() => {
        getRoles();
    }, [getRoles])



    const onFinish = async (values) => {
        await UsersService.update(values);
        setOpen(false);
        await getUsers();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div>
            <Form
                name="edit"
                form={form}
                labelAlign='left'
                style={{
                    width: 300,
                }}
                layout='horizontal'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={{

                }}
            >
                <Form.Item
                    label="Имя"
                    name="userName"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                >
                    <Input type='email' />
                </Form.Item>

                <Form.Item
                    label="Роль"
                    name="role"
                >
                    <Select
                        showSearch
                        placeholder="Выберите роль"
                        optionFilterProp="children"
                        options={roles && roles.map(el => { return { value: el._id, label: el.roleName } })}
                    />
                </Form.Item>

                <Form.Item
                    label="Активирован?"
                    name="isActivated"
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>

                <Form.Item
                    label="ID"
                    name="_id"
                >
                    <Input disabled />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    Сохранить
                </Button>
            </Form>
        </div>
    );
};

export default EditUser;