import React, { useState } from 'react';
import { ChevronCompactDown, ChevronCompactUp, DatabaseAdd, FileEarmarkXFill, FolderPlus, Receipt, TruckFrontFill } from 'react-bootstrap-icons';
import { Tooltip, Table, ConfigProvider } from 'antd';
import styled from 'styled-components';
import { IconButton, } from './components/styles';
import ru_RU from 'antd/lib/locale/ru_RU';


const ButtonsWrapper = styled.div`
    display: flex;
    justify-conent: start;
    gap: 16px;
`

const TableWrapper = styled.div`
    overflow: auto;
    font-family: 'Roboto', sans-serif;

`

const TopPanel = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    padding: 0 18px;
`

export const OrdersTable = ({ columns, data, selectedRowKeys, setSelectedRowKeys, setOpen, createRegistry, showModalSaved, setOpenEdit, setOrderToEdit, showModalCreateSaved, deleteOrders, handleChange, filteredInfo, setCreateRegistryModal }) => {

    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageSize, setCurrentPageSize] = useState(50)

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const Header = () => (
        <>
            <TopPanel>
                <ButtonsWrapper>
                    <Tooltip title="Добавить заказ">
                        <IconButton onClick={() => {
                            setOpen(true)
                        }} style={{ 'color': '#fff', 'backgroundColor': '#9cb996' }} icon={<DatabaseAdd />}></IconButton>
                    </Tooltip>
                    <Tooltip title="Удалить">
                        <IconButton onClick={() => deleteOrders()
                        } style={{ 'color': '#ff5319' }} icon={<FileEarmarkXFill />}></IconButton>
                    </Tooltip>
                </ButtonsWrapper >

                <ButtonsWrapper>
                    <Tooltip title="Сформировать реестр">
                        <IconButton onClick={() => setCreateRegistryModal(true)} style={{ 'color': '#001529' }} icon={<Receipt />}></IconButton>
                    </Tooltip>
                    <Tooltip title="Сохраненные маршруты">
                        <IconButton onClick={showModalSaved} style={{ 'color': '#001529' }} icon={<FolderPlus />}></IconButton>
                    </Tooltip>
                    <Tooltip title="Запланировать сохраненные маршруты">
                        <IconButton onClick={showModalCreateSaved} style={{ 'color': '#001529' }} icon={<TruckFrontFill />}></IconButton>
                    </Tooltip>
                </ButtonsWrapper >
            </TopPanel>
        </>

    )

    const DataTable = () => {
        const rowClassName = (record, index) => {
            return index % 2 === 0 ? 'even-row-table' : 'odd-row-table';
        };

        const expandedRowRender = (record) => {
            const tableArr = record.routes;
            const arrayFromHtmlCollection = Array.from(document.getElementsByClassName('ant-table-row')[0].getElementsByTagName('td'));
            let firstTwelveElements = arrayFromHtmlCollection.slice(1, 12);
            let sumWidth = firstTwelveElements.reduce((acc, current) => {
                let elementWidth = current.clientWidth; // получаем ширину элемента
                return acc + elementWidth;
            }, 0);

            const columns = [
                {
                    title: 'Загрузка',
                    dataIndex: 'space',
                    key: 'space',
                    width: sumWidth,
                },
                {
                    title: 'Загрузка',
                    dataIndex: 'routes',
                    render: (text, row) => row[0],
                    key: 'routeA',
                    width: '99.45px',
                },
                {
                    title: 'Выгрузка',
                    dataIndex: 'routes',
                    render: (text, row) => row[1],
                    key: 'routeB',
                    width: '99.45px',
                },
            ];
            return <Table rowClassName={rowClassName} columns={columns} dataSource={tableArr} pagination={false} showHeader={false} style={{ display: 'flex' }} />;
        };

        const expandIcon = ({ expanded, onExpand, record }) => {
            if (record.routes.length > 1) {
                return expanded ? (
                    <ChevronCompactUp style={{ width: '18px', height: '18px' }} onClick={e => onExpand(record, e)} />
                ) : (
                    <ChevronCompactDown style={{ width: '18px', height: '18px' }} onClick={e => onExpand(record, e)} />
                )
            } else {
                return null;
            }

        }

        const onChangePage = (currentPage, currentPageSize) => {
            setCurrentPage(currentPage)
            setCurrentPageSize(currentPageSize)
        }

        return (
            <TableWrapper>
                <Header />
                <ConfigProvider locale={ru_RU}>
                    <Table
                        rowSelection={rowSelection}
                        dataSource={data}
                        columns={columns}
                        size="small"
                        bordered={true}
                        rowClassName={rowClassName}
                        pagination={{
                            onChange: onChangePage,
                            current: currentPage,
                            pageSize: currentPageSize,
                            pageSizeOptions: [50, 100, 150],
                            position: ['topRight', 'bottomRight']
                        }}
                        expandable={{
                            expandedRowRender,
                            expandIcon,
                        }}
                        onChange={handleChange}
                        onRow={(record) => {
                            return {
                                onDoubleClick: () => {
                                    setOrderToEdit(record);
                                    setOpenEdit(true)
                                },
                            };
                        }}
                    />


                </ConfigProvider>
            </TableWrapper >
            
        );
    };

    return (
        <DataTable />
    );
};