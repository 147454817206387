import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const SpinWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 10%;
    height: 450px;
`

const Spinner = (spinning) => {
    return (
        <SpinWrapper>
            <Spin spinning={spinning} size='large'/>
        </SpinWrapper>
    );
};

export default Spinner;