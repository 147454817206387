import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import AuthButtons from './AuthButtons';
import ProfileButton from './ProfileButton';

import { Layout } from 'antd';

const Header = styled.div`
    width 100%;
    margin: 0 auto;
    background: #001529;
`

const HeaderWrapper = ({ setUser, openNotification, setIsLoggin }) => {

    const { Content } = Layout;

    const userInfo = useSelector(state => state.userInfo);

    return (
        <Header>
            <Content>
                {userInfo.isAuth ? <ProfileButton setIsLoggin={setIsLoggin} setUser={setUser} /> : <AuthButtons setUser={setUser} openNotification={openNotification} />}
            </Content>
        </Header>
    );
};

export default HeaderWrapper;