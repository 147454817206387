import React, { useEffect, useState } from 'react';
import { Button, Space, Table, Modal } from 'antd';
import styled from 'styled-components';
import { ButtonPrimary } from '../../styles/buttons';
import moment from 'moment';
import driversService from '../../services/DriverService';
import EditPayment from './EditPayment';
import EditDebt from './EditDebt';
import { ArrowRight } from 'react-bootstrap-icons';

const PaymentItem = styled.div`
    padding: 2px 4px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    color: #fff;
`

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border: none;
`

const Debt = styled(PaymentItem)`
    background-color: #ca3333;
`

const Pay = styled(PaymentItem)`
    background-color: #38761d;
`

const Gray = styled(PaymentItem)`
    background-color: #dee2e4;
    color: #000;
`

const ActionButton = styled(ButtonPrimary)`
    padding: 2px 4px;
    font-size: 12px;
`

const SpaceWrap = styled(Space)`
    display: flex;
    justify-content: flex-end;
`



const PaymentTable = ({ driver, getPaymentsInfo, paymentsInfo, monthDate, isPaidMonth, currentId }) => {


    const [open, setOpen] = useState(false);
    const [openDebt, setOpenDebt] = useState(false);
    const [openPayment, setOpenPayment] = useState();

    useEffect(() => {

    }, [])


    const handleCancelEdit = () => {
        setOpen(false);
    }

    const handleCancelDebt = () => {
        setOpenDebt(false);
    }

    const deleteItem = async (e) => {
        await driversService.deletePayment(e)
        await getPaymentsInfo();
    }

    const compareDates = (a, b) => {
        const dateA = moment(a, 'DD/MM/YYYY');
        const dateB = moment(b, 'DD/MM/YYYY');

        if (dateA.isBefore(dateB)) {
            return -1;
        }

        if (dateA.isAfter(dateB)) {
            return 1;
        }

        return 0;
    };

    const movePaymentToRight = async (payment) => {
        const newPayment = {
            ...payment,
            monthDate: monthDate.startDate
        };
        delete newPayment.date;
        await driversService.editPayment(newPayment)
        await getPaymentsInfo(driver);
    }

    const columns = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => compareDates(a.date, b.date),
            defaultSortOrder: 'descend',
            size: 20,
        },
        {
            title: 'Олата',
            dataIndex: 'sum',
            key: 'sum',
            render: (_, tags) => (
                <>
                    {
                        tags.type === 'pay' ? <Pay>{_}</Pay> :
                            tags.type === 'debt' && _ > 0 ? <Debt>{_}</Debt> :
                                tags.type === 'debt' && _ === 0 ? <Gray>{_}</Gray> :
                                    null
                    }
                </>),
            size: 40,

        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            key: 'comment',
            size: 70,
        },
        {
            title: 'Действия',
            key: 'action',
            render: (_, record) => (
                <SpaceWrap size="small">
                    <ActionButton onClick={() => editPaymentModal(record)}>Изменить</ActionButton>
                    <ActionButton onClick={() =>
                        Modal.confirm({
                            onOk: () => {
                                deleteItem(record)
                            },
                            title: 'Удалить',
                            content: 'Подтвердите удаление',
                            footer: (_, { OkBtn, CancelBtn }) => (
                                <>
                                    <CancelBtn />
                                    <OkBtn />
                                </>
                            ),
                        })
                        }>Удалить</ActionButton>

                    {
            record.type === 'debt' && record.sum > 0 ? <ActionButton onClick={() => editDebtPayment(record)}>Списать</ActionButton> : null
        }
                    {
            isPaidMonth? null: <IconButton onClick={() => movePaymentToRight(record)} style={{ 'color': 'rgb(24,0,181)' }} icon={<ArrowRight />}></IconButton>
        }

                </SpaceWrap >
            ),
size: 50,
        },
    ]

const editPaymentModal = (record) => {
    setOpenPayment(record);
    setOpen(true);
}

const editDebtPayment = (record) => {
    setOpenPayment(record)
    setOpenDebt(true)

}

return (
    <div>

        {
            paymentsInfo &&
            <Table
                columns={columns}
                dataSource={paymentsInfo}

            />
        }
        <Modal
            title="Редактировать выплату"
            open={open}
            destroyOnClose={true}
            onCancel={handleCancelEdit}
            footer={null}
            width={400}
            style={{ top: 10 }}
        >
            <EditPayment setOpen={setOpen} openPayment={openPayment} getPaymentsInfo={getPaymentsInfo} />
        </Modal>

        <Modal
            title="Списать долг"
            open={openDebt}
            destroyOnClose={true}
            onCancel={handleCancelDebt}
            footer={null}
            width={400}
            style={{ top: 10 }}
        >
            <EditDebt setOpen={setOpenDebt} openPayment={openPayment} getPaymentsInfo={getPaymentsInfo} />
        </Modal>
    </div>
);
};

export default PaymentTable;