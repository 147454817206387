import { configureStore  } from '@reduxjs/toolkit';
import UserReducer  from './reducers/userReducer';


let store = configureStore({
    reducer: { 
        userInfo: UserReducer,
    }
})

export default store;