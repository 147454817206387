import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from '../../services/AuthService';

export const fetchRegistration = createAsyncThunk(
    'user/fetchRegistration',
    async function(action) {
        const {email, password, name, surName, role, company_id} = action;
        const response = await AuthService.registration({email, password, name, surName, role, company_id});
        return response.data;
    }
);

export const fetchLogin = createAsyncThunk(
    'user/fetchLogin',
    async function(action) {
        const {email, password} = action;
        const response = await AuthService.login(email, password);
        return response.data;
    }
);

export const updateProfile = createAsyncThunk(
    'user/updateProfile',
    async function(action) {
        const {apiStatistics, apiStandart, email} = action;
        const response = await AuthService.updateProfileApi(apiStatistics, apiStandart, email);
        return response.data;
    }
);

const UserReducer = createSlice({
    name: 'user',
    initialState: {
        isAuth: false,
        user: '',
        isLoading: false,
    },
    reducers: {
        checkIsAuth(state, action) {
            localStorage.setItem('token' , action.payload.accessToken);
            state.isAuth = true;
            state.user = action.payload;
            state.isLoading = null;
        },
        logoutReducer(state) {
            localStorage.removeItem('token');
            state.isAuth = false;
            state.user = null;
        }
    },
    extraReducers: {
        [fetchRegistration.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchRegistration.fulfilled]: (state, action) => {
            localStorage.setItem('token', action.payload.accessToken)
            state.isAuth = true;
            state.isLoading = false;
            state.user =  action.payload;
        },
        [fetchRegistration.rejected]: () => {console.log('rejected')},
        [fetchLogin.pending]: (state) => {
            state.isLoading = true;
        },
        [fetchLogin.fulfilled]: (state, action) => {
            localStorage.setItem('token', action.payload.accessToken);
            state.isAuth = true;
            state.user =  action.payload;
            state.isLoading = false;
        },
        [fetchLogin.rejected]: () => 'rejected',
    },
})

export const { checkIsAuth, logoutReducer } = UserReducer.actions
export default UserReducer.reducer;