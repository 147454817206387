import React from 'react';
import styled from 'styled-components';
import { Button, Form, Input } from 'antd';
import CarService from '../../../services/CarService';

const Wrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 24px;
max-width: 100%;
`
const { TextArea } = Input;

const AddNewCar = ({ setOpen, userCompany }) => {

    const onFinish = async (values) => {
        const res = await CarService.createOrEdit({ ...values, company_id: userCompany });
        if (res.data.message === 'success') {
            setOpen(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Wrapper>
            <Form
                name="basic"
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 20,
                }}
                labelAlign='left'
                style={{
                    width: '100%',
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"

            >
                <Form.Item
                    label="Гос. номер"
                    name="number"
                >
                    <Input allowClear/>
                </Form.Item>

                <Form.Item
                    label="Марка/Модель"
                    name="model"
                >
                    <Input allowClear/>
                </Form.Item>

                <Form.Item
                    label="Объем"
                    name="volume"
                >
                    <Input type="number" addonAfter="м³" allowClear />
                </Form.Item>

                <Form.Item
                    label="Вес"
                    name="weight"
                >
                    <Input type="number" addonAfter="тонн" allowClear/>
                </Form.Item>

                <Form.Item
                    label="Ставка"
                    name="driver_bet"
                >
                    <Input type="number" allowClear/>
                </Form.Item>

                <Form.Item
                    label="Комментарии"
                    name="comment"
                >
                    <TextArea rows={4} allowClear/>
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 20,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
        </Wrapper>
    );
};

export default AddNewCar;