import React, { useEffect, useState } from 'react';
import { ButtonsWrapper, ButtonPrimary } from '../../styles/buttons';
import { Modal, Space, Table } from 'antd';
import UsersService from '../../services/UserServices';
import AddNewUserModal from './AddNewUserModal';


const Users = ({ openNotification }) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            hidden: true,
        },
        {
            title: 'Иия',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Фамилия',
            dataIndex: 'surName',
            key: 'surName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Компания',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Роль',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Действия',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <ButtonPrimary onClick={() => editUser(record)}>Изменить</ButtonPrimary>
                    <ButtonPrimary onClick={() =>
                        Modal.confirm({
                            onOk: () => {
                                deleteUser(record)
                            },
                            title: 'Удалить',
                            content: 'Подтвердите удаление',
                            footer: (_, { OkBtn, CancelBtn }) => (
                                <>
                                    <CancelBtn />
                                    <OkBtn />
                                </>
                            ),
                        })
                        }>Удалить</ButtonPrimary>
                </Space >
            ),
        },
    ].filter(item => !item.hidden);

const [users, setUsers] = useState([]);
const [addUser, setOpenAddUser] = useState(false);

useEffect(() => {
    getUsers();
}, [])

const deleteUser = async (record) => {
    if (record.company === 'AdminCompany') {
        openNotification('Ошибка', 'Нельзя удалять данного пользователя', 'error')
    } else {
        Modal.confirm({
            onOk: async () => {
                await UsersService.delete({ _id: record.id })
                await getUsers();
            },
            title: 'Удалить',
            content: 'Подтвердите удаление',
            footer: (_, { OkBtn, CancelBtn }) => (
                <>
                    <CancelBtn />
                    <OkBtn />
                </>
            ),
        })

    }
}

const editUser = async (record) => {
    if (record.company === 'AdminCompany') {
        openNotification('Ошибка', 'Нельзя изменить данного пользователя', 'error')
    } else {
        // console.log(record)
    }
}

const getUsers = async () => {
    const users = await UsersService.getUsers();
    if (users?.data) {
        setUsers(users.data)
    }
}

const handleCancelAddUser = () => {
    setOpenAddUser(false)
}



return (
    <div>
        <ButtonsWrapper>
            <ButtonPrimary onClick={() => setOpenAddUser(true)}>
                Добавить пользователя
            </ButtonPrimary>
        </ButtonsWrapper>
        {
            users ?
                <Table dataSource={users.map((el, index) => ({
                    id: el._id,
                    key: index,
                    name: el.name,
                    surName: el.surName,
                    email: el.email,
                    company: el.company_id.shortName,
                    role: el.role.roleName
                }))}
                    columns={columns} />
                : null
        }

        <Modal
            title="Добавление нового пользователя"
            open={addUser}
            destroyOnClose={true}
            footer={null}
            width={500}
            style={{ top: 10 }}
            onCancel={handleCancelAddUser}
        >
            <AddNewUserModal setOpenAddUser={setOpenAddUser} getUsers={getUsers} openNotification={openNotification} />
        </Modal>
    </div>
);
};

export default Users;