import React from 'react';
import styled from 'styled-components';
import { ConfigProvider, DatePicker, Form, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import driversService from '../../services/DriverService';
import ru_RU from 'antd/lib/locale/ru_RU';
import moment from 'moment';
import { ButtonPrimary } from '../../styles/buttons';


const Wrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 24px;
max-width: 100%;
`


const AddDebt = ({ setOpen, driver, getPaymentsInfo }) => {

    const onFinish = async (values) => {
        values.driver = driver;
        await driversService.addPayment(values)
        await getPaymentsInfo(values.driver);
        setOpen(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Wrapper>
                {
                    <Form
                    name="addPayment"
                    labelCol={{
                        span: 5,
                    }}
                    wrapperCol={{
                        span: 30,
                    }}
                    labelAlign='left'
                    style={{
                        width: 250,
                    }}
                    layout="horizontal"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={{
                        date: moment(),
                    }}
                >
    
                    <Form.Item
                        label="Тип"
                        name="type"
                    >
                        <Select
                            options={[{ value: 'pay', label: 'Мы должны' }, { value: 'debt', label: 'Водитель должен' }]}
                        />
                    </Form.Item>
    
                    <Form.Item
                        label="Сумма"
                        name="sum"
                    >
                        <Input/>
                    </Form.Item>
                    <ConfigProvider locale={ru_RU}>
                        <Form.Item
                            label="Дата"
                            name="date"
                        >
                            <DatePicker locale={ru_RU} format={'DD/MM/YYYY'} />
                        </Form.Item>
                    </ConfigProvider>
    
                    <Form.Item
                        label="Комм."
                        name="comment"
                    >
                        <TextArea />
                    </Form.Item>
                    <ButtonPrimary type="primary" htmlType="submit">
                        Сохранить
                    </ButtonPrimary>
                </Form>
                }
            </Wrapper>
        </>
    );
};

export default AddDebt;