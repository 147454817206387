import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { fetchRegistration, fetchLogin } from '../../redux/reducers/userReducer';
import Form from 'react-bootstrap/Form';


const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
    color: #fff;
    padding: 16px 16px;
`

const ButtonDefaultStyle = styled.button`
    display: flex;
    align-items: center;
    max-height: 40px;
    padding: 8px 16px;
    border: none;
    color: #fff;
`

const ButtonPrimary = styled(ButtonDefaultStyle)`
    background-color: #001529;
    transition: 0.5s all;
    &:hover{
        outline: 1px solid #001529;
        background-color: #fff; 
        color: #001529;
    }
`

const AuthButtons = ({ setUser, openNotification }) => {

    const [showLogin, setShowLogin] = useState(false);
    // const [showRegistration, setShowRegistration] = useState(false);

    const handleCloseLogin = () => setShowLogin(false);
    const handleShowLogin = () => setShowLogin(true);
    // const handleShowRegistration = () => setShowRegistration(true);
    // const handleCloseRegistration = () => setShowRegistration(false);

    const [email, setEmail] = useState('')
    // const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()

    // const registration = () => dispatch(fetchRegistration({ email, password, name }));


    const login = async () => {
        const res = await dispatch(fetchLogin({ email, password }))
        if (res.error) {
            openNotification('Ошибка!', 'Пароль или пользователя не существует!', 'error')
        } else {
            setUser(res.payload.user)
        }
    }


    return (
        <>
            <ButtonsWrapper>
                <ButtonPrimary onClick={handleShowLogin}>Войти</ButtonPrimary>
                {/* <ButtonPrimary onClick={handleShowRegistration}>Регистрация</ButtonPrimary> */}
            </ButtonsWrapper>


            {/* АВТОРИЗАЦИЯ */}

            <Modal show={showLogin} onHide={handleCloseLogin}>
                <Modal.Header closeButton >
                    <Modal.Title>Войти</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                autoComplete='username'
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Пароль"
                                autoComplete='current-password'
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonPrimary onClick={login} type="submit">
                        Войти
                    </ButtonPrimary>
                    <ButtonPrimary onClick={handleCloseLogin}>
                        Закрыть
                    </ButtonPrimary>
                </Modal.Footer>

            </Modal>

            {/* РЕГИСТРАЦИЯ */}
            {/* <Modal show={showRegistration} onHide={handleCloseRegistration}> */}

                {/* <Modal.Header closeButton>
                    <Modal.Title>Регистрация</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Имя</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Имя"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Пароль"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonPrimary onClick={registration} type="submit">
                        Зарегистрироваться
                    </ButtonPrimary>
                    <ButtonPrimary onClick={handleCloseRegistration}>
                        Закрыть
                    </ButtonPrimary>
                </Modal.Footer>
            </Modal> */}
        </>
    );
};

export default AuthButtons;