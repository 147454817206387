import React, { useEffect, useState } from 'react';
import { ConfigProvider, Input, Transfer } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import { ButtonPrimary } from '../../styles/buttons';
import RolesService from '../../services/RolesService';
import { mockData } from '../../components/Supply/SettingsPage';

const CreateRole = ({ setOpen, getRoles, initialRole, setInitialRole, userCompany, openNotification }) => {
    const initialTargetKeys = initialRole ?
        initialRole.access.flatMap(el => mockData.filter(foo => foo.title === el).map(item => item.key))
        : mockData.filter((item) => Number(item.key) === 0).map((item) => item.key);


    const [targetKeys, setTargetKeys] = useState(initialTargetKeys);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [inputValue, setInputValue] = useState();

    useEffect(() => {
        if (initialRole) {
            setInputValue(initialRole.roleName)
        }
    }, [initialRole])



    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const inputChange = (e) => {
        setInputValue(e.target.value)
    }

    const saveRole = async () => {
        const saveData = {
            roleName: inputValue,
            access: targetKeys.map(el => +el),
            company_id: userCompany,
        }

        if (initialRole?._id) {
            saveData._id = initialRole._id
        }
        setInitialRole()
        await RolesService.create(saveData);
        openNotification('Успех!', 'Новая роль успешно добавлена!', 'info')
        setOpen(false);
        await getRoles();
    }

    return (
        <ConfigProvider locale={ru_RU}>
            <Input placeholder='Введите название роли' onChange={inputChange} value={inputValue}></Input>
            <Transfer
                dataSource={mockData}
                titles={['Не доступно', 'Доступно']}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                render={(item) => item.title}
                style={{
                    'margin': '20px 0'
                }}
            />

            <ButtonPrimary onClick={saveRole}>Сохранить</ButtonPrimary>
        </ConfigProvider>
    );
};

export default CreateRole;