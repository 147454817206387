import $api from '../http';

export default class DocsService {

    static async get(data) {
        try {
            return await $api.post('/get-docs', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async createExport(data) {
        try {
            return await $api.post('/create-export', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async checkDocs(data) {
        try {
            return await $api.post('/check-docs', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async createDocsRegistryItem(data) {
        try {
            return await $api.post('/create-docks-registry-item', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async findDocsRegistryItem(data) {
        try {
            return await $api.post('/find-docks-registry-item', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async deleteDocsRegistryItem(data) {
        try {
            return await $api.post('/delete-docks-registry-item', data);
        } catch(e) {
            console.log(e)
        }
    }
}