import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DaysServise from '../../services/DaysServise';
import { Button, Card, Form, Input, Space, Switch } from 'antd';

const Others = () => {

    const [formDays] = Form.useForm();
    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const getDays = useCallback( async () => {
        const res = await DaysServise.getDaysRange({ company_id: userCompany });
        setDataRecived(true)
        formDays.setFieldsValue({
            isActive: res.data.isActive,
            daysForAdd: res.data.daysForAdd,
            _id:  res.data._id,
        })
    }, [userCompany, formDays])

    useEffect(() => {
        getDays();
    }, [getDays])

    const [dataRecived, setDataRecived] = useState();
    
    const onFinishDays = async (values) => {
        await DaysServise.createOrEdit({ company_id: userCompany, ...values });
        await getDays();
    }

    const onFinishFailedDays = () => {
        console.log('error')
    }

    return (
        <Space direction="vertical" size={16}>
            <Card
                title="Настройка создания запланированных маршрутов"
                style={{
                    width: 800,
                }}
            >
                Сервис позволяет автоматически добавлять сохраненные заказы у которых установлена переиодичность. К примеру, если есть маршруты которые всегда должны быть по средам, сохраняем этот маршрут и выбираем периодичность по средам. Сервис ночью, в 01:00 по МСК будет автоматически добавлять эти маршруты на страницу заказов. Если указано 0, то в ночь со вторника на среду заказ будет создан, если 1, то в ночь с понедельника на вторник будет создан маршрут на среду.
                <br />
                <br />
                {
                    dataRecived ?
                        <Form
                            name="basic"
                            form={formDays}
                            onFinish={onFinishDays}
                            onFinishFailed={onFinishFailedDays}
                            autoComplete="off"
                        >

                            <Form.Item
                                label="Активировать"
                                name="isActive"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>

                            <Form.Item
                                label="ID"
                                name="_id"
                                hidden={true}
                            >
                                <Input></Input>
                            </Form.Item>


                            <Form.Item
                                label="Количество дней (0 - следующий день)"
                                name="daysForAdd"
                            >
                                <Input placeholder='Количество дней'></Input>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Form> : null
                }

            </Card>
        </Space>
    );
};

export default Others;