import React, { useCallback, useEffect, useState } from 'react';
import UserService from '../../services/UserServices';
import { Modal, Space, Table } from 'antd';
import { useSelector } from 'react-redux';
import { ButtonPrimary } from '../../styles/buttons';
import EditUser from './EditUser';

const Users = () => {

    const [users, setUsers] = useState([])
    const [open, setOpen] = useState(false);
    const [initialUser, setInitialUser] = useState(false);

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const getUsers = useCallback( async () => {
        const res = await UserService.getUsers({ company_id: userCompany });
        setUsers(res.data.map(el => {
            return {
                ...el,
                role: el.role?.roleName,
                key: el._id,
                isActivated: el.isActivated ? 'Да' : 'Нет'
            }
        }))
    }, [userCompany])

    useEffect(() => {
        getUsers();
    }, [getUsers])

    const editUser = (record) => {
        setInitialUser(record)
        setOpen(true)
    }

    const deleteUser = async (record) => {
        await UserService.delete(record);
        getUsers();
    }

    const columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Роль',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Активирован?',
            dataIndex: 'isActivated',
            key: 'isActivated',
        },
        {
            title: 'Действия',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <ButtonPrimary onClick={() => editUser(record)}>Изменить</ButtonPrimary>
                    <ButtonPrimary onClick={() =>
                        Modal.confirm({
                            onOk: () => {
                                deleteUser(record)
                            },
                            title: 'Удалить',
                            content: 'Подтвердите удаление',
                            footer: (_, { OkBtn, CancelBtn }) => (
                                <>
                                    <CancelBtn />
                                    <OkBtn />
                                </>
                            ),
                        })
                    }>Удалить</ButtonPrimary>
                </Space >
            ),
        },
    ]



    const handleCancel = () => {
        setOpen(false);
    }

    return (
        <div>
            <Table columns={columns} dataSource={users} />
            <Modal
                title="Новая роль"
                open={open}
                onCancel={handleCancel}
                footer={null}
                destroyOnClose={true}
                width={350}
                style={{ top: 10 }}
            >
                <EditUser setOpen={setOpen} initialUser={initialUser} setInitialUser={setInitialUser} getUsers={getUsers} userCompany={userCompany}></EditUser>
            </Modal>
        </div>
    );
};

export default Users;