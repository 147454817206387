import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import DriverService from '../../services/DriverService';
import moment from 'moment';

const NeedPay = styled.div`
    color: #e7551c;
    font-weight: bold;
`

const Payed = styled.div`
    color: #38761d;
    font-weight: bold;
`

const columns = [
    {
        title: 'Начало',
        dataIndex: 'startPeriod',
        key: 'startPeriod',
    },
    {
        title: 'Конец',
        dataIndex: 'endPeriod',
        key: 'endPeriod',
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
    },
];

const PaymentCalendar = ({ driver }) => {

    const [driverData, setDriverData] = useState();

    const getSalary = useCallback( async () => {
        const res = await DriverService.getSalaryInfo({ _id: driver });
        const dataForTable = res.data.map(el => {
            return {
                startPeriod: moment(el.startPeriod).format('DD/MM/YY'),
                endPeriod: moment(el.endPeriod).format('DD/MM/YY'),
                status: el.status === 'needToPay' ? <NeedPay>Требуется оплата</NeedPay> : 
                el.status === 'payed' ? <Payed>Оплата сформирована</Payed> : 'Неизвестно'
            }
        })

        setDriverData(dataForTable)
    }, [driver])

    useEffect(() => {
        getSalary();
    }, [getSalary])




    return (
        <>
        {
            driverData && <Table dataSource={driverData} columns={columns} />
        }
            
        </>
    );
};

export default PaymentCalendar;