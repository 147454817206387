import React, { useEffect, useState } from 'react';

import { ButtonsWrapper, ButtonPrimary } from '../../styles/buttons';
import AgentsTable from './AgentsTable';
import { useSelector } from 'react-redux';

const Agents = ({ openNotification }) => {

    useEffect(() => {
        document.title = "Контрагенты - ГрузДок - сервис учета грузоперевозок"
     }, []);


    const [open, setOpen] = useState(false);
    const [openNewProject, setOpenNewProject] = useState(false);

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const showModal = () => {
        setOpen(true);
    };

    const showModalProject = () => {
        setOpenNewProject(true);
    };

    return (
        <>
            <ButtonsWrapper>
                <ButtonPrimary onClick={showModal}
                >
                    Добавить контрагента
                </ButtonPrimary>

                <ButtonPrimary onClick={showModalProject}
                >
                    Добавить проект
                </ButtonPrimary>
            </ButtonsWrapper>
            <AgentsTable setOpen={setOpen} open={open} setOpenNewProject={setOpenNewProject} openNewProject={openNewProject} userCompany={userCompany} openNotification={openNotification}/>
        </>
    )
};

export default Agents;