import { Modal, Space, Table, Form, Input } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ButtonPrimary } from '../../styles/buttons';
import driversService from '../../services/DriverService';
import OrdersService from '../../services/OrdersService';

const TablesWrapper = styled.div`
    display: flex;
`

const RoutesTableFull = ({ data, driver, currentId, getData, isPaidMonth, socket }) => {

    useEffect(() => {
    }, [])

    const [isModalOpen, setIsModalOpen] = useState(false);

    const currentEdit = useRef([]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {

        if (!isPaidMonth) {
            await OrdersService.createOrEdit(values)
            socket.emit('message', {
                text: 'update',
                room: 'Orders',
                id: `${socket.id}${Math.random()}`,
                socketID: socket.id,
            });
        } else {
            await driversService.editPaymentMonth({ ...values, id: currentId, type: 'routes' })
        }
        setIsModalOpen(false)
        await getData();
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const structuredData = data.map(el => {

        return {
            ...el,
            date: moment(el.date).format('DD/MM/YYYY'),
            agent: el.agent.shortName,
            driver: el.driver.name,
            number: el.number.number,
            company: el.company.shortName,
        }
    })

    const deleteRow = (data) => {
    }

    const columns = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            width: '75px'
        },
        {
            title: 'Компания',
            dataIndex: 'agent',
            key: 'agent',
            width: '125px'
        },
        {
            title: 'Водитель',
            dataIndex: 'driver',
            key: 'driver',
            width: '75px'
        },
        {
            title: 'Номер',
            dataIndex: 'number',
            key: 'number',
            width: '75px'
        },
        {
            title: 'Контрагент',
            dataIndex: 'company',
            key: 'company',
            width: '180px'
        },
        {
            title: 'Ставка',
            dataIndex: 'bet',
            key: 'bet',
            width: '75px'
        },
        {
            title: 'Маршрут',
            dataIndex: 'routeA',
            key: 'routeA',
            width: '400px'
        },
        {
            title: 'Маршрут',
            dataIndex: 'routeB',
            key: 'routeB',
            width: '400px'
        },
        {
            title: 'Оплата',
            dataIndex: 'driver_bet',
            width: '90px'
        },
        {
            title: 'Действия',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <ButtonPrimary onClick={() => {
                        currentEdit.current = _;
                        showModal(true)
                    }}>Редактировать</ButtonPrimary>
                    <ButtonPrimary onClick={() => {
                        Modal.confirm({
                            onOk: () => deleteRow(_),
                            title: 'Удалить',
                            content: 'Подтвердите удаление',
                            footer: (_, { OkBtn, CancelBtn }) => (
                                <>
                                    <CancelBtn />
                                    <OkBtn />
                                </>
                            ),
                        })
                    }}>Удалить</ButtonPrimary>
                </Space>
            ),
        },
    ];



    return (
        <>

            <TablesWrapper>
                {
                    data &&
                    <Table
                        columns={columns}
                        dataSource={structuredData}
                        pagination={false}
                        bordered
                        enableEditing={true}
                        style={{
                            width: '100%'
                        }} />
                }
            </TablesWrapper>
            <Modal title="Редактирование" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <Form
                    name="basic"
                    initialValues={{
                        bet: currentEdit.current.bet,
                        driver_bet: currentEdit.current.driver_bet,
                        _id: currentEdit.current._id,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Ставка"
                        name="bet"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Оплата"
                        name="driver_bet"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="ID"
                        name="_id"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Space size="middle">
                            <ButtonPrimary type="primary" htmlType="submit">
                                Сохранить
                            </ButtonPrimary>
                            <ButtonPrimary onClick={() => setIsModalOpen(false)}>
                                Закрыть
                            </ButtonPrimary>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal >
        </>
    );
};

export default RoutesTableFull;