import React, { useEffect, useState } from 'react';
import { Table, Button, Space, Modal } from 'antd';
import styled from 'styled-components';
import { ArrowLeft, Pencil } from 'react-bootstrap-icons';
import driversService from '../../services/DriverService';
import { ButtonPrimary } from '../../styles/buttons';
import moment from 'moment';
import EditPayments from './EditPayments';

const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const TableWrapper = styled.div`
  border: 1px solid #c8c8c8;
  padding: 16px 8px;
`

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border: none;
`

const PaymentButton = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
`

const PaymentSuccess = styled.div`
  padding: 8px;
  background-color: #38761d;
  color: #fff;
`

const EditPen = styled(ButtonPrimary)`
  padding: 4px;
  background-color: #498ecd;
`

const RoutesTable = ({ driver, data, paymentsInfo, monthDate, getPaymentsInfo, fullRoutes, isPaidMonth, onFinishHadler, dateForFinish, currentId, driverPaymentMonth }) => {

  const [dataForDriver, setdataForDriver] = useState();

  const [openEditPayment, setOpenEditPayment] = useState();
  useEffect(() => {
    if (driverPaymentMonth) {
      createData(data, driverPaymentMonth)
    } else {
      createData(data, paymentsInfo)
    }
  }, [data, driverPaymentMonth, paymentsInfo])

  const createData = async (routes, info) => {
    const tableArray = [];

    tableArray.push({
      key: 1,
      name: 'Рейсы',
      sum: routes.reduce((a, b) => b.driver_bet ? a + b.driver_bet : a + 0, 0)
    })

    info.forEach((el, index) => {
      tableArray.push({
        key: index + 2,
        date: el.date,
        name: `${el.comment}`,
        sum: el.type === 'debt' ? -el.sum : el.sum,
        _id: el._id
      })
    })

    tableArray.push({
      key: 'end',
      name: 'Итого',
      sum: tableArray.reduce((a, b) => a + +b.sum, 0)
    })
    setdataForDriver(tableArray);
  }

  const movePaymentToLeft = async (record) => {
    await driversService.editPayment({ _id: record._id, monthDate: null })
    await getPaymentsInfo(driver);
  }

  const columns = [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      width: '100px',
      render: (_, record) => {
        return moment(_).format('DD/MM/YYYY')
      },
    },
    {
      title: 'Маршрут',
      dataIndex: 'routeA',
      key: 'route',
    },
    {
      title: 'Маршрут',
      dataIndex: 'routeB',
      key: 'route',
    },
    {
      title: 'Оплата',
      dataIndex: 'driver_bet',
      width: '200px',
    },
  ];

  const columnsForDriver = [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      width: '100px'
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      width: '200px'
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        return (
          record._id && (isPaidMonth === false) ?
            <IconButton onClick={() => movePaymentToLeft(record)} style={{ 'color': 'rgb(24,0,181)' }} icon={<ArrowLeft />}></IconButton>
            : null
        );
      },
      width: '50px'
    },
  ];

  const savePayment = async () => {

    const routesFroSave = fullRoutes.map(el => {
      delete el._id;
      return el;
    })

    const dateForSave = monthDate.current.startDate.split(' ')[0].split('-');
    const objectToSave = {
      driver: driver._id,
      month: +dateForSave[1],
      year: +dateForSave[0],
      day: +dateForSave[2],
      status: 'payed',
      routes: routesFroSave,
      paymentHistory: paymentsInfo,
      dates: {
        start: moment(monthDate.current.startDate).utc().add(3, 'hours'),
        end: moment(monthDate.current.endDate).utc().add(3, 'hours')
      }
    }

    await driversService.addPaymentMonth(objectToSave);
    await onFinishHadler({ driver: driver._id, date: dateForFinish });
  }

  const handleCancelEditPayment = () => {
    setOpenEditPayment(false);
  }

  return (

    <>
      <Table columns={columns} dataSource={data} pagination={{ pageSize: 5 }} />
      {
        data &&
        <TableWrapper>
          <TableHeader>
            <Space>
              <>
                <div>
                  <h4>
                    {driver.name}
                  </h4>
                </div>
                <div>
                  <h6>
                    {moment(monthDate.current.startDate).format('DD-MM-YYYY')} / {moment(monthDate.current.endDate).format('DD-MM-YYYY')}
                  </h6>
                </div>
              </>


              {
                isPaidMonth ?
                  <EditPen onClick={() => setOpenEditPayment(true)}>
                    <Pencil />
                  </EditPen> : null
              }


            </Space>
          </TableHeader>
          <Table columns={columnsForDriver} dataSource={dataForDriver} pagination={false} showHeader={false} bordered />
          <PaymentButton>
            {
              isPaidMonth ? <PaymentSuccess>Оплата была сформирована</PaymentSuccess> : <ButtonPrimary onClick={savePayment}>Сформировать оплату</ButtonPrimary>
            }

          </PaymentButton>

        </TableWrapper>
      }
      <Modal
        title="Новая заметка"
        open={openEditPayment}
        onCancel={handleCancelEditPayment}
        footer={null}
        destroyOnClose={true}
        width={800}
        style={{ top: 10 }}
      >
        <EditPayments paymentsInfo={driverPaymentMonth ? driverPaymentMonth : paymentsInfo} currentId={currentId} setOpenEditPayment={setOpenEditPayment}
          onFinishHadler={onFinishHadler}
          driver={driver}
          dateForFinish={dateForFinish}
        />
      </Modal>
    </>
  );
};

export default RoutesTable;