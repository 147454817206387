import React from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Select } from 'antd';
import CompaniesService from '../../services/CompaniesService';
import TextArea from 'antd/es/input/TextArea';
import PhoneMaskInput from '../../components/MaskInput/PhoneMaskInput';
import DateMaskInput from '../../components/MaskInput/DateMaskInput';

const Wrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 24px;
max-width: 100%;
`

const nds = [
    {
        label: '20%',
        value: 20
    },
    {
        label: '13%',
        value: 13
    },
    {
        label: '6%',
        value: 6
    },
    {
        label: '0%',
        value: 0
    },
]

const EditCompany = ({ setOpen, openCompany }) => {

    const onFinish = async (values) => {
        await CompaniesService.createOrEdit({ ...values, shortName: values.type === 'official' ? values.shortName : `${values.name} ${values.secondName} ${values.surName}` });
        setOpen(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Wrapper>
                {
                    openCompany.type === 'official' ?
                        <Form
                            name="edit"
                            labelCol={{
                                span: 5,
                            }}
                            wrapperCol={{
                                span: 30,
                            }}
                            labelAlign='left'
                            style={{
                                width: 800,
                            }}
                            layout='horizontal'
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            initialValues={{
                                ...openCompany
                            }}

                        >
                            <Form.Item
                                label="Полное наименование"
                                name="fullName"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Краткое наименование"
                                name="shortName"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="ИНН"
                                name="inn"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="КПП"
                                name="kpp"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Юридический адрес"
                                name="adress"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="ОГРН"
                                name="ogrn"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="ОКПО"
                                name="okpo"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="ОКВЭД"
                                name="okved"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Руководитель"
                                name="boss"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Должность Руководителя"
                                name="bossJob"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Бухгалтер"
                                name="accountier"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Банк"
                                name="bank"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="БИК"
                                name="bik"
                            >
                                <Input type="number" />
                            </Form.Item>

                            <Form.Item
                                label="Р/с"
                                name="billOne"
                            >
                                <Input type="number" />
                            </Form.Item>

                            <Form.Item
                                label="К/с"
                                name="billTwo"
                            >
                                <Input type="number" />
                            </Form.Item>

                            <Form.Item
                                label="НДС"
                                name="nds"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательно для заполнения',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="НДС"
                                    allowClear
                                    options={nds}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Начало нумерации"
                                name="startNumber"
                            >
                                <Input type='number' />
                            </Form.Item>

                            <Form.Item
                                label="Префикс"
                                name="prefix"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Телефон"
                                name="phone"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="ID"
                                name="_id"
                                hidden
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Комментарии"
                                name="comment"
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                            <Form.Item
                                label="type"
                                name="type"
                                hidden
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    offset: 20,
                                    span: 16,
                                }}
                            >
                                <Button type="primary" htmlType="submit">
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Form>
                        :
                        <Form
                            name="basic"
                            labelCol={{
                                span: 4,
                            }}
                            wrapperCol={{
                                span: 30,
                            }}
                            labelAlign='left'
                            style={{
                                width: 700,
                                marginTop: '30px'
                            }}
                            layout='horizontal'
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            initialValues={{
                                ...openCompany
                            }}
                        >
                            <Form.Item
                                label="Имя"
                                name="name"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Фамилия"
                                name="secondName"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Отчество"
                                name="surName"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Телефон"
                                name="phone"
                            >
                                <PhoneMaskInput />
                            </Form.Item>

                            <Form.Item
                                label="Дата рождения"
                                name="birth"
                            >
                                <DateMaskInput />
                            </Form.Item>

                            <Form.Item
                                label="Номер паспорта"
                                name="passNumber"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Дата выдачи"
                                name="passDate"
                            >
                                <DateMaskInput />
                            </Form.Item>
                            <Form.Item
                                label="Кем выдан"
                                name="passWho"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Прописка"
                                name="adress"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Банк"
                                name="bank"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="БИК"
                                name="bik"
                            >
                                <Input type="number" />
                            </Form.Item>

                            <Form.Item
                                label="Р/с"
                                name="billOne"
                            >
                                <Input type="number" />
                            </Form.Item>

                            <Form.Item
                                label="К/с"
                                name="billTwo"
                            >
                                <Input type="number" />
                            </Form.Item>

                            <Form.Item
                                label="Комментарий"
                                name="comment"
                            >
                                <TextArea />
                            </Form.Item>
                            <Form.Item
                                label="ID"
                                name="_id"
                                hidden
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="type"
                                name="type"
                                hidden
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    offset: 20,
                                    span: 16,
                                }}
                            >
                                <Button type="primary" htmlType="submit">
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Form>
                }

            </Wrapper>
        </>
    );
};

export default EditCompany;