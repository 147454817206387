import React from 'react';
import { Tabs } from 'antd';
import EditCar from './EditCar';
import EditCarDocs from './EditCarDocs';
import { PageWrapper } from '../../../components/StyledComponents/styled';


const EdiCarModal = ({ setOpenEdit, car, openNotification }) => {

    
const items = [
    {
      key: '1',
      label: 'Общее',
      children: <EditCar setOpenEdit={setOpenEdit} car={car} openNotification={openNotification} />,
    },
    {
      key: '2',
      label: 'Документы',
      children: <EditCarDocs setOpenEdit={setOpenEdit} car={car} openNotification={openNotification} />,
    },
  ];

  
    return (
        <PageWrapper>
            <Tabs defaultActiveKey="1" items={items} destroyInactiveTabPane={true} />
        </PageWrapper>
    );
};

export default EdiCarModal;