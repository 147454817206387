import styled from "styled-components";

export const WrapperBackground = styled.div`
    display: flex;
    align-items: middle;
    justify-content: center;
    background: #ffda4d;
    width: 48px;
    border-radius: 20%;
    font-weight: 900;
`

export const YellowBackground = styled(WrapperBackground)`
    background: #ffda4d;
`

export const RedBackground = styled(WrapperBackground)`
    background: #b40a0a;
    color: #fff;
`

export const GreenBackground = styled(WrapperBackground)`
    background: #4d966f;
    color: #fff;
`