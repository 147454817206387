import { Button, Checkbox, Form, Input, List, Space, Tooltip, Pagination } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react';
import { ArrowClockwise, DashCircleDotted, PlusCircleDotted } from 'react-bootstrap-icons';
import styled from 'styled-components';
import moment from 'moment';
import { ButtonPrimary } from '../../../styles/buttons';
import RegistryService from '../../../services/RegistryService';
import DocksService from '../../../services/DocsService';
import DocsService from '../../../services/DocsService';
import OrdersService from '../../../services/OrdersService';
import Spinner from '../../../components/Supply/Spinner';


const Wrapper = styled.div`
    margin-top: 16px;
`

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`

const Registry = ({ data, checkDocs, createDocs, openNotification }) => {
    const [RegsItems, setItems] = useState();
    const [formRegs] = Form.useForm();

    const [spinning, setSpinning] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 50; // количество элементов на странице

    const createData = useCallback(async (items = data) => {
        const ordersFromCompleted = await DocksService.findDocsRegistryItem({ registryRef: data._id });
        if (ordersFromCompleted?.data.length > 0) {
            setItems({ orders: ordersFromCompleted.data.sort((a, b) => parseDate(a.date) - parseDate(b.date)) });
            formRegs.setFieldValue('header', data.regsHeader)
        } else {
            const orders = await OrdersService.get({ registryRef: data._id });
            const res = orders.data.map(el => {
                return ({
                    date: moment(el.date).format('DD.MM.YYYY'),
                    startTime: el?.startTime ? el.startTime : '',
                    driverName: el.driver?.name ? el.driver.name : '',
                    number: el.number?.number ? el.number.number : '',
                    bet: el.bet ? el.bet : '',
                    routes: el.routes ? el.routes.map(el => ({ a: el[0], b: el[1] })) : '',
                    qty: 1,
                    isRound: el.isRound,
                })
            })

            const ordersToTable =
                res.sort((a, b) => {
                    if (moment(a.date).isSame(b.date)) {
                        const ATime = +a.startTime.replace(':', '');
                        const BTime = +b.startTime.replace(':', '');
                        return ATime - BTime;
                    } else {
                        return parseDate(a.date) - parseDate(b.date)
                    }
                })


            setItems({ orders: ordersToTable });
            // Преобразуем массив дат в массив объектов Moment
            const momentDates = orders.data.map(el => moment(el.date));

            // Находим самую раннюю дату
            const earliestDate = moment.min(momentDates);

            // Находим самую позднюю дату
            const latestDate = moment.max(momentDates);
            if (moment(earliestDate).format('DD.MM.YYYY') === moment(latestDate).format('DD.MM.YYYY')) {
                formRegs.setFieldValue('header', `Реестр выполненных транспортных услуг № ${items.number} от ${moment(items.date).format('DD.MM.YYYY')}г. Исполнителем ${data.company.shortName} для Заказчика ${items.agent.shortName} по договору № ${items.agent.contractNumber} от ${moment(items.agent.contractDate, "DD.MM.YYYY").format('DD.MM.YYYY')} за период ${moment(earliestDate).format('DD.MM.YYYY')}г.`,)
            } else {
                formRegs.setFieldValue('header', `Реестр выполненных транспортных услуг № ${items.number} от ${moment(items.date).format('DD.MM.YYYY')}г. Исполнителем ${data.company.shortName} для Заказчика ${items.agent.shortName} по договору № ${items.agent.contractNumber} от ${moment(items.agent.contractDate, "DD.MM.YYYY").format('DD.MM.YYYY')} за период с ${moment(earliestDate).format('DD.MM.YYYY')}г. по ${moment(latestDate).format('DD.MM.YYYY')}г.`,)
            }

        }
    }, [data, formRegs])

    useEffect(() => {
        createData();
    }, [createData])

    const onFinish = async (values) => {


        function hasUndefinedOrNull(obj) {
            let objForCheck = {};
            Object.assign(objForCheck, obj);
            delete objForCheck.routes
            return Object.values(objForCheck).some(value => 
                value === null || value === undefined || 
                (typeof value === 'object' && hasUndefinedOrNull(value)) || value.length === 0
            );
        }
        if (hasUndefinedOrNull(values.ordersToRegs)) {
            openNotification('Ошибка!', 'Присутствуют пустые поля', 'error')
            return;
        }

        function isRoutesArrayValid(obj) {

            let isOkay = true;
            obj.forEach(el => {
                if (el.hasOwnProperty('routes')) {
                    if (el.routes.length === 0) {
                        isOkay = false
                    }
                    // Проверяем, что 'routes' это массив и его длина больше 0
                }
            })
            return isOkay;
        }
       
        if (!isRoutesArrayValid(values.ordersToRegs)) {
            openNotification('Ошибка!', 'Где-то пустой маршрут', 'error')
            return;
        }
        setSpinning(true);
        try {
            let registryId = await RegistryService.createOrEdit({ _id: data._id, regsHeader: values.header }); // Получаем ID регистра
            registryId = registryId.data._id;
            await DocksService.deleteDocsRegistryItem({ registryRef: data._id }); // Удаляем прошлые итемы регистра
            const promises = values.ordersToRegs.map(async el => await DocsService.createDocsRegistryItem({ ...el, registryRef: registryId })); // Создаем новые итемы регистра
            await Promise.all(promises);
        } catch (e) {
            setSpinning(false);
            console.error('Произошла ошибка при добавлении заказов в реестр документов:', e);
        }

        try {
            await createData();
            await createDocs();
            setSpinning(false);
        } catch (error) {
            console.error('Ошибка выполнения:', error);
            setSpinning(false); // В случае ошибки также остановить вращение
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const resetValues = async () => {
        const orders = await OrdersService.get({ registryRef: data._id });
        const res = orders.data.map(el => {
            return ({
                date: moment(el.date).format('DD.MM.YYYY'),
                startTime: el.startTime,
                driverName: el.driver.name,
                number: el.number.number,
                bet: el.bet,
                routes: el.routes ? el.routes.map(el => ({ a: el[0], b: el[1] })) : '',
                qty: 1,
                isRound: el.isRound,
            })

        })

        const ordersToTable =
            res.sort((a, b) => {
                if (moment(a.date).isSame(b.date)) {
                    const ATime = +a.startTime.replace(':', '');
                    const BTime = +b.startTime.replace(':', '');
                    return ATime - BTime;
                } else {
                    return parseDate(a.date) - parseDate(b.date)
                }
            })

        setItems({ orders: ordersToTable })

        formRegs.setFieldsValue({
            ordersToRegs: ordersToTable
        })
    }

    const titles = [
        {
            title: 'Дата',
        },
        {
            title: 'ФИО',
        },
        {
            title: 'Гос. Номер',
        },
        {
            title: 'Точка отправления',
        },
        {
            title: 'Точка прибытия',
        },
        {
            title: 'Сумма',
        },
        {
            title: 'Количество',
        },
        {
            title: 'Кругорейс',
        },
    ];

    const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split('.');
        return new Date(`${year}-${month}-${day}`); // Формат для создания объекта Date (гггг-мм-дд)
    };

    return (
        <Wrapper>
            <Space style={{ marginBottom: '16px' }}>
                <h5>Реестр выполненых работ</h5>
                {
                    data.status !== 'Paid' &&
                    <Tooltip title="Сбросить">
                        <IconButton onClick={resetValues} alt='Сбросить' icon={<ArrowClockwise />}></IconButton>
                    </Tooltip>
                }
            </Space>
            {
                spinning ?
                    <Spinner spinning={spinning} /> :
                    <>
                        {
                            RegsItems &&
                            <Form
                                form={formRegs}
                                layout='horizontal'
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                disabled={data.status === 'Paid' ? true : false}
                            >
                                <Form.Item
                                    label="Заголовок"
                                    name="header"
                                >
                                    <TextArea placeholder="Заголовок документа" />
                                </Form.Item>
                                <List
                                    grid={{
                                        gutter: 16,
                                        column: 8,
                                    }}

                                    style={{
                                        marginLeft: '16px',
                                    }}
                                    dataSource={titles}
                                    renderItem={(item) => (
                                        <List.Item>
                                            {item.title}
                                        </List.Item>
                                    )}
                                />
                                <Form.List name="ordersToRegs"
                                    initialValue={RegsItems.orders}
                                >
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                                                .map(({ key, name, ...restField }) => (
                                                    <Space
                                                        key={key}
                                                        style={{
                                                            display: 'flex',
                                                            width: '100%',
                                                            alignItems: 'center',
                                                            margin: '16px 0',
                                                            borderBottom: '1px solid #d7deea'
                                                        }}
                                                        align="start"
                                                    >
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'date']}
                                                        >
                                                            <Input placeholder="Дата" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'startTime']}
                                                            hidden

                                                        >
                                                            <Input placeholder="Время" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'driverName']}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'number']}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                        <Space direction="vertical" size="middle">
                                                            <Form.Item>
                                                                <Form.List name={[key, 'routes']}>
                                                                    {(subFields, subOpt) => (
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                rowGap: 16,
                                                                            }}
                                                                        >
                                                                            {subFields.map((subField) => (
                                                                                <Space key={subField.key}>
                                                                                    <Form.Item noStyle name={[subField.name, `a`]}>
                                                                                        <Input placeholder="Загрузка" />
                                                                                    </Form.Item>
                                                                                    <DashCircleDotted
                                                                                        onClick={() => {
                                                                                            subOpt.remove(subField.name);
                                                                                        }}
                                                                                    />
                                                                                </Space>
                                                                            ))}
                                                                            <Button type="dashed" onClick={() => subOpt.add()} block>
                                                                                + Добавить
                                                                            </Button>
                                                                        </div>
                                                                    )}
                                                                </Form.List>
                                                            </Form.Item>
                                                        </Space>

                                                        <Space direction="vertical" size="middle">
                                                            <Form.Item>
                                                                <Form.List name={[key, 'routes']}>
                                                                    {(subFields, subOpt) => (
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                rowGap: 16,
                                                                            }}
                                                                        >
                                                                            {subFields.map((subField) => (
                                                                                <Space key={subField.key}>
                                                                                    <Form.Item noStyle name={[subField.name, `b`]}>
                                                                                        <Input placeholder="Выгрузка" />
                                                                                    </Form.Item>
                                                                                    <DashCircleDotted
                                                                                        onClick={() => {
                                                                                            subOpt.remove(subField.name);
                                                                                        }}
                                                                                    />
                                                                                </Space>
                                                                            ))}
                                                                            <Button type="dashed" onClick={() => subOpt.add()} block>
                                                                                + Добавить
                                                                            </Button>
                                                                        </div>
                                                                    )}
                                                                </Form.List>
                                                            </Form.Item>
                                                        </Space>

                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'bet']}

                                                        >
                                                            <Input type="number" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'qty']}
                                                        >
                                                            <Input />
                                                        </Form.Item>

                                                        <Form.Item
                                                            {...restField}
                                                            style={{ width: '182px', display: 'flex', justifyContent: 'center' }}
                                                            name={[name, 'isRound']}
                                                            valuePropName="checked"
                                                        >
                                                            <Checkbox />
                                                        </Form.Item>

                                                        <DashCircleDotted onClick={() => {
                                                            remove(name)
                                                        }} />
                                                    </Space>
                                                ))}

                                            {
                                                currentPage === Math.ceil(fields.length / itemsPerPage) ?
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={() => add()} block icon={<PlusCircleDotted />}>
                                                            Добавить
                                                        </Button>
                                                    </Form.Item> : null
                                            }
                                            <Pagination
                                                current={currentPage}
                                                defaultPageSize={itemsPerPage}
                                                pageSizeOptions={[50]}
                                                total={fields.length}
                                                onChange={(page) => setCurrentPage(page)}
                                            />
                                        </>
                                    )}
                                </Form.List>
                                <Form.Item style={{ 'marginTop': '16px' }}>
                                    {
                                        data.status !== 'Paid' &&
                                        <ButtonPrimary type="primary" htmlType="submit">
                                            Сформировать
                                        </ButtonPrimary>
                                    }
                                </Form.Item>
                            </Form>
                        }
                    </>
            }
        </Wrapper >
    );
};

export default Registry;