import $api from '../http';

export default class UsersService {

    static async getUsers(data) {
        try {
            return await $api.post('/get-users', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async update(data) {
        try {
            return await $api.post('/update-user', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async delete(data) {
        try {
            return await $api.post('/delete-user', data);
        } catch(e) {
            console.log(e)
        }
    }
}