import OrdersService from "../services/OrdersService";

export const getOrders = async (query) => {
    const orders = await OrdersService.get(query);
    return orders.data;
}

export const removeDuplicates = (array) => {
    return array.filter((obj, index, self) =>
        obj.value && index === self.findIndex((t) => (
            t.value === obj.value
        ))
    ).sort();
}