import React, { useCallback, useEffect, useState } from 'react';
import CarDocsService from '../../../services/CarDocsService';
import { useSelector } from 'react-redux';
import { PageWrapper, TabItemWrapper } from '../../../components/StyledComponents/styled';
import { Button, Input, Modal, Space, Table } from 'antd';
import { GreenBackground, RedBackground, YellowBackground } from '../../../components/TextChanger/ColorTextBg';
import { TextWrapper } from '../../../components/TextChanger/TextWrapper';
import CarsService from '../../../services/CarService';
import EditCarModal from '../EditCar/EdiCarModal';

const CarsDocsPage = ({ openNotification }) => {
    const [types, setTypes] = useState();
    const [docs, setDocs] = useState();
    const [cars, setCars] = useState();
    const [searchValue, setSearchValue] = useState();
    const [openCar, setOpenCar] = useState('')
    const [openEdit, setOpenEdit] = useState(false);
    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const getTypes = useCallback(async () => {
        const res = await CarDocsService.getType({ company_id: userCompany });
        setTypes(res.data);
    }, [userCompany]);

    const getDocs = useCallback(async () => {
        const res = await CarDocsService.getDoc({ company_id: userCompany });
        setDocs(res.data);
    }, [userCompany]);

    const getCars = useCallback(async () => {
        const res = await CarsService.get({ company_id: userCompany });
        setCars(res.data.map((el, index) => ({ ...el, key: el._id + index })));
        setSearchValue(res.data.map((el, index) => ({ ...el, key: el._id + index })));
    }, [userCompany]);

    const createData = (type) => {
        let dataToReturn = searchValue.map((el, index) => {
            const element = docs.find(doc => doc.car.number === el.number && doc.type._id === type)
            if (element) {
                return {
                    _id: el._id,
                    number: el.number,
                    startDate: element.startDate,
                    endDate: element.endDate,
                    remained: element.remained,
                    key: el._id + index
                }
            } else {
                return {
                    _id: el._id,
                    number: el.number,
                    startDate: '-',
                    endDate: '-',
                    remained: '-',
                    key: el._id + index
                }
            }
        })
        dataToReturn = dataToReturn.sort((a,b) => a.remained - b.remained)
        return dataToReturn;
    }

    useEffect(() => {
        getTypes();
        getDocs();
        getCars();
    }, [getTypes, getDocs, getCars]);

    const columns = [
        {
            title: 'Id',
            dataIndex: '_id',
            key: '_id',
            width: 40,
            hidden: true,
        },
        {
            title: 'Номер',
            dataIndex: 'number',
            key: 'number',
            width: 40
        },
        {
            title: 'От',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 55
        },
        {
            title: 'До',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 55
        },
        {
            title: 'Осталось',
            dataIndex: 'remained',
            key: 'remained',
            width: 20,
            render: (number, record) => (
                number <= 30 ?
                    <TextWrapper>
                        <RedBackground>{number}</RedBackground>
                    </TextWrapper> :
                    number <= 100 ?
                        <TextWrapper>
                            <YellowBackground>{number}</YellowBackground>
                        </TextWrapper>
                        : <TextWrapper>
                            <GreenBackground>{number}</GreenBackground>
                        </TextWrapper>
            )
        },
    ];

    const searchInput = (e) => {
        const search = cars.filter(el => el.number.toLowerCase().includes(e.target.value.toLowerCase()));
        setSearchValue(search);
    }


    const handleCancelEdit = () => {
        setOpenEdit(false);
    }

    return (
        <PageWrapper>
            <TabItemWrapper>
                <Input onInput={searchInput} placeholder={'Поиск'} />
            </TabItemWrapper>


            <Space wrap align={'center'}>
                {
                    types && docs && searchValue ?
                        types.map(el => (
                            <Table
                                key={el.name}
                                title={() => el.name}
                                dataSource={createData(el._id)}
                                columns={columns}
                                size="small"
                                bordered={true}
                                style={{
                                    width: '280px',
                                }}
                                onRow={(record) => {
                                    return {
                                        onDoubleClick: async () => {
                                            setOpenCar(record);
                                            setOpenEdit(true)
                                        },
                                    };
                                }}
                                rowClassName={(record, index) => index % 2 === 0 ? 'even-row-table' : 'odd-row-table'}
                                pagination={{
                                    // onChange: onChangePage,
                                    // current: currentPage,
                                    pageSize: 50,
                                    pageSizeOptions: [50, 100, 150],
                                    position: ['bottomRight']
                                }}
                            />
                        )) : null
                }

            </Space>

            <Modal
                title="Редактирование транспортного средства"
                open={openEdit}
                destroyOnClose={true}
                footer={null}
                width={500}
                style={{ top: 10 }}
                onCancel={handleCancelEdit}
                afterClose={getDocs}
            >
                <EditCarModal setOpenEdit={setOpenEdit} car={openCar} openNotification={openNotification} />
            </Modal>

        </PageWrapper>
    );
};

export default CarsDocsPage;