import React, { useCallback, useEffect, useState } from 'react';
import CarDocsService from '../../../services/CarDocsService';
import { Button, Form, Select } from 'antd';
import { useSelector } from 'react-redux';
import { TabItemWrapper } from '../../../components/StyledComponents/styled';
import DateMaskInput from '../../../components/MaskInput/DateMaskInput';
import EditCarDocsList from './EditCarDocsList';

const EditCarDocs = ({ openNotification, car }) => {

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const [types, setTypes] = useState();
    const [docs, setDocs] = useState();

    const getDocs = useCallback(async () => {
        const resDocs = await CarDocsService.getDoc({ car: car._id })
        setDocs(resDocs.data);
    }, [])


    const getDocsType = useCallback(async () => {
        const resTypes = await CarDocsService.getType({ company_id: userCompany });
        setTypes(resTypes?.data);
    }, [])

    const onFinish = async (values) => {

        const isExist = docs.find(el => el.type._id === values.type);
        if (isExist) {
            openNotification('Ошибка!', 'Данный тип документов уже существует!', 'error')
        } else {
            await CarDocsService.createOrEditDoc({
                car: car._id,
                company_id: userCompany,
                ...values,
            })

            openNotification('Успех!', 'Документ успешно добавлен!', 'info')
            await getDocs();
        }



    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    useEffect(() => {
        getDocsType();
        getDocs();
    }, [])


    return (
        <TabItemWrapper>
            {
                docs ? <EditCarDocsList docs={docs} openNotification={openNotification} getDocs={getDocs} /> : null
            }

            {
                types ? <Form
                    name="carDocs"
                    labelCol={{
                        span: 5,
                    }}
                    wrapperCol={{
                        span: 30,
                    }}
                    labelAlign='left'
                    style={{
                        width: 300,
                    }}
                    layout='horizontal'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >

                    <Form.Item
                        label="Документ"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Обязательно к выбору',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Выберите документ"
                            optionFilterProp="children"
                            options={types && types.map(el => { return { value: el._id, label: el.name } })}
                        />
                    </Form.Item>

                    <Form.Item
                        label="От"
                        name="startDate"
                        rules={[
                            {
                                required: true,
                                message: 'Обязательно к заполнению',
                            },
                        ]}
                    >
                        <DateMaskInput />
                    </Form.Item>

                    <Form.Item
                        label="До"
                        name="endDate"
                        rules={[
                            {
                                required: true,
                                message: 'Обязательно к заполнению',
                            },
                        ]}
                    >
                        <DateMaskInput />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 20,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Form.Item>
                </Form> : null
            }
        </TabItemWrapper>
    );
};

export default EditCarDocs;