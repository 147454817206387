import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Select } from 'antd';
import CompaniesService from '../../services/CompaniesService';
import { ButtonsWrapper, ButtonPrimary } from '../../styles/buttons';
import TextArea from 'antd/es/input/TextArea';

const Wrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 24px;
max-width: 100%;
`

const InnWrapper = styled.div`
    display: flex;
    justify-content: start;
    width: 300px;
`

const nds = [
    {
        label: '20%',
        value: 20
    },
    {
        label: '13%',
        value: 13
    },
    {
        label: '6%',
        value: 6
    },
    {
        label: '0%',
        value: 0
    },
]

const AddOfficial = ({ setOpen, userCompany }) => {

    const [inn, setInn] = useState();
    const [initVal, setInitVal] = useState();

    const findByInn = async (number) => {
        const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
        const token = "6d61202bda78cdeb99169219c1c628858a32c6e7";
        const query = number;

        const options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({ query: query })
        }

        return fetch(url, options)
            .then(response => response.json())
            .then(result =>
                setInitVal({
                    fullName: result.suggestions[0].data.name.full_with_opf,
                    shortName: result.suggestions[0].data.name.short_with_opf,
                    inn: result.suggestions[0].data.inn,
                    kpp: result.suggestions[0].data.kpp,
                    adress: result.suggestions[0].data.address.value,
                    ogrn: result.suggestions[0].data.ogrn,
                    okpo: result.suggestions[0].data.okpo,
                    okved: result.suggestions[0].data.okved,
                    boss: result.suggestions[0].data?.management ? result.suggestions[0].data.management.name : null,
                    bossJob: result.suggestions[0].data?.management ? result.suggestions[0].data.management.post : null,
                    accountier: result.suggestions[0].data?.management ? result.suggestions[0].data.management.name : null,
                })
            )
            .catch(error => console.log("error", error));
    }


    const onFinish = async (values) => {
        await CompaniesService.createOrEdit({ ...values, type: 'official', company_id: userCompany });
        setOpen(false);
    };

    const change = (value) => {
        setInn(value)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };



    return (
        <>

            <ButtonsWrapper>
                <InnWrapper>
                    <Input placeholder='Поиск по ИНН' onChange={(e) => change(e.target.value)} /><ButtonPrimary onClick={() => findByInn(inn)}>Поиск</ButtonPrimary>
                </InnWrapper>
            </ButtonsWrapper>

            <Wrapper>
                {
                    initVal && <Form
                        name="basic"
                        labelCol={{
                            span: 5,
                        }}
                        wrapperCol={{
                            span: 30,
                        }}
                        labelAlign='left'
                        style={{
                            width: 800,
                        }}
                        layout='horizontal'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        initialValues={{
                            ...initVal
                        }}

                    >
                        <Form.Item
                            label="Полное наименование"
                            name="fullName"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Краткое наименование"
                            name="shortName"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="ИНН"
                            name="inn"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="КПП"
                            name="kpp"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Юридический адрес"
                            name="adress"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="ОГРН"
                            name="ogrn"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="ОКПО"
                            name="okpo"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="ОКВЭД"
                            name="okved"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Банк"
                            name="bank"
                            rules={[
                                {
                                  required: true,
                                  message: 'Обязательно для заполнения',
                                },
                              ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="БИК"
                            name="bik"
                            rules={[
                                {
                                  required: true,
                                  message: 'Обязательно для заполнения',
                                },
                              ]}
                        >
                            <Input type="number"  />
                        </Form.Item>

                        <Form.Item
                            label="Р/с"
                            name="billOne"
                            rules={[
                                {
                                  required: true,
                                  message: 'Обязательно для заполнения',
                                },
                              ]}
                        >
                            <Input type="number" />
                        </Form.Item>

                        <Form.Item
                            label="К/с"
                            name="billTwo"
                            rules={[
                                {
                                  required: true,
                                  message: 'Обязательно для заполнения',
                                },
                              ]}
                        >
                            <Input type="number" />
                        </Form.Item>

                        <Form.Item
                            label="НДС"
                            name="nds"
                            rules={[
                                {
                                  required: true,
                                  message: 'Обязательно для заполнения',
                                },
                              ]}
                        >
                            <Select
                                showSearch
                                placeholder="НДС"
                                allowClear
                                optionFilterProp="children"
                                options={nds}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Начало нумерации"
                            name="startNumber"
                        >
                            <Input type='number' />
                        </Form.Item>

                        <Form.Item
                            label="Префикс"
                            name="prefix"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Руководитель"
                            name="boss"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Должность Руководителя"
                            name="bossJob"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Бухгалтер"
                            name="accountier"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Телефон"
                            name="phone"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Комментарии"
                            name="comment"
                        >
                            <TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 20,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Сохранить
                            </Button>
                        </Form.Item>
                    </Form>
                }
            </Wrapper>
        </>
    );
};

export default AddOfficial;