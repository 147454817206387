import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Select } from 'antd';
import CarService from '../../services/CarService';
import DriverService from '../../services/DriverService';
import PhoneMaskInput from '../../components/MaskInput/PhoneMaskInput';
import DateMaskInput from '../../components/MaskInput/DateMaskInput';
import PayDayMaskInput from '../../components/MaskInput/PayDayMaskInput';

const Wrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 24px;
max-width: 100%;
`

const { TextArea } = Input;

const AddNewDriver = ({ setOpen, getDrivers, userCompany, types }) => {

    const [cars, setCars] = useState();

    useEffect(() => {
        getCarsApi();
    }, [])

    const getCarsApi = async () => {
        const data = await CarService.get({ company_id: userCompany, isDeleted: false });
        setCars(data.data)
    }

    const onFinish = async (values) => {
        await DriverService.createOrEdit({ ...values, company_id: userCompany });
        setOpen(false);
        await getDrivers();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Wrapper>
            <Form
                name="basic"
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 22,
                }}
                labelAlign='left'
                style={{
                    width: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                layout='horizontal'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="ТС"
                    name="car"
                    rules={[
                        {
                            required: true,
                            message: 'Тс обязательна к заполнению',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Выбрать авто"
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={cars ? cars.map(el => { return { value: el._id, label: `${el.number} (${el.model})` } }) : null}
                    />
                </Form.Item>

                <Form.Item
                    label="Тип"
                    name="type"
                >
                    <Select
                        showSearch
                        placeholder="Выбрать авто"
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={types.map(el => ({ value: el._id, label: el.typeName }))}
                    />
                </Form.Item>

                <Form.Item
                    label="ФИО"
                    name="name"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Телефон"
                    name="phone"
                >
                    <PhoneMaskInput />
                </Form.Item>

                <Form.Item
                    label="Расчетный день"
                    name="startDayToPay"
                >
                    <PayDayMaskInput />
                </Form.Item>


                <Form.Item
                    label="Дата рождения"
                    name="birth"
                >
                    <DateMaskInput />
                </Form.Item>

                <Form.Item
                    label="Номер паспорта"
                    name="passNumber"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Дата выдачи"
                    name="passDate"
                >
                    <DateMaskInput />
                </Form.Item>
                <Form.Item
                    label="Кем выдан"
                    name="passWho"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Адрес прописки"
                    name="adress"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Номер ВУ"
                    name="driverPassNumber"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Дата выдачи ВУ"
                    name="driverPassDate"
                >
                    <Input />
                </Form.Item>


                <Form.Item
                    label="Комментарии"
                    name="comment"
                >
                    <TextArea rows={4} />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 20,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Добавить
                    </Button>
                </Form.Item>
            </Form>
        </Wrapper>
    );
};

export default AddNewDriver;