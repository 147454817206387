import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Space, Table } from 'antd';
import { ButtonPrimary } from '../../styles/buttons';
import { useSelector } from 'react-redux';

import PlanningService from '../../services/PlanningService';
import AddPlanningType from './AddPlanningType';

const PlanningSettings = ({ openNotification }) => {

    const [types, setType] = useState();
    const [open, setOpen] = useState(false);

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const getTypes = useCallback( async () => {
        const res = await PlanningService.getTypes({ company_id: userCompany });
        setType(res.data)
    }, [userCompany])

    useEffect(() => {
        getTypes();
    }, [getTypes])





    const deleteType = async (record) => {
        const res = await PlanningService.deleteType(record);
        if (res.data.message === 'success') {
            await getTypes();
            openNotification('Успешно!', 'Тип удален', 'info')
        }
    }

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Действия',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {
                        record.canDelete === true ?
                            <ButtonPrimary onClick={() =>
                                Modal.confirm({
                                    onOk: () => {
                                        deleteType(record)
                                    },
                                    title: 'Удалить',
                                    content: 'Подтвердите удаление',
                                    footer: (_, { OkBtn, CancelBtn }) => (
                                        <>
                                            <CancelBtn />
                                            <OkBtn />
                                        </>
                                    ),
                                })
                            }>Удалить</ButtonPrimary> : null
                    }

                </Space>
            ),
        },
    ]

    const handleCancel = () => {
        setOpen(false);
    }

    const addType = () => {
        setOpen(true);
    }


    return (
        <div>
            {
                types &&
                <Table columns={columns} dataSource={types.map((el, index) => {
                    return {
                        ...el,
                        key: index,
                    }
                })} />
            }

            <ButtonPrimary onClick={addType}>Добавить тип планирования</ButtonPrimary>

            <Modal
                title="Новый тип водителей"
                open={open}
                onCancel={handleCancel}
                footer={null}
                destroyOnClose={true}
                width={350}
                style={{ top: 10 }}
            >
                <AddPlanningType setOpen={setOpen} getTypes={getTypes} openNotification={openNotification} userCompany={userCompany} />
            </Modal>
        </div>
    );
};

export default PlanningSettings;