import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonPrimary } from '../../styles/buttons';
import styled from 'styled-components';
import { Input, Tabs, Space, Form, DatePicker, ConfigProvider, Button, Tooltip } from 'antd';
import Bill from './Docs/Bill';
import Invoice from './Docs/Invoice';
import Registry from './Docs/Registry';
import RegistryService from '../../services/RegistryService';
import Examples from './Docs/Examples';
import ru_RU from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import DocksService from '../../services/DocsService';
import { ButtonsWrapper } from '../../styles/buttons';
import { ReactComponent as ExcelSvg } from '../../icons/excel.svg';
import { ReactComponent as ZipSvg } from '../../icons/zip.svg';
import { DWN_URL } from '../../http';
import Spinner from '../../components/Supply/Spinner';
import moment from 'moment';

const Wrapper = styled.div`
    display: flex;
    jusitfy-content: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
`

const Union = styled.div`
`

const UnionItem = styled.div`
    display: flex;
    justify-content: space-between;
`

const RedText = styled.div`
    color: #cd3633;
    font-weight: 600;
`

const WrapperBottom = styled.div`
    display: flex;
    justify-content: space-between;
`

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`

const DocumentDataWrapper = styled.div`
    color: black;
`

const DocumentDataWrapperItem = styled.div`
    color: black;
`

const RedSpan = styled.span`
    color: #a90303
`


const DocksFiles = ({ openedRegistry, setOpenRegistry, userCompany, openNotification }) => {

    const [documents, setDocuments] = useState();
    const [currentTab, setCurrentTab] = useState();
    const [spinning, setSpinning] = useState(false);
    const [currentTabKey, setCurrentTabKey] = useState(1);

    const checkDocs = useCallback( async () => {
        const res = await DocksService.checkDocs({ _id: openedRegistry._id, company: openedRegistry.company_id });
        setDocuments(res.data);
        setCurrentTab(res.data)
    }, [openedRegistry])


    useEffect(() => {
        checkDocs();
    }, [checkDocs])



    const createDocs = async (type = tab.current) => {
        const res = await DocksService.get({ _id: openedRegistry._id, type: type, user: userCompany });
        if (res.data) {
            const newDocs = documents.map(el => {
                if (el.name === type) {
                    return el = res.data;
                } else {
                    return el;
                }
            })
            setDocuments(newDocs);
            setCurrentTab(res.data)
        }
    }

    const items = [
        {
            key: '1',
            label: 'Счет на оплату',
            name: 'bill',
            children: <Bill data={openedRegistry} setOpenRegistry={setOpenRegistry} userCompany={userCompany} documents={documents} checkDocs={checkDocs} createDocs={createDocs} />,
        },
        {
            key: '2',
            label: 'Счет фактура',
            name: 'upd',
            children: <Invoice data={openedRegistry} setOpenRegistry={setOpenRegistry} userCompany={userCompany} documents={documents} checkDocs={checkDocs} createDocs={createDocs} />,
        },
        {
            key: '3',
            label: 'Реестр выполненых работ',
            name: 'registry',
            children: <Registry data={openedRegistry} checkDocs={checkDocs} createDocs={createDocs} openNotification={openNotification} />,
        },
        {
            key: '4',
            label: 'Варианты наименования',
            name: 'other',
            children: <Examples data={openedRegistry} userCompany={userCompany} />,
        }
    ];

    const tab = useRef(items[0].name);


    const saveNumber = async (val) => {
        const res = await RegistryService.createOrEdit({
            _id: openedRegistry._id,
            numberForDocuments: val.numberForDocuments,
            date: val.date,
        })

        res.data.agent = openedRegistry.agent;
        setOpenRegistry(res.data)
    }

    const changeTab = (key) => {
        setCurrentTabKey(key)
        setCurrentTab(documents.find(el => el.name === items[key - 1].name))
        tab.current = documents.find(el => el.name === items[key - 1].name)?.name;
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const getDocs = async (type = tab.current) => {
        setSpinning(true)
        const res = await DocksService.get({ _id: openedRegistry._id, type: type, user: userCompany })
        if (res.data.url) {
            const link = document.createElement('a');
            link.download = 'DownloadingFiles';
            link.href = `${DWN_URL}/files/${res.data.url}`;
            link.click();
        }
        setSpinning(false)
    }

    return (
        <>
            <Wrapper>
                <Union>
                    <UnionItem>
                        {
                            openedRegistry &&
                            <Form
                                name="basic"
                                initialValues={{
                                    numberForDocuments: openedRegistry.numberForDocuments,
                                    date: dayjs(openedRegistry.date),
                                }}
                                onFinish={saveNumber}
                                onAbort={onFinishFailed}
                            >
                                <Space
                                    align="start"
                                >
                                    <Form.Item
                                        label="Номер документа"
                                        name="numberForDocuments"
                                    >
                                        <Input placeholder="Номер документа" disabled={openedRegistry.status === 'Paid' ? true : false} />
                                    </Form.Item>


                                    <ConfigProvider locale={ru_RU}>
                                        <Form.Item
                                            label="Дата документа"
                                            name="date"
                                        >
                                            <DatePicker format={'DD/MM/YYYY'} locale={ru_RU} disabled={openedRegistry.status === 'Paid' ? true : false} />
                                        </Form.Item>
                                    </ConfigProvider>
                                    {
                                        openedRegistry.status !== 'Paid' &&
                                        <ButtonPrimary type="primary" htmlType="submit">Сохранить</ButtonPrimary>
                                    }

                                </Space>
                            </Form>
                        }
                        <Space
                            align="start"
                        >
                            {/* <TextItems>
                            <div>Сумма: {openedRegistry.orders.reduce((a, b) => a + +b.bet, 0)} рублей.</div>
                            <div>НДС: {Math.floor(openedRegistry.orders.reduce((a, b) => a + +b.bet, 0) * 20 / (20 + 100))} рублей.</div>
                        </TextItems> */}
                        </Space>
                    </UnionItem>
                </Union>
                {
                    spinning ?
                        <Spinner spinning={spinning} /> :
                        <>
                            {
                                openedRegistry.agent.contractNumber && openedRegistry.agent.contractDate ?
                                    <Tabs defaultActiveKey={currentTabKey} items={items} destroyInactiveTabPane={true} onChange={changeTab} /> :
                                    openedRegistry?.company?.type === 'physics' ? <></> :
                                        <RedText>
                                            Внимание! У {openedRegistry.agent.shortName} не заполнены:
                                            {openedRegistry.agent.contractNumber ? null : ' "Номер договора"'}
                                            {openedRegistry.agent.contractDate ? null : ' "Дата договора"'}.
                                            Что бы продолжить работу - перейдите во вкладу "Контрагенты" и заполните отсутствующие поля.
                                        </RedText>
                            }

                            {
                                openedRegistry?.company?.type === 'physics' && <Tabs defaultActiveKey="1" items={items} destroyInactiveTabPane={true} onChange={changeTab} />
                            }

                            <WrapperBottom>
                                {
                                    documents ?
                                        <>
                                            <DocumentDataWrapper>
                                                {
                                                    documents.map((el, index) => (
                                                        <DocumentDataWrapperItem key={index}>
                                                            <b>{el.rusName}</b>
                                                            {el.exist ?
                                                                ` был сформирован ${moment(el.time).add(3, 'hours').format('DD/MM/YYYY HH:mm')}` : <RedSpan> Не был сформирован</RedSpan>
                                                            }
                                                        </DocumentDataWrapperItem>
                                                    ))
                                                }
                                            </DocumentDataWrapper>

                                            <ButtonsWrapper>
                                                {
                                                    currentTab?.exist &&
                                                    <>
                                                        <Tooltip title={currentTab.exist ? 'Скачать Excel' : 'Сохраните документ'}>
                                                            <IconButton onClick={() => getDocs()} disabled={currentTab.exist ? false : true} alt='Скачать' icon={<ExcelSvg />}></IconButton>
                                                        </Tooltip>

                                                        {
                                                            documents.find(el => el.exist === false) ?
                                                                <Tooltip title="Сформируйте все документы">
                                                                    <IconButton alt='Скачать все файлы' disabled icon={<ZipSvg />}></IconButton>
                                                                </Tooltip> :
                                                                <Tooltip title="Скачать все файлы">
                                                                    <IconButton onClick={() => getDocs('all')} alt='Скачать все файлы' icon={<ZipSvg />}></IconButton>
                                                                </Tooltip>
                                                        }
                                                    </>
                                                }
                                            </ButtonsWrapper> </> : null
                                }
                            </WrapperBottom>
                        </>
                }
            </Wrapper>
        </>
    );
};

export default DocksFiles;