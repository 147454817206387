import React from 'react';
import { IMaskInput } from 'react-imask';
import moment from 'moment';

const DateMaskInput = React.forwardRef((props, ref) => {
    const { value, onChange, ...rest } = props;
    const currentYear = moment().get('year') + 1;

    return (
        <IMaskInput
            {...rest}
            value={value}
            onAccept={(value) => {
                if (onChange) {
                    onChange({ target: { name: props.name, value } });
                }
            }}
            inputRef={ref}
            mask={Date}
            min={new Date(1900, 0, 1)}
            max={new Date(currentYear, 0, 1)}
            style={{
                'width': '100%',
                'borderWidth': '1px',
                'borderColor': 'rgb(231,231,231)',
                'borderRadius': '6px',
                'transition': 'all 0.2s',
                'padding': '4px 11px',
                'color': 'rgba(0, 0, 0, 0.88)',
                'fontSize': '14px',
            }}
        />
    );
});

export default DateMaskInput;