import $api from '../http';

export default class DaysServise {

    static async getDaysRange(data) {
        try {
            return await $api.post('/get-days-range', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async createOrEdit(data) {
        try {
            return await $api.post('/edit-days-range', data);
        } catch(e) {
            console.log(e)
        }
    }
}