import RegistryService from "../../../services/RegistryService";

import OrdersService from "../../../services/OrdersService";

export const changeStatus = async (item, status, orders) => {
    await RegistryService.createOrEdit({_id: item, status: status});
    if (status === 'Created') {
        for (let el of orders) {
            await OrdersService.changeStatus(el._id, 'Created');
        }
    } else {
        for (let el of orders) {
            await OrdersService.changeStatus(el._id, status);
        }
    }
};