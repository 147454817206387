import React, { useEffect } from 'react';
import { Form, Input, Space } from 'antd';
import PlanningService from '../../services/PlanningService';
import { ButtonPrimary } from '../../styles/buttons';

const AddPlanningType = ({ setOpen, getTypes, userCompany }) => {

    const [form] = Form.useForm();

    useEffect(() => {
    }, [])

    const onFinish = async (values) => {
        await PlanningService.createNewType({...values, company_id: userCompany});
        setOpen(false);
        await getTypes();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div>
            <Form
                name="edit"
                form={form}
                labelAlign='left'
                style={{
                    width: 300,
                }}
                layout='horizontal'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={{

                }}
            >
                <Form.Item
                    label="Название"
                    name="name"
                >
                    <Input />
                </Form.Item>
                <Space>
                    <ButtonPrimary type="primary" htmlType="submit">
                        Сохранить
                    </ButtonPrimary>
                </Space>
            </Form>
        </div>
    );
};

export default AddPlanningType;