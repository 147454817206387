import React, { useCallback, useEffect, useState } from 'react';
import RolesService from '../../services/RolesService';
import { Modal } from 'antd';
import { ButtonPrimary } from '../../styles/buttons';
import CreateRole from './CreateRole';
import { Space, Table } from 'antd';
import { mockData } from '../../components/Supply/SettingsPage';
import { useSelector } from 'react-redux';


const UsersRoles = ({ openNotification }) => {

    const [roles, setRoles] = useState([]);
    const [open, setOpen] = useState(false);
    const [initialRole, setInitialRole] = useState();

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const getRoles = useCallback(async () => {
        const res = await RolesService.get({ company_id: userCompany });
        res.data = res.data.map(item => {
            return {
                ...item,
                access: [item.access.map(el => mockData.find(data => +data.key === +el)?.title)]
            }
        })
        setRoles(res.data)
    }, [userCompany]);

    
    useEffect(() => {
        getRoles()
    }, [getRoles])
    

    const deleteRole = async (record) => {
        if (roles.length > 1) {
            await RolesService.delete({ _id: record._id })
            await getRoles()
        } else {
            openNotification('Ошибка!', 'Нельзя удалить последнюю роль!', 'error')
        }
    }

    const editRole = async (record) => {
        record.access = record.access.split(',')
        setInitialRole(record)
        setOpen(true)
    }

    const columns = [
        {
            title: 'Название',
            dataIndex: 'roleName',
            key: 'roleName',
        },
        {
            title: 'Страницы',
            dataIndex: 'access',
            key: 'access',
        },
        {
            title: 'Действия',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <ButtonPrimary onClick={() => editRole(record)}>Изменить</ButtonPrimary>
                    <ButtonPrimary onClick={() => Modal.confirm({
                        onOk: () => deleteRole(record),
                        title: 'Удалить',
                        content: 'Подтвердите удаление',
                        footer: (_, { OkBtn, CancelBtn }) => (
                            <>
                                <CancelBtn />
                                <OkBtn />
                            </>
                        ),
                    })}>Удалить</ButtonPrimary>
                </Space >
            ),
        },
    ];



    const addRole = () => {
        setOpen(true);
    }

    const handleCancel = () => {
        setOpen(false);
    }

    return (
        <div>

            {
                roles &&
                <Table columns={columns} dataSource={roles.map((el, index) => {
                    return {
                        key: index,
                        roleName: el.roleName,
                        access: el.access.join(','),
                        _id: el._id
                    }
                })} />
            }

            <ButtonPrimary onClick={addRole}>Добавить роль</ButtonPrimary>

            <Modal
                title="Новая роль"
                open={open}
                onCancel={handleCancel}
                footer={null}
                destroyOnClose={true}
                width={450}
                style={{ top: 10 }}
            >
                <CreateRole
                    setOpen={setOpen}
                    getRoles={getRoles}
                    initialRole={initialRole}
                    setInitialRole={setInitialRole}
                    userCompany={userCompany}
                    openNotification={openNotification}
                ></CreateRole>
            </Modal>
        </div>
    );
};

export default UsersRoles;