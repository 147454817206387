import React, { useEffect, useState } from 'react';
import CarsTable from './CarsTable';
import { ButtonsWrapper, ButtonPrimary } from '../../../styles/buttons';

const Cars = ({ openNotification }) => {

    useEffect(() => {
        document.title = "ТС - ГрузДок - сервис учета грузоперевозок"
     }, []);

    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    return (
        <>
            <ButtonsWrapper>
                <ButtonPrimary onClick={showModal}
                >
                    Добавить ТС
                </ButtonPrimary>
            </ButtonsWrapper>
            <CarsTable type={'current'} setOpen={setOpen} open={open} openNotification={openNotification}/>
        </>

    )
};

export default Cars;