import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { X } from 'react-bootstrap-icons';
import companiesService from '../../services/CompaniesService';
import { Modal, Space, Table, Tooltip, Button, Tabs } from 'antd';
import AddNewCompany from './AddNewCompany';
import EditCompany from './EditCompany';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`

const Wrapper = styled.div`
    padding: 0 15px;
`

const CompaniesTable = ({ setOpen, open, openNotification }) => {

    const [companies, setCompanies] = useState('');
    const [openEdit, setOpenEdit] = useState(false);
    const [openCompany, setOpenCompany] = useState('')

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);
    const userRole = useSelector((state) => state.userInfo.user.user.role.roleName);

    const getCompanies = useCallback(async () => {
        const companies = await companiesService.get({ company_id: userCompany });
        setCompanies(companies.data.map(el => ({ ...el, key: el._id })))
    }, [userCompany])

    useEffect(() => {
        getCompanies();
    }, [getCompanies])

    const handleCancel = () => {
        setOpen(false);
    }

    const handleCancelEdit = () => {
        setOpenEdit(false);
    }



    const deleteCompany = useCallback(async (data) => {
        await companiesService.delete({ _id: data });
        await getCompanies();
    }, [getCompanies])

    const columnsofficial = useMemo(
        () => [
            {
                title: 'Наименование',
                dataIndex: 'shortName',
                key: 'shortName',
                width: 40,
            },
            {
                title: 'ИНН',
                dataIndex: 'inn',
                key: 'inn',
                width: 40,
            },
            {
                title: 'КПП',
                dataIndex: 'kpp',
                key: 'kpp',
                width: 40,
            },
            {
                title: 'Адрес',
                dataIndex: 'adress',
                key: 'adress',
                width: 40,
            },
            {
                title: 'Комментарии',
                dataIndex: 'comment',
                key: 'comment',
                width: 40,
            },
            {
                title: 'Действия',
                dataIndex: 'operation',
                width: 60,
                render: (_, record) => (
                    <Space>
                        <Tooltip title="Удалить">
                            <IconButton onClick={() => {
                                Modal.confirm({
                                    onOk: () => {
                                        if (userRole === 'Администратор') {
                                            deleteCompany(record._id);
                                        } else {
                                            openNotification('Ошибка!', 'Удалять Мои Компании могут только пользователи с правами Администратор!', 'error')
                                        }
                                    },
                                    title: 'Удалить',
                                    content: 'Подтвердите удаление',
                                    footer: (_, { OkBtn, CancelBtn }) => (
                                        <>
                                            <CancelBtn />
                                            <OkBtn />
                                        </>
                                    ),
                                })
                            }} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                                icon={
                                    <X style={{ 'color': '#ef423e', 'width': '18px', 'height': '18px' }} />
                                } />
                        </Tooltip>
                    </Space>
                ),
            }
        ],
        [deleteCompany, openNotification, userRole],
    );

    const columnsPhysical = useMemo(
        () => [
            {
                title: 'Имя',
                dataIndex: 'name',
                key: 'name',
                width: 40,
            },
            {
                title: 'Фамилия',
                dataIndex: 'secondName',
                key: 'secondName',
                width: 40,
            },
            {
                title: 'Отчество',
                dataIndex: 'surName',
                key: 'surName',
                width: 40,
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                key: 'phone',
                width: 40,
            },
            {
                title: 'Комментарии',
                dataIndex: 'comment',
                key: 'comment',
                width: 40,
            },
            {
                title: 'Действия',
                dataIndex: 'operation',
                width: 60,
                render: (_, record) => (
                    <Space>
                        <Tooltip title="Удалить">
                            <IconButton onClick={() => {
                                Modal.confirm({
                                    onOk: () => {
                                        if (userRole === 'Администратор') {
                                            deleteCompany(record._id);
                                        } else {
                                            openNotification('Ошибка!', 'Удалять Мои Компании могут только пользователи с правами Администратор!', 'error')
                                        }
                                    },
                                    title: 'Удалить',
                                    content: 'Подтвердите удаление',
                                    footer: (_, { OkBtn, CancelBtn }) => (
                                        <>
                                            <CancelBtn />
                                            <OkBtn />
                                        </>
                                    ),
                                })
                            }} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                                icon={
                                    <X style={{ 'color': '#ef423e', 'width': '18px', 'height': '18px' }} />
                                } />
                        </Tooltip>
                    </Space>
                ),
            }
        ],
        [deleteCompany, userRole, openNotification],
    );

    const items = [
        {
            key: '1',
            label: 'ООО или ИП',
            children: <Table
                columns={columnsofficial}
                dataSource={companies ? companies.filter(el => el.type === 'official') : null}
                rowClassName={(record, index) => index % 2 === 0 ? 'even-row-table' : 'odd-row-table'}
                onRow={(record) => {
                    return {
                        onDoubleClick: async () => {
                            setOpenCompany(record);
                            setOpenEdit(true)
                        },
                    };
                }}
            />,
        },
        {
            key: '2',
            label: 'Физ. лицо',
            children: <Table
                columns={columnsPhysical}
                dataSource={companies ? companies.filter(el => el.type === 'physics') : null}
                rowClassName={(record, index) => index % 2 === 0 ? 'even-row-table' : 'odd-row-table'}
                onRow={(record) => {
                    return {
                        onDoubleClick: async () => {
                            setOpenCompany(record);
                            setOpenEdit(true)
                        },
                    };
                }}
            />,
        },
    ];


    return (
        <Wrapper>
            {
                companies &&
                <>
                    <Tabs
                        defaultActiveKey="1"
                        items={items}
                        destroyInactiveTabPane
                    />


                    <Modal
                        title="Новая компания"
                        open={open}
                        destroyOnClose={true}
                        onCancel={handleCancel}
                        footer={null}
                        width={800}
                        style={{ top: 10 }}
                        afterClose={getCompanies}
                    >
                        <AddNewCompany setOpen={setOpen} />
                    </Modal>

                    <Modal
                        title="Редактировать"
                        open={openEdit}
                        onCancel={handleCancelEdit}
                        destroyOnClose={true}
                        footer={null}
                        width={800}
                        style={{ top: 10 }}
                        afterClose={getCompanies}
                    >
                        <EditCompany setOpen={setOpenEdit} openCompany={openCompany} />
                    </Modal>
                </>
            }
        </Wrapper>
    );
};

export default CompaniesTable;