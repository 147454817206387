import React from 'react';
import { Tabs } from 'antd';
import AddOfficialAgent from './AddOfficialAgent';
import AddPhysicalAgent from './AddPhysicalAgent';


const AddNewAgent = ({ setOpen, userCompany }) => {


    const items = [
        {
            key: '1',
            label: 'ООО или ИП',
            children: <AddOfficialAgent setOpen={setOpen} userCompany={userCompany}/>,
        },
        {
            key: '2',
            label: 'Физ. лицо',
            children: <AddPhysicalAgent setOpen={setOpen} userCompany={userCompany}/>,
        },
    ];

    return (
        <Tabs defaultActiveKey="1" items={items} />
    );
};

export default AddNewAgent;