import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Select, Checkbox } from 'antd';
import 'dayjs/locale/ru';
import OrdersService from '../../services/OrdersService';
import TimeMaskInput from '../../components/MaskInput/TimeMaskInput';


const Wrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 24px;
max-width: 100%;
`

// const ButtonsWrapper = styled.div`
//     display: flex;
//     justify-content: start;
//     align-items: center;
//     gap: 8px;
//     color: #fff;
//     padding: 16px 0;
// `

// const AddProject = styled(Link)`
// display: flex;
// align-items: center;
// max-height: 40px;
// padding: 8px 16px;
// border: none;
// color: #fff;
// background-color: #001529;
// transition: 0.5s all;
// &:hover{
//     outline: 1px solid #001529;
//     background-color: #fff; 
//     color: #001529;
// }
// `

const { TextArea } = Input;

const SaveRouteModal = ({ saveRoute, setOpenSaveRoute, openNotification, userCompany }) => {
    // const [projects, setProject] = useState({});
    const [isFrequency, setIsFrequency] = useState(false);

    const [saveOrder] = Form.useForm();

    useEffect(() => {
        // getProjects();
    }, [saveRoute])

    // const getProjects = async () => {
        // const res = await ProjectService.get({ company_id: userCompany, company: allData.agents.find(el => el.shortName === saveRoute.agent)._id});
        // setProject(res.data);
    // }

    const onFinish = async (values) => {

        const dataForSave = {
            ...saveRoute,
            agent: saveRoute.agent ? saveRoute.agent : null,
            company: saveRoute.company ? saveRoute.company : null,
            // project: projects?._id ? projects?._id : null,
            company_id: userCompany,
            ...values,
            startTime: values.startTime ? values.startTime : '',
        }
        const res = await OrdersService.saveOrder(dataForSave);
        if (res.data.message !== 'error') {
            closeModal();
        } else {
            openNotification('Ошибка!', ' Выбранный маршрут уже сохранен!', 'error')
        }
    };

    const onChangeFrequency = (e) => {
        setIsFrequency(e.target.checked)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const closeModal = () => {
        setOpenSaveRoute(false)
    }

    return (
        <Wrapper>
            <Form
                form={saveOrder}
                name={'SaveOrder_modal'}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 22,
                }}
                labelAlign='left'
                style={{
                    width: 350,
                }}
                layout='horizontal'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={{
                    startTime: saveRoute.startTime,
                }}
            >


                {/* {
                    projects.length > 0 ?
                        <ConfigProvider renderEmpty={() => <Link to='/agents'>Добавить проект</Link>}>
                            <Form.Item
                                label="Проект"
                                name="project"
                            >

                                <Select
                                    showSearch
                                    allowClear={true}
                                    placeholder="Выберите проект"
                                    optionFilterProp="children"
                                    options={projects && projects.map(el => { return { value: el._id, label: el.name } })}
                                />


                            </Form.Item></ConfigProvider>
                        : <ButtonsWrapper>
                            <AddProject to='/agents'>Добавить проект</AddProject>
                        </ButtonsWrapper>

                } */}
                <Form.Item
                    label="Время начала"
                    name="startTime"
                    wrapperCol={{
                        span: 5,
                    }}
                >
                    <TimeMaskInput />
                </Form.Item>

                <Form.Item
                    label="Постоянный"
                    name="isFrequency"
                    onChange={onChangeFrequency}
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>

                {isFrequency && <Form.Item
                    label="Дни"
                    name="freqDays"
                >
                    <Select
                        showSearch
                        mode="multiple"
                        allowClear={true}
                        placeholder="Выберите дни"
                        optionFilterProp="children"
                        options={[
                            { value: 'Monday', label: 'Понедельник' },
                            { value: 'Tuesday', label: 'Вторник' },
                            { value: 'Wednesday', label: 'Среда' },
                            { value: 'Thursday', label: 'Четверг' },
                            { value: 'Friday', label: 'Пятница' },
                            { value: 'Saturday', label: 'Суббота' },
                            { value: 'Sunday', label: 'Воскресенье' }
                        ]}
                    />
                </Form.Item>}

                <Form.Item
                    label="Комментарии"
                    name="comment"
                >
                    <TextArea rows={4} />
                </Form.Item>


                <Form.Item
                    wrapperCol={{
                        offset: 20,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Сохранить
                    </Button>
                </Form.Item>
            </Form>
        </Wrapper>
    );
};

export default SaveRouteModal;