import { Button, Form, Input, InputNumber, Space, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react';
import { ArrowClockwise, DashCircleDotted, PlusCircleDotted } from 'react-bootstrap-icons';
import styled from 'styled-components';
import RegistryService from '../../../services/RegistryService';
import { ButtonPrimary } from '../../../styles/buttons';
// import { ReactComponent as ExcelSvg } from '../../../icons/excel.svg';
// import { ReactComponent as ZipSvg } from '../../../icons/zip.svg';
// import DocksService from '../../../services/DocsService';
// import { DWN_URL } from '../../../http';


const Wrapper = styled.div`
    margin-top: 16px;
    gap: 20px;
    display: flex;
    flex-direction: column;
`

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`

const Invoice = ({ data, setOrdersToDocks, checkDocs, createDocs }) => {

    const [RegsItems, setItems] = useState();
    // const [isSumFits, setIsSumFits] = useState(true);

    const [form] = Form.useForm();

    const createData = useCallback( (items = data) => {
        if (items.ordersToDocs.length > 0) {
            setItems({ orders: items.ordersToDocs });
        } else {
            const res = items.orders.map(el => {
                let routes = el.routes.map(item => item.filter(foo => foo.length > 0));
                routes = [].concat(...routes);
                return {
                        name: routes.join(' - '),
                        qty: 1,
                        unit: 'шт.',
                        price: +el.bet,
                        sum: el.bet * 1,
                    }
            })
            setItems({ orders: res });
        }
    }, [data])

    useEffect(() => {
        createData();
    }, [createData])

    const onFinish = async (values) => {
        await RegistryService.createOrEdit({ _id: data._id, ordersToDocs: values.ordersToDocs });
        setOrdersToDocks(data._id)
        createData();
        await createDocs();
        checkDocs();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const deleteItem = (arr) => {
        const startSum = data.orders.reduce((a, b) => a + +b.bet, 0)
        const endSum = arr.reduce((a, b) => a + +b.sum, 0)
        if (startSum !== endSum) {
            // setIsSumFits(false)
        }
    }

    const onFormLayoutChange = () => {
        const values = form.getFieldValue();
        const newval = values.ordersToDocs.map(el => ({
            ...el,
            sum: el.qty * el.price
        }))
        form.setFieldsValue({
            ordersToDocs: newval
        })
    }

    const resetValues = () => {
        const res = data.orders.map(el => ({
            name: el.route,
            qty: 1,
            unit: 'шт.',
            price: +el.bet,
            sum: el.bet * 1,
        }))

        form.setFieldsValue({
            ordersToDocs: res
        })
        setItems(res);
    }

    return (
        <Wrapper>
            <Space>
                <h5>Наименование товара (описание выполненных работ, оказанных услуг), имущественного права</h5>
                {
                    data.status !== 'Paid' &&
                    <Tooltip title="Сбросить">
                        <IconButton onClick={resetValues} alt='Сбросить' icon={<ArrowClockwise />}></IconButton>
                    </Tooltip>
                }
            </Space>

            {
                RegsItems &&
                <Form
                    form={form}
                    layout='horizontal'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    disabled={data.status === 'Paid' ? true : false}
                >

                    <Form.List name="ordersToDocs"
                        initialValue={RegsItems.orders}
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            width: '100%'
                                        }}
                                        align="start"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            style={{
                                                width: '400px',
                                            }}
                                        >
                                            <TextArea rows={1} placeholder="Наименование" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            onChange={onFormLayoutChange}
                                            name={[name, 'qty']}
                                            initialValue={1}
                                        >
                                            <Input type="number" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'unit']}
                                            initialValue={'шт.'}
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'price']}
                                            onChange={onFormLayoutChange}
                                        >
                                            <Input type="number" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'sum']}
                                        >
                                            <InputNumber disabled />
                                        </Form.Item>
                                        <DashCircleDotted onClick={() => {
                                            remove(name)
                                            const arr = RegsItems.orders;
                                            arr.splice(name, 1);
                                            deleteItem(arr)
                                        }} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusCircleDotted />}>
                                        Добавить
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Form.Item>
                        {
                            data.status !== 'Paid' &&
                            <ButtonPrimary type="primary" htmlType="submit">
                                Сформировать
                            </ButtonPrimary>
                        }
                    </Form.Item>
                </Form>
            }
        </Wrapper >
    );
};

export default Invoice;