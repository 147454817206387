export const mockData =
    [
        {
            key: '0',
            title: 'Главная',
            description: 'Главная',
        },
        {
            key: '1',
            title: 'Мои компании',
            description: 'Мои компании',
        },
        {
            key: '2',
            title: 'Бухгалтерия',
            description: 'Бухгалтерия',
        },
        {
            key: '3',
            title: 'Контрагенты',
            description: 'Контрагенты',
        },
        {
            key: '4',
            title: 'Водители',
            description: 'Водители',
        },
        {
            key: '5',
            title: 'Транспортные средства',
            description: 'Транспортные средства',
        },
        {
            key: '6',
            title: 'Заказы',
            description: 'Заказы',
        },
        {
            key: '7',
            title: 'Заработная плата',
            description: 'Заработная плата',
        },
        {
            key: '8',
            title: 'Планирование',
            description: 'Планирование',
        },
        {
            key: '19',
            title: 'Настройки',
            description: 'Настройки',
        },
    ]