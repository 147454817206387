import $api from '../http';

export default class CompaniesService {

    static async createOrEdit(data) {
        try {
            return await $api.post('/create-company', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async createOrEditMainCompany(data) {
        try {
            return await $api.post('/create-company-main', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async delete(data) {
        try {
            return await $api.post('/delete-company', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async deleteMainCompany(data) {
        try {
            return await $api.post('/delete-company-main', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async get(data) {
        try {
            return await $api.post('/get-companies', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async getMainCompany(data) {
        try {
            return await $api.post('/get-companies-main', data);
        } catch(e) {
            console.log(e)
        }
    }
}