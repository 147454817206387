import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
display: flex;
justify-content: end;
align-items: center;
gap: 8px;
color: #fff;
padding: 4px 16px;
`

export const ButtonDefaultStyle = styled.button`
display: flex;
align-items: center;
max-height: 30px;
padding: 8px 16px;
border: none;
color: #fff;
`

export const ButtonPrimary = styled(ButtonDefaultStyle)`
background-color: #001529;
transition: 0.5s all;
&:hover{
    outline: 1px solid #001529;
    background-color: #fff; 
    color: #001529;
}
`