import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Space } from 'antd';
import driversService from '../../services/DriverService';

const { TextArea } = Input;

const EditPayments = ({ paymentsInfo, currentId, onFinishHadler, setOpenEditPayment, driver, dateForFinish }) => {

    const onFinish = async (values) => {
        await driversService.editPaymentMonth({ ...values, id: currentId, type:'payments' })
        await onFinishHadler({ driver: driver._id, date: dateForFinish });
        setOpenEditPayment(false);
    };


    return (
        <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            style={{
                maxWidth: 800,
            }}
            autoComplete="off"
            initialValues={{
                items: paymentsInfo
            }}
        >
            <Form.List name="items">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Space
                                key={key}
                                style={{
                                    display: 'flex',
                                    marginBottom: 8,
                                }}
                                align="baseline"
                            >
                                <Form.Item
                                    {...restField}
                                    name={[name, 'date']}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'type']}
                                >
                                    <Select
                                        options={[
                                            { value: 'pay', label: 'Мы должны' }, { value: 'debt', label: 'Водитель должен' }
                                            ,]}
                                        style={{
                                            'width': '150px'
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'sum']}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'comment']}
                                >
                                    <TextArea />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Добавить
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Сохранить
                </Button>
            </Form.Item>
        </Form>
    );
};

export default EditPayments;