import { Space, Table } from 'antd';
import React from 'react';
import moment from 'moment';
import TooltipDelete from '../../../components/Tooltips/Tooltips';
import CarDocsService from '../../../services/CarDocsService';
import { GreenBackground, RedBackground, YellowBackground } from '../../../components/TextChanger/ColorTextBg';
import { TextWrapper } from '../../../components/TextChanger/TextWrapper';

const EditCarDocsList = ({ docs, openNotification, getDocs }) => {

    const deleteDoc = async (id) => {
        await CarDocsService.deleteDoc({ _id: id });
        await getDocs();
    }

    const columns = [
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'От',
            dataIndex: 'startDate',
            key: 'startDate',
        },
        {
            title: 'До',
            dataIndex: 'endDate',
            key: 'endDate',
        },
        {
            title: 'Осталось',
            dataIndex: 'remained',
            key: 'remained',
            render: (number, record) => (
                number < 30 ?
                    <TextWrapper>
                        <RedBackground>{number}</RedBackground>
                    </TextWrapper> :
                    number < 100 ?
                        <TextWrapper>
                            <YellowBackground>{number}</YellowBackground>
                        </TextWrapper>
                        : <TextWrapper>
                            <GreenBackground>{number}</GreenBackground>
                        </TextWrapper>
            )
        },
        {
            render: (_, record) => (
                <Space>
                    <TooltipDelete deleteFunction={deleteDoc} openNotification={openNotification} id={record._id} />
                </Space>
            ),
        }

    ];

    return (
        <Table
            columns={columns}
            dataSource={docs.map(el => {
                const startDate = moment(el.startDate, "DD.MM.YYYY")
                const endDate = moment(el.endDate, "DD.MM.YYYY")
                return {
                    ...el,
                    key: el._id,
                    type: el.type.name,
                    remained: endDate.diff(startDate, 'days'),
                }
            }

            )}
            style={{
                fontSize: '26px',
            }}
            rowClassName={(record, index) => index % 2 === 0 ? 'even-row-table' : 'odd-row-table'}
        />
    );
};

export default EditCarDocsList;