import $api from '../http';

export default class OrdersService {

    static async createOrEdit(data) {
        try {
            return await $api.post('/create-order', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async delete(data) {
        try {
            return await $api.post('/delete-order', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async get(data) {
        try {
            return await $api.post('/get-orders', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async getOrder(data) {
        try {
            return await $api.post('/get-order', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async changeDocsStatus(data, status) {
        try {
            return await $api.post('/change-order-docs-status', {data: data, status: status});
        } catch(e) {
            console.log(e)
        }
    }

    static async changeStatus(data, status) {
        try {
            return await $api.post('/change-order-status', {data: data, status: status});
        } catch(e) {
            console.log(e)
        }
    }

    static async getSavedOrders(data) {
        try {
            return await $api.post('/get-saved-orders', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async deleteSavedOrder(data) {
        try {
            return await $api.post('/delete-saved-orders', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async saveOrder(data) {
        try {
            return await $api.post('/save-order', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async editSaveOrder(data) {
        try {
            return await $api.post('/edit-save-order', data);
        } catch(e) {
            console.log(e)
        }
    }

    
}