import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowClockwise, X } from 'react-bootstrap-icons';
import carsService from '../../../services/CarService';
import { Modal, Space, Table, Tooltip, Button, notification } from 'antd';
import AddNewCar from './AddNewCar';
import EditCarModal from '../EditCar/EdiCarModal';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`


const CarsTable = ({ setOpen, open, openNotification, type }) => {

    const [cars, setCars] = useState('');
    const [openEdit, setOpenEdit] = useState(false);
    const [openCar, setOpenCar] = useState('')
    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const getCars =  useCallback(async () => {
        if (type === 'current') {
            const cars = await carsService.get({ company_id: userCompany, isDeleted: false });
            setCars(cars.data.map(el => ({ ...el, key: el._id })))
        }

        if (type === 'sell') {
            const cars = await carsService.get({ company_id: userCompany, isDeleted: true });
            setCars(cars.data.map(el => ({ ...el, key: el._id })))
        }
    }, [type, userCompany])

    useEffect(() => {
        getCars();
    }, [getCars])

    const handleCancel = () => {
        setOpen(false);
    }

    const handleCancelEdit = () => {
        setOpenEdit(false);
    }



    const deleteCar = async (data) => {
        await carsService.delete({ _id: data });
        await getCars();
    }

    const recoverCar = async (data) => {
        await carsService.recover({ _id: data });
        await getCars();
    }

    function removeDuplicates(array, type) {
        if (type) {
            return array.filter((obj, index, self) =>
                obj.text && index === self.findIndex((t) => (
                    t.text === obj.text
                ))
            );
        }
        return array.filter((obj, index, self) =>
            obj.value && index === self.findIndex((t) => (
                t.value === obj.value
            ))
        );
    }

    const columns = useMemo(
        () => [
            {
                title: 'Гос номер',
                dataIndex: 'number',
                key: 'number',
                filterSearch: true,
                filters: cars ? removeDuplicates(cars.map(el => ({ text: el.number, value: el._id }))) : null,
                onFilter: (value, record) => record._id.startsWith(value),
                width: 40,
            },
            {
                title: 'Марка/Модель',
                dataIndex: 'model',
                key: 'model',
                width: 40,
            },
            {
                title: 'Объем',
                dataIndex: 'volume',
                key: 'volume',
                filterSearch: true,
                filters: cars ? removeDuplicates(cars.map(el => ({ text: el.volume, value: el._id })), 'type') : null,
                onFilter: (value, record) => record._id.startsWith(value),
                width: 40,
            },
            {
                title: 'Вес',
                dataIndex: 'weight',
                key: 'weight',
                filterSearch: true,
                filters: cars ? removeDuplicates(cars.map(el => ({ text: el.weight, value: el._id })), 'type') : null,
                onFilter: (value, record) => cars._id.startsWith(value),
                width: 40,
            },
            {
                title: 'Ставка',
                dataIndex: 'driver_bet',
                key: 'driver_bet',
                width: 40,
            },
            {
                title: 'Комментарии',
                dataIndex: 'comment',
                key: 'comment',
                width: 40,
            },
            {
                title: 'Действия',
                dataIndex: 'operation',
                width: 60,
                render: (_, record) => {
                    const message = {
                        title: type === 'current' ? 'Удалить' : type === 'sell' ? 'Восстановить' : null,
                        notification: type === 'current' ? 'ТС было удалено!' : type === 'sell' ? 'ТС было восстановлено!' : null,
                        content: type === 'current' ? 'удаление' : type === 'sell' ? 'восстановление' : null,
                        icon:  type === 'current' ? <X style={{ 'color': '#ef423e', 'width': '18px', 'height': '18px' }} /> : type === 'sell' ? <ArrowClockwise style={{ 'color': '#38761d', 'width': '18px', 'height': '18px' }} /> : null,
                        function: type === 'current' ? (data) => deleteCar(data) : type === 'sell' ? (data) => recoverCar(data) : null,
                    } 
                    return (<Space>
                        <Tooltip title={message.title}>
                            <IconButton onClick={() => {
                                Modal.confirm({
                                    onOk: () => {
                                        message.function(record._id);
                                        openNotification('Успешно!', message.notification, 'info')
                                    },
                                    title: message.title,
                                    content: `Подтвердите ${message.content}`,
                                    footer: (_, { OkBtn, CancelBtn }) => (
                                        <>
                                            <CancelBtn />
                                            <OkBtn />
                                        </>
                                    ),
                                })
                            }} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                                icon={
                                    message.icon
                                } />
                        </Tooltip>
                    </Space>
                    )
                },
            }
        ],
        [],
    );

    return (
        <>
            {
                cars ?
                    <>
                        <Table
                            columns={columns}
                            dataSource={cars}
                            rowClassName={(record, index) => index % 2 === 0 ? 'even-row-table' : 'odd-row-table'}
                            onRow={(record) => {
                                return {
                                    onDoubleClick: async () => {
                                        setOpenCar(record);
                                        setOpenEdit(true)
                                    },
                                };
                            }}
                        />

                        <Modal
                            title="Добавление нового транспортного средства"
                            open={open}
                            destroyOnClose={true}
                            footer={null}
                            width={500}
                            style={{ top: 10 }}
                            onCancel={handleCancel}
                            afterClose={getCars}
                        >
                            <AddNewCar setOpen={setOpen} userCompany={userCompany} />
                        </Modal>

                        <Modal
                            title="Редактирование транспортного средства"
                            open={openEdit}
                            destroyOnClose={true}
                            footer={null}
                            width={500}
                            style={{ top: 10 }}
                            onCancel={handleCancelEdit}
                            afterClose={getCars}
                        >
                            <EditCarModal setOpenEdit={setOpenEdit} car={openCar} />
                        </Modal>
                    </>
                    : <></>}
        </>

    )
};

export default CarsTable;