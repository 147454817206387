import $api from '../http';

export default class CarService {

    static async createOrEdit(data) {
        try {
            return await $api.post('/create-cars', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async delete(data) {
        try {

            return await $api.post('/delete-car', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async recover(data) {
        try {

            return await $api.post('/recover-car', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async get(data) {
        try {
            return await $api.post('/get-cars', data);
        } catch(e) {
            console.log(e)
        }
    }
}