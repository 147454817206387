import React, { useCallback, useEffect, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import PlanningService from '../../services/PlanningService';
import { ButtonPrimary } from '../../styles/buttons';

const Wrapper = styled.div`
    margin: 16px 0;
    padding: 0 16px;
`

const PlanningText = ({ openNotification, userCompany, type }) => {
    const [text, setText] = useState();

    const getText = useCallback( async () => {
        let text = await PlanningService.get({ company_id: userCompany, type: type });
        setText(text.data);
    }, [type, userCompany])

    useEffect(() => {
        getText();
    }, [getText])




    const saveText = async () => {
        if (text._id) {
            await PlanningService.createOrEdit({ ...text });
        } else {
            await PlanningService.createOrEdit({ text: text.text, company_id: userCompany, type: type._id });
        }
        
    }


    return (
        <>
            <Wrapper>

                <TextArea rows={'15'} value={text?.text ? text.text : null} onChange={(e) => setText({...text, text: e.target.value})} />
            </Wrapper>
            <ButtonPrimary onClick={() => saveText()}>Сохранить</ButtonPrimary>
        </>

    );
};

export default PlanningText;