import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Select } from 'antd';
import agentsService from '../../services/AgentsService';
import TextArea from 'antd/es/input/TextArea';
import projectService from '../../services/ProjectService';

const Wrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 24px;
max-width: 100%;
`

const Project = ({ setOpenNewProject, userCompany }) => {

    const [agents, setAgents] = useState('');

    useEffect(() => {
        getAgents();
    }, [])

    const getAgents = async () => {
        const agents = await agentsService.get({ company_id: userCompany });
        const res = agents.data.filter(el => el.isProject === true);
        setAgents(res);
    }


    const onFinish = async (values) => {
        await projectService.createOrEdit(values);
        setOpenNewProject(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <Wrapper>
            {
                <Form
                    name="projects"
                    labelCol={{
                        span: 5,
                    }}
                    wrapperCol={{
                        span: 30,
                    }}
                    labelAlign='left'
                    style={{
                        width: 800,
                    }}
                    layout='horizontal'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Название"
                        name="name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Компания"
                        name="company"
                    >
                        <Select
                            showSearch
                            placeholder="Выберите компанию"
                            optionFilterProp="children"
                            options={agents && agents.map(el => {return {value: el._id, label: el.shortName}})}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Менеджеры"
                        name="managers"
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item
                        label="Комментарии"
                        name="comment"
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 20,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </Form.Item>
                </Form>
            }
        </Wrapper>
    );
};

export default Project;