import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Select, DatePicker, ConfigProvider, Checkbox, InputNumber, Skeleton, Space } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ru';
import OrdersService from '../../services/OrdersService';
import CompaniesService from '../../services/CompaniesService';
import projectService from '../../services/ProjectService';
import driverService from '../../services/DriverService';
import carsService from '../../services/CarService';
import agentsService from '../../services/AgentsService';
import { useSelector } from 'react-redux';
import { DashCircleDotted, PlusCircleDotted } from 'react-bootstrap-icons';
import TimeMaskInput from '../../components/MaskInput/TimeMaskInput';


dayjs.extend(updateLocale);
dayjs.locale('ru');
dayjs.updateLocale('ru', {
    weekStart: 1
});

const Wrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 24px;
max-width: 100%;
`

const { TextArea } = Input;

const AddNewOrder = ({ setOpen, gettingOrders, orderToEdit = false, isFromRegistry = false, getOrders, openNotification }) => {
    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);
    // const [projects, setProjects] = useState('');
    const allData = useRef({});
    const [isSave, setIsSave] = useState(false);
    const [isFrequency, setIsFrequency] = useState(false);
    const [formCreate] = Form.useForm();
    const [formEdit] = Form.useForm();
    const [allDataLoaded, setAllDataLoaded] = useState(false);

    const getData = useCallback( async () => {
        const companies = await CompaniesService.get({ company_id: userCompany });
        allData.company = companies.data;

        const agents = await agentsService.get({ company_id: userCompany });
        allData.agents = agents.data;

        const cars = await carsService.get({ company_id: userCompany })
        allData.cars = cars.data

        const res = await projectService.get({ company_id: userCompany });
        allData.projects = res.data

        const drivers = await driverService.get({ company_id: userCompany });
        allData.drivers = drivers.data

        setAllDataLoaded(true);
    }, [userCompany])

    useEffect(() => {
        getData()
    }, [getData])

    if (orderToEdit && allDataLoaded) {
        formEdit.setFieldsValue({
            ...orderToEdit,
            driver: orderToEdit.driver?._id ? allData.drivers.find(el => el._id === orderToEdit.driver._id)._id : null,
            company: orderToEdit.company?._id ? allData.company.find(el => el._id === orderToEdit.company._id)._id : null,
            agent: orderToEdit.agent?._id ? allData.agents.find(el => el._id === orderToEdit.agent._id)._id : null,
            number: orderToEdit.number?._id ? allData.cars.find(el => el._id === orderToEdit.number._id)._id : null,
            date: orderToEdit.date ? dayjs(orderToEdit.date) : null,
            routes: orderToEdit.routes.map(el => ({ a: el[0], b: el[1] }))
        })
    }

    const onFinish = async (values) => {
        values.routes = values.routes.map(el => Object.values(el))
        if (values.isSave) {
            await OrdersService.saveOrder({ ...values, company_id: userCompany });
        }
        if (values?.company?.value) {
            await OrdersService.createOrEdit({ ...values, company: values.company.value, company_id: userCompany, });
        } else {
            await OrdersService.createOrEdit({ ...values, company_id: userCompany });
        }

        if (!isFromRegistry) {
            await gettingOrders(true)
        } else {
            await getOrders(true);
        }
        setOpen(false)
    };

    const setNewDriver = (value) => {
        const fieldDriver = allData.drivers.find(el => el._id === value);
        const fieldCar = allData.cars.find(el => el.number === fieldDriver.car.number)
        if (orderToEdit) {
            formEdit.setFieldsValue({
                number: fieldCar._id,
                driver_bet: fieldCar.driver_bet
            })
        } else {
            formCreate.setFieldsValue({
                number: fieldCar._id,
                driver_bet: fieldCar.driver_bet
            })
        }

    }

    const agentHandle = async (value) => {
        const fieldAgent = allData.agents.find(el => el._id === value);
        const searchCompany = allData.company.find(el => el._id === fieldAgent.company);
        // const projects = await ProjectService.get({ company_id: userCompany });
        formCreate.setFieldsValue({
            company: { value: searchCompany._id, label: searchCompany.shortName }
        })
    }

    const onFinishFailed = (errorInfo) => {
        openNotification('Ошибка!', errorInfo.errorFields[0].errors[0], 'error')
    };

    const onChangeSave = (e) => {
        setIsSave(e.target.checked)
    }

    const onChange = (e) => {
        setIsFrequency(e.target.checked)
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return (
        <Wrapper>
            {
                allDataLoaded ?
                    <Form
                        form={orderToEdit ? formEdit : formCreate}
                        name="CreateForm"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 22,
                        }}
                        labelAlign='left'
                        style={{
                            width: 750,
                        }}
                        layout='horizontal'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <ConfigProvider locale={ru_RU}>
                            <Form.Item
                                label="Дата"
                                name="date"
                            >
                                <DatePicker format='DD-MM-YYYY' locale={ru_RU} />
                            </Form.Item>
                        </ConfigProvider>

                        <Form.Item
                            label="Время начала"
                            name="startTime"
                            wrapperCol={{
                                span: 5,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Обязательно к заполнению',
                                },
                            ]}
                        >
                            <TimeMaskInput />
                        </Form.Item>
                        <Form.Item
                            label="Загрузка"
                            name="agent"
                            rules={[
                                {
                                    required: true,
                                    message: 'Загрузка обязательно к заполнению',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Выбрать контрагента"
                                optionFilterProp="children"
                                onChange={agentHandle}
                                allowClear={orderToEdit ? false : true}
                                disabled={isFromRegistry ? true : orderToEdit ? orderToEdit.status === 'Created' : false}
                                filterOption={filterOption}
                                options={allData.agents && allData.agents.map(el => ({ value: el._id, label: el.type === 'official' ? el.shortName : `${el.name} ${el.secondName} ${el.surName}` }))}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Водитель"
                            name="driver"
                        >
                            <Select
                                showSearch
                                placeholder="Выбрать водителя"
                                optionFilterProp="children"
                                allowClear={orderToEdit ? false : true}
                                onChange={setNewDriver}
                                filterOption={filterOption}
                                options={allData.drivers && allData.drivers.map(el => { return { value: el._id, label: el.name } })}
                            />
                        </Form.Item>

                        <Form.Item
                            label="ГОС"
                            name="number"
                        >
                            <Select
                                showSearch
                                placeholder="Гос номер"
                                allowClear={orderToEdit ? false : true}
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={allData.cars && allData.cars.map(el => { return { value: el._id, label: el.number } })}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Контрагент"
                            name="company"
                        >
                            <Select
                                showSearch
                                placeholder="Контрагент"
                                allowClear
                                optionFilterProp="children"
                                disabled={isFromRegistry ? true : orderToEdit ? orderToEdit.status === 'Created' : false}
                                options={allData.company && allData.company.map(el => ({ value: el._id, label: el.shortName }))}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Ставка"
                            name="bet"
                        >
                            <Input type='number' allowClear />
                        </Form.Item>

                        <Form.Item
                            label="т/м3/п"
                            name="weight"
                        >
                            <Input type='number' allowClear />
                        </Form.Item>

                        <Space
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between'
                            }}
                            align="start">
                            Маршрут:
                            <Form.List name="routes"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Маршрут обязателен к заполнению!',
                                    },
                                ]}
                            >

                                {(fields, { add, remove }) => (

                                    <>
                                        {fields.map(({ key, name, ...restField }, index) => (
                                            <Space
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    width: '100%'
                                                }}
                                                align="start"
                                            >

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'a']}
                                                    style={{
                                                        width: '300px',
                                                    }}
                                                    initialValue=''
                                                >
                                                    <TextArea placeholder={`Загрузка ${index + 1}`} />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'b']}
                                                    style={{
                                                        width: '300px',
                                                    }}
                                                    initialValue=''
                                                >
                                                    <TextArea placeholder={`Выгрузка ${index + 1}`} />
                                                </Form.Item>
                                                <DashCircleDotted onClick={() => {
                                                    remove(name)
                                                }} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button style={{ 'width': '600px' }} type="dashed" onClick={() => add()} block icon={<PlusCircleDotted />}>
                                                Добавить
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Space>
                        <Form.Item
                            label="Кругорейс"
                            name="isRound"
                            valuePropName="checked"
                        >
                            <Checkbox />
                        </Form.Item>
                        <Form.Item
                            label="Часы"
                            name="time"
                        >
                            <Input allowClear />
                        </Form.Item>

                        {
                            isFromRegistry &&
                            <Form.Item
                                label="Сохранить"
                                name="isSave"
                                onChange={onChangeSave}
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>

                        }


                        {
                            isSave &&
                            <Form.Item
                                label="Периодичный ?"
                                name="isFrequency"
                                onChange={onChange}
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        }

                        {
                            isFrequency && <Form.Item
                                label="Дни"
                                name="freqDays"
                            >
                                <Select
                                    showSearch
                                    mode="multiple"
                                    allowClear
                                    placeholder="Выберите дни"
                                    optionFilterProp="children"
                                    options={[
                                        { value: 'Monday', label: 'Понедельник' },
                                        { value: 'Tuesday', label: 'Вторник' },
                                        { value: 'Wednesday', label: 'Среда' },
                                        { value: 'Thursday', label: 'Четверг' },
                                        { value: 'Friday', label: 'Пятница' },
                                        { value: 'Saturday', label: 'Суббота' },
                                        { value: 'Sunday', label: 'Воскресенье' }
                                    ]}
                                />
                            </Form.Item>
                        }

                        <Form.Item
                            label="Оплата водителя"
                            name="driver_bet"
                        >
                            <InputNumber />
                        </Form.Item>

                        <Form.Item
                            label="Комментарии"
                            name="comment"
                        >
                            <TextArea rows={4} allowClear />
                        </Form.Item>

                        <Form.Item
                            label="ID"
                            name="_id"
                            hidden
                        >
                            <TextArea rows={4} allowClear />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 20,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                {orderToEdit ? 'Сохранить' : 'Добавить'}
                            </Button>
                        </Form.Item>
                    </Form> : <Skeleton />

            }

        </Wrapper>
    );
};

export default AddNewOrder;