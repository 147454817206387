import $api from '../http';

export default class RolesService {

    static async get(data) {
        try {
            return await $api.post('/get-roles', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async create(data) {
        try {
            return await $api.post('/create-roles', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async delete(data) {
        try {
            return await $api.post('/delete-roles', data);
        } catch(e) {
            console.log(e)
        }
    }
}