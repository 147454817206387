import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import { JournalBookmark, Building, FileEarmarkPerson, Truck, Compass, Coin, People, HouseExclamation, Gear, BalloonHeart, Airplane, BucketFill } from 'react-bootstrap-icons';

import { Menu } from 'antd';


const items =
    [
        {
            key: '0',
            icon: <HouseExclamation />,
            label: <Link to='/'>Главная</Link>,
        },
        {
            key: '1',
            icon: <Building />,
            label: <Link to='/companies'>Мои компании</Link>,
        },
        {
            key: '2',
            icon: <JournalBookmark />,
            label: <Link to='/registry'>Бухгалтерия</Link>,
        },
        {
            key: '3',
            icon: <People />,
            label: <Link to='/agents'>Контрагенты</Link>,
        },
        {
            key: '4',
            icon: <FileEarmarkPerson />,
            label: <Link to='/drivers'>Водители</Link>,
        },
        {
            key: '5',
            icon: <Truck />,
            label: <Link to='/cars'>Транспортные средства</Link>,
        },
        {
            key: '6',
            icon: <Compass />,
            label: <Link to='/orders'>Заказы</Link>,
        },
        {
            key: '7',
            icon: <Coin />,
            label: <Link to='/payments'>Заработная плата</Link>,
        },
        {
            key: '8',
            icon: <Airplane />,
            label: <Link to='/planning'>Планирование</Link>,
        },
        {
            key: '12',
            icon: <BucketFill />,
            label: <Link to='/bucket'>Корзина</Link>,
        },
        {
            key: '19',
            icon: <Gear />,
            label: <Link to='/settings'>Настройки</Link>,
        },
        {
          key: 20,
          icon: <BalloonHeart/>,
          label: <Link to='/main-a-page'>Мейн</Link>,
        }
    ]



const MenuButton = ({user}) => {
    const menuItems = useRef([])

    const [sideBarMenuKey, setSideBarMenuKey] = useState();

    const getCurrentUrl = () => {
        const currentPage = items.find(el => el.label.props.to === window.location.pathname)
        setSideBarMenuKey(currentPage.key.toString())
    }

    const getMenuItems = () => {
        if (user.id)
        {
            menuItems.current = items.filter(value => user.role.access.includes(+value.key))
        }
    }

    useEffect(() => {
        getCurrentUrl()
        getMenuItems()
    }, [])



    return (
        <>
            {sideBarMenuKey && user.id && <Menu theme="dark" defaultSelectedKeys={[sideBarMenuKey]} mode="inline" items={menuItems.current} />}
        </>
    );
};

export default MenuButton;