import React from 'react';
import { Tabs } from 'antd';
import { PageWrapper } from '../../components/StyledComponents/styled';
import Cars from './CarsList/Cars';
import CarsDocsPage from './CarsDocsList/CarsDocsPage';
import CarsSell from './CarsSell/CarsSell';



const CarsPage = ({ openNotification }) => {

    const items = [
        {
            key: '1',
            label: 'Транспортные средства',
            children: <Cars openNotification={openNotification} />
        },
        {
            key: '2',
            label: 'Документы',
            children: <CarsDocsPage openNotification={openNotification} />,
        },
        {
            key: '3',
            label: 'Проданные/удаленные ТС',
            children: <CarsSell openNotification={openNotification} />
        },
    ];

    return (
        <PageWrapper>
            <Tabs defaultActiveKey="1" items={items} destroyInactiveTabPane={true} />
        </PageWrapper>
    );

};

export default CarsPage;