import styled from 'styled-components';
import { ButtonsWrapper } from '../../../styles/buttons';
import { Button } from 'antd';

export const ButtonsFlex = styled(ButtonsWrapper)`
    display: flex;
    justify-content: space-between;
    background-color: #001529;
    padding: 16px 0;
`

export const InvoicePaid = styled.div`
    background-color: #3a9350;
    text-align: center;
    color: #fff;
`

export const InvoiceIssued = styled.div`
    background-color: #2a4ea6;
    text-align: center;
    color: #fff;
`

export const ReadyForInvoicing = styled.div`
    background-color: #693e83;
    text-align: center;
    color: #fff;
`

export const Created = styled.div`
    background-color: #fff;
    text-align: center;
`

export const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`

export const DocsOffice = styled.div`
    display: flex;
    justify-content: center;
    background-color: #8b00ff;
    border-radius: 100%;
    width: 15px;
    height: 15px
`

export const Attention = styled.div`
    display: flex;
    justify-content: center;
    background-color: #ffda4d;
    border-radius: 100%;
    width: 15px;
    height: 15px
`

export const Submited = styled.div`
    display: flex;
    justify-content: center;
    background-color: #9cb996;
    border-radius: 100%;
    width: 15px;
    height: 15px
`