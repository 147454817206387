import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Select, Checkbox } from 'antd';
import AgentsService from '../../services/AgentsService';
import TextArea from 'antd/es/input/TextArea';
import companiesService from '../../services/CompaniesService';
import PhoneMaskInput from '../../components/MaskInput/PhoneMaskInput';
import DateMaskInput from '../../components/MaskInput/DateMaskInput';

const Wrapper = styled.div`
display: flex;
justify-content: center;
margin-top: 24px;
max-width: 100%;
`


const EditCompany = ({ setOpen, openAgent, userCompany }) => {

    const [companies, setCompanies] = useState();

    useEffect(() => {
        getCompanies();
    }, [])

    const getCompanies = async () => {
        const res = await companiesService.get({ company_id: userCompany });
        setCompanies(res.data)
    }

    const onFinish = async (values) => {
        if (openAgent.type === 'official') {
            await AgentsService.createOrEdit(values);
        } else {
            await AgentsService.createOrEdit({ ...values, shortName: `${values.name} ${values.secondName} ${values.surName}` });
        }

        setOpen(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Wrapper>
                {
                    openAgent.type === 'official' && companies ?
                        <Form
                            name="edit"
                            labelCol={{
                                span: 5,
                            }}
                            wrapperCol={{
                                span: 30,
                            }}
                            labelAlign='left'
                            style={{
                                width: 800,
                            }}
                            layout='horizontal'
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            initialValues={{
                                ...openAgent,
                                company: companies ? openAgent.company : null
                            }}

                        >
                            <Form.Item
                                label="Полное наименование"
                                name="fullName"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Краткое наименование"
                                name="shortName"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="ИНН"
                                name="inn"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="КПП"
                                name="kpp"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Юридический адрес"
                                name="adress"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="ОГРН"
                                name="ogrn"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="ОКПО"
                                name="okpo"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="ОКВЭД"
                                name="okved"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Руководитель"
                                name="boss"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Должность Руководителя"
                                name="bossJob"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Бухгалтер"
                                name="accountier"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Вид оплаты"
                                name="company"
                            >
                                <Select
                                    showSearch
                                    placeholder="Вид оплаты"
                                    optionFilterProp="children"
                                    allowClear
                                    options={companies ? companies.map(el => ({ value: el._id, label: el.shortName })) : null}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Договор №1"
                                name="contractNumber"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Дата договора №1"
                                name="contractDate"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Доп. вид оплаты"
                                name="companyTypeTwo"
                            >
                                <Select
                                    showSearch
                                    placeholder="Вид оплаты"
                                    optionFilterProp="children"
                                    allowClear
                                    options={companies ? companies.map(el => ({ value: el._id, label: el.shortName })) : null}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Договор №2"
                                name="contractNumberTwo"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Дата договора №2"
                                name="contractDateTwo"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Телефон"
                                name="phone"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Есть проекты"
                                name="isProject"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>

                            <Form.Item
                                label="Комментарии"
                                name="comment"
                            >
                                <TextArea rows={4} />
                            </Form.Item>

                            <Form.Item
                                label="ID"
                                name="_id"
                                hidden
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{
                                    offset: 20,
                                    span: 16,
                                }}
                            >
                                <Button type="primary" htmlType="submit">
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Form> :
                        openAgent.type === 'physics' && companies ?
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 4,
                                }}
                                wrapperCol={{
                                    span: 30,
                                }}
                                labelAlign='left'
                                style={{
                                    width: 700,
                                    marginTop: '30px'
                                }}
                                layout='horizontal'
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                initialValues={{
                                    ...openAgent,
                                    company: companies ? openAgent.company : null
                                }}
                            >
                                <Form.Item
                                    label="Имя"
                                    name="name"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Фамилия"
                                    name="secondName"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Отчество"
                                    name="surName"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Телефон"
                                    name="phone"
                                >
                                    <PhoneMaskInput />
                                </Form.Item>

                                <Form.Item
                                    label="Дата рождения"
                                    name="birth"
                                >
                                    <DateMaskInput />
                                </Form.Item>

                                <Form.Item
                                    label="Номер паспорта"
                                    name="passNumber"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Дата выдачи"
                                    name="passDate"
                                >
                                    <DateMaskInput />
                                </Form.Item>
                                <Form.Item
                                    label="Кем выдан"
                                    name="passWho"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Прописка"
                                    name="adress"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Вид оплаты"
                                    name="company"
                                >
                                    <Select
                                        showSearch
                                        placeholder="Вид оплаты"
                                        optionFilterProp="children"
                                        options={companies ? companies.map(el => ({ value: el._id, label: el.shortName })) : null}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Комментарий"
                                    name="comment"
                                >
                                    <TextArea />
                                </Form.Item>
                                <Form.Item
                                    label="ID"
                                    name="_id"
                                    hidden
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 20,
                                        span: 16,
                                    }}
                                >
                                    <Button type="primary" htmlType="submit">
                                        Сохранить
                                    </Button>
                                </Form.Item>
                            </Form> : null
                }

            </Wrapper>
        </>
    );
};

export default EditCompany;