import $api from '../http';

export default class CarDocsService {

    static async createOrEditType(data) {
        try {
            return await $api.post('/add-car-docs-type', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async deleteType(data) {
        try {

            return await $api.post('/delete-car-docs-type', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async getType(data) {
        try {
            return await $api.post('/get-car-docs-type', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async createOrEditDoc(data) {
        try {
            return await $api.post('/add-car-docs', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async deleteDoc(data) {
        try {

            return await $api.post('/delete-car-docs', data);
        } catch(e) {
            console.log(e)
        }
    }

    static async getDoc(data) {
        try {
            return await $api.post('/get-car-docs', data);
        } catch(e) {
            console.log(e)
        }
    }
}