import { Button, Form, Input } from 'antd';
import React from 'react';
import CompaniesService from '../../services/CompaniesService';
import TextArea from 'antd/lib/input/TextArea';
import PhoneMaskInput from '../../components/MaskInput/PhoneMaskInput';
import DateMaskInput from '../../components/MaskInput/DateMaskInput';


const AddPhysical = ({ setOpen, userCompany }) => {

    const onFinish = async (values) => {
        await CompaniesService.createOrEdit({ ...values, type: 'physics', shortName: `${values.name} ${values.secondName} ${values.surName}`, company_id: userCompany });
        setOpen(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div>
            <Form
                name="basic"
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 30,
                }}
                labelAlign='left'
                style={{
                    width: 700,
                    marginTop: '30px'
                }}
                layout='horizontal'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Имя"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Обязательно для заполнения',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Фамилия"
                    name="secondName"
                    rules={[
                        {
                            required: true,
                            message: 'Обязательно для заполнения',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Отчество"
                    name="surName"
                    rules={[
                        {
                            required: true,
                            message: 'Обязательно для заполнения, если отустствует то поставьте пробел',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Телефон"
                    name="phone"
                >
                    <PhoneMaskInput/>
                </Form.Item>

                <Form.Item
                    label="Дата рождения"
                    name="birth"
                >
                    <DateMaskInput />
                </Form.Item>

                <Form.Item
                    label="Номер паспорта"
                    name="passNumber"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Дата выдачи"
                    name="passDate"
                >
                    <DateMaskInput />
                </Form.Item>
                <Form.Item
                    label="Кем выдан"
                    name="passWho"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Прописка"
                    name="adress"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Банк"
                    name="bank"
                    rules={[
                        {
                            required: true,
                            message: 'Обязательно для заполнения',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="БИК"
                    name="bik"
                    rules={[
                        {
                            required: true,
                            message: 'Обязательно для заполнения',
                        },
                    ]}
                >
                    <Input type="number" />
                </Form.Item>

                <Form.Item
                    label="Р/с"
                    name="billOne"
                    rules={[
                        {
                            required: true,
                            message: 'Обязательно для заполнения',
                        },
                    ]}
                >
                    <Input type="number" />
                </Form.Item>

                <Form.Item
                    label="К/с"
                    name="billTwo"
                    rules={[
                        {
                            required: true,
                            message: 'Обязательно для заполнения',
                        },
                    ]}
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item
                    label="Комментарий"
                    name="comment"
                >
                    <TextArea />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 20,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Сохранить
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddPhysical;