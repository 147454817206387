import { useEffect, useMemo, useState } from 'react';
import { Modal, Table, ConfigProvider, Tooltip, Button, Space } from 'antd';
import { X, Folder2 } from 'react-bootstrap-icons';
import ru_RU from 'antd/lib/locale/ru_RU';
import RegistryItemsModal from './RegistryItemsModal';
import styled from 'styled-components';
import moment from 'moment';

const TextPlace = styled.div`
    max-width: 150px;
    max-height: 60px;
    overflow: auto;
`
const StatusWrapper = styled.div`
    display: flex;
    justify-content: center;
    text-align: middle;
    align-items: center;
    font-weight: bold;
    width: 45px;
    height: 25px;
`

const InvoicePaid = styled(StatusWrapper)`
    background-color: #3a9350;
    color: white;
`

const InvoiceAttention = styled(StatusWrapper)`
    background-color: #ffda4d;
`

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`

export const RegistryTable = ({ deleteRegistry, data, setOrdersToDocks, setSelectedRowKeys, selectedRowKeys, socket, getRegistry, company }) => {

  const [openRegistryModal, setOpenRegistryModal] = useState(false);
  const [registryOrders, setRegistryOrders] = useState();

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {

  }, [data])

  const closeRegistryModal = () => {
    setOpenRegistryModal(false);
  }

  const DataTable = () => {
    const rowClassName = (record, index) => {
      return index % 2 === 0 ? 'even-row-table' : 'odd-row-table';
    };

    const columns = useMemo(
      () => [
        {
          title: '№',
          dataIndex: 'numberForDocuments',
          key: 'numberForDocuments',
          width: 40,
          filterSearch: true,
          filters: data ? data.map(el => ({ text: el.numberForDocuments, value: el.numberForDocuments })) : null,
          onFilter: (value, record) => record.numberForDocuments.startsWith(value),
          render: (text, row) =>
            <TextPlace>
              {
                row.status === 'Paid' ? <InvoicePaid>{text}</InvoicePaid> :
                  row.status === 'Attention' ? <InvoiceAttention>{text}</InvoiceAttention> :
                    <StatusWrapper>{text}</StatusWrapper>
              }
            </TextPlace>,
        },
        {
          title: 'Дата',
          dataIndex: 'date',
          key: 'date',
          width: 40,
          render: (text) => text ? <TextPlace><b>{moment(text).format('DD/MM/YY')}</b></TextPlace> : null,
        },
        {
          title: 'Контрагент',
          dataIndex: 'agent',
          key: 'agent',
          width: 40,
          render: (text) => text ? <TextPlace>{text.shortName}</TextPlace> : null,
        },
        {
          title: 'ИНН',
          dataIndex: 'inn',
          key: 'inn',
          width: 40,
        },
        {
          title: 'КПП',
          dataIndex: 'kpp',
          key: 'kpp',
          width: 40,
        },
        {
          title: 'Комментарии',
          dataIndex: 'comment',
          key: 'comment',
          width: 40,
        },
        {
          title: 'Действия',
          dataIndex: 'operation',
          width: 60,
          render: (_, record) => (
            <Space>
              <Tooltip title="Удалить">
                <IconButton onClick={() => deleteRegistry(record._id)} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                  icon={
                    <X style={{ 'color': '#ef423e', 'width': '18px', 'height': '18px' }} />
                  } />
              </Tooltip>
              <Tooltip title="Документы">
                <IconButton onClick={() => setOrdersToDocks(record._id, company)} style={{ 'color': '#001529', 'width': '20px', 'height': '20px' }}
                  icon={
                    <Folder2 style={{ 'color': '#464541', 'width': '18px', 'height': '18px' }} />
                  } />
              </Tooltip>
            </Space>
          ),
        }
      ],
      [],
    );


    return (
      <ConfigProvider locale={ru_RU}>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          rowSelection={rowSelection}
          rowClassName={rowClassName}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setRegistryOrders(record);
                setOpenRegistryModal(true)
              },
            };
          }}
        />
      </ConfigProvider>
    );
  }
  return (
    <>
      <DataTable />
      <Modal
        title="Маршруты"
        open={openRegistryModal}
        onCancel={closeRegistryModal}
        footer={null}
        destroyOnClose={true}
        width={1200}
        style={{ top: 10 }}
      >
        <RegistryItemsModal data={registryOrders} socket={socket} closeRegistryModal={closeRegistryModal} getRegistry={getRegistry} />
      </Modal>
    </>

  );

}