import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PlanningService from '../../services/PlanningService';
import { Tabs } from 'antd';
import PlanningText from './PlanningText';
import { Link } from 'react-router-dom';


const Wrapper = styled.div`
    padding: 0 15px;
`

const Planning = ({ openNotification }) => {

    
    const [types, setTypes] = useState([])
    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const getTypes = useCallback( async () => {
        const res = await PlanningService.getTypes({ company_id: userCompany })
        setTypes(res.data);
    }, [userCompany])

    useEffect(() => {
        getTypes();
        document.title = "Планирование - ГрузДок - сервис учета грузоперевозок"
    }, [getTypes])



    return (
        <Wrapper>
            {
                types ?
                    <Tabs defaultActiveKey='0' destroyInactiveTabPane items={
                        types.map((el, index) => ({
                            key: index,
                            label: el.name,
                            children: <PlanningText userCompany={userCompany} openNotification={openNotification} type={types[index]}/>
                        }))
                    }/> :

                    <>
                        Перейдите в <Link to='/settings'>Настройки</Link> и создайте тип планирования (доступно только Администратору)
                    </>
            }

        </Wrapper>
    );
};

export default Planning;