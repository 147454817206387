import React from 'react';
import { Tabs } from 'antd';
import Companies from './Companies';
import Users from './Users';

const MainAPage = ({openNotification}) => {

    const items = [
        {
            key: '1',
            label: 'Компании',
            children: <Companies openNotification={openNotification}/>,
        },
        {
            key: '2',
            label: 'Пользователи',
            children: <Users openNotification={openNotification}/>,
        },
    ];


    return (
        <div>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    );
};

export default MainAPage;