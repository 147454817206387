import React from 'react';
import { Form, Input, Space } from 'antd';
import DriversSettings from '../../services/DriversSettings';
import { ButtonPrimary } from '../../styles/buttons';

const AddDriverType = ({ setOpen, getDriversTypes, openNotification, userCompany }) => {

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const res = await DriversSettings.create({...values, company_id: userCompany});
        if (res.data.message === 'success') {
            await getDriversTypes();
            setOpen(false);
            openNotification('Успешно!', 'Новый тип водителей успешно создан!', 'info')
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div>
            <Form
                name="edit"
                form={form}
                labelAlign='left'
                style={{
                    width: 300,
                }}
                layout='horizontal'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={{

                }}
            >
                <Form.Item
                    label="Название"
                    name="typeName"
                >
                    <Input />
                </Form.Item>
                <Space>
                    <ButtonPrimary type="primary" htmlType="submit">
                        Сохранить
                    </ButtonPrimary>
                </Space>
            </Form>
        </div>
    );
};

export default AddDriverType;