import { ConfigProvider, DatePicker, Form, Input } from 'antd';
import React from 'react';
import driversService from '../../services/DriverService';
import ru_RU from 'antd/lib/locale/ru_RU';
import { ButtonPrimary } from '../../styles/buttons';
import dayjs from 'dayjs';

const { TextArea } = Input;

const EditPayment = ({ setOpen, openPayment, getPaymentsInfo }) => {


    const onFinish = async (values) => {
        values._id = openPayment._id;
        await driversService.editPayment(values)
        setOpen(false);
        await getPaymentsInfo();
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div>
            <Form
                name="editPayment"
                layout='horizontal'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={{
                    ...openPayment,
                    date:  dayjs(openPayment.date, 'DD/MM/YYYY'),
                }}

            >
                <ConfigProvider locale={ru_RU}>
                    <Form.Item
                        label="Дата"
                        name="date"
                    >
                        <DatePicker format={'DD/MM/YYYY'} locale={ru_RU} />
                    </Form.Item>
                </ConfigProvider>

                <Form.Item
                    label="Сумма"
                    name="sum"
                >
                    <Input type='number' />
                </Form.Item>

                <Form.Item
                    label="Комментарий"
                    name="comment"
                >
                    <TextArea />
                </Form.Item>

                <ButtonPrimary type="primary" htmlType="submit">
                    Сохранить
                </ButtonPrimary>
            </Form>
        </div>
    );
};

export default EditPayment;