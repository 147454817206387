import { Modal, Space, Table } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import { ButtonPrimary } from '../../styles/buttons';
import AddDriverType from './AddDriverType';
import DriversSettings from '../../services/DriversSettings';
import { useSelector } from 'react-redux';

const Drivers = ({ openNotification }) => {

    const [driversType, setDriversType] = useState();
    const [open, setOpen] = useState(false);

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const getDriversTypes = useCallback( async () => {
        const res = await DriversSettings.get({ company_id: userCompany });
        console.log(res.data)
        setDriversType(res.data)
    }, [userCompany])

    useEffect(() => {
        getDriversTypes();
    }, [getDriversTypes])





    const deleteType = async (record) => {
        const res = await DriversSettings.delete(record);
        if (res.data.message === 'success') {
            await getDriversTypes();
            openNotification('Успешно!', 'Тип водителей удален', 'info')
        }
    }

    const columns = [
        {
            title: 'Имя',
            dataIndex: 'typeName',
            key: 'typeName',
        },
        {
            title: 'Действия',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {
                        record.canDelete === true ?
                            <ButtonPrimary onClick={() =>
                                Modal.confirm({
                                    onOk: () => {
                                        deleteType(record)
                                    },
                                    title: 'Удалить',
                                    content: 'Подтвердите удаление',
                                    footer: (_, { OkBtn, CancelBtn }) => (
                                        <>
                                            <CancelBtn />
                                            <OkBtn />
                                        </>
                                    ),
                                })
                            }>Удалить</ButtonPrimary> : null
                    }

                </Space>
            ),
        },
    ]

    const handleCancel = () => {
        setOpen(false);
    }

    const addPaymentType = () => {
        setOpen(true);
    }


    return (
        <div>
            {
                driversType &&
                <Table columns={columns} dataSource={driversType.map((el, index) => {
                    return {
                        ...el,
                        key: index,
                    }
                })} />
            }

            <ButtonPrimary onClick={addPaymentType}>Добавить тип водителя</ButtonPrimary>

            <Modal
                title="Новый тип водителей"
                open={open}
                onCancel={handleCancel}
                footer={null}
                destroyOnClose={true}
                width={350}
                style={{ top: 10 }}
            >
                <AddDriverType setOpen={setOpen} getDriversTypes={getDriversTypes} openNotification={openNotification} userCompany={userCompany}></AddDriverType>
            </Modal>
        </div>
    );
};

export default Drivers;