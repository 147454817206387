import React, { useEffect, useState } from 'react';
import { ButtonsWrapper, ButtonPrimary } from '../../styles/buttons';
import { Modal, Space, Table } from 'antd';
import AddNewCompanyModal from './AddNewCompanyModal';
import CompaniesService from '../../services/CompaniesService';

const Companies = ({ openNotification }) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            hidden: true,
        },
        {
            title: 'Название',
            dataIndex: 'shortName',
            key: 'shortName',
        },
        {
            title: 'ИНН',
            dataIndex: 'inn',
            key: 'inn',
        },
        {
            title: 'Действия',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <ButtonPrimary onClick={() => editCompany(record)}>Изменить</ButtonPrimary>
                    <ButtonPrimary onClick={() => deleteCompany(record)}>Удалить</ButtonPrimary>
                </Space >
            ),
        },
    ].filter(item => !item.hidden);

const [companies, setCompanies] = useState([]);
const [addCompany, setOpenAddCompany] = useState(false);

useEffect(() => {
    getCompanies();
}, [])

const deleteCompany = async (record) => {
    if (record.shortName === 'AdminCompany') {
        openNotification('Ошибка', 'Нельзя удалить компанию', 'error')
    } else {
        Modal.confirm({
            onOk: async () => {
                await CompaniesService.deleteMainCompany({ _id: record.id })
                await getCompanies()
            },
            title: 'Внимание!!!',
            content: 'Это действие повлечет за собой УДАЛЕНИЕ ВСЕХ данных, связанных с этой компанией!',
            footer: (_, { OkBtn, CancelBtn }) => (
                <>
                    <CancelBtn />
                    <OkBtn />
                </>
            ),
        })

    }
}

const editCompany = async (record) => {
    if (record.shortName === 'AdminCompany') {
        openNotification('Ошибка', 'Нельзя изменить компанию', 'error')
    } else {
        // console.log(record)
    }
}

const getCompanies = async () => {
    const companies = await CompaniesService.getMainCompany();
    if (companies?.data) {
        setCompanies(companies.data)
    }
}

const handleCancelAddUser = () => {
    setOpenAddCompany(false)
}


return (
    <div>
        <ButtonsWrapper>
            <ButtonPrimary onClick={() => setOpenAddCompany(true)}>
                Добавить компанию
            </ButtonPrimary>
        </ButtonsWrapper>
        {
            companies ?
                <Table dataSource={companies.map((el, index) => ({
                    id: el._id,
                    key: index,
                    shortName: el.shortName,
                    inn: el.inn,
                }))}
                    columns={columns} />
                : null
        }
        <Modal
            title="Добавление новой компании"
            open={addCompany}
            destroyOnClose={true}
            footer={null}
            width={600}
            style={{ top: 10 }}
            onCancel={handleCancelAddUser}
        >
            <AddNewCompanyModal setOpenAddCompany={setOpenAddCompany} getCompanies={getCompanies} openNotification={openNotification} />
        </Modal>
    </div>
);
};

export default Companies;