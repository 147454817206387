import React, { useState, useEffect, useRef, useCallback } from 'react';
import { HandThumbsUpFill, EmojiExpressionlessFill, ExclamationOctagonFill, App } from 'react-bootstrap-icons';
import RegistryService from '../../services/RegistryService';
import DocksFiles from './DocksFiles';
import DocksService from '../../services/DocsService';
import { ConfigProvider, Modal, Tooltip, DatePicker, Button, Tabs } from 'antd';
import styled from 'styled-components';
import { ButtonsWrapper } from '../../styles/buttons';
import ru_RU from 'antd/lib/locale/ru_RU';
import { RegistryTable } from './RegistryTable';
import AgentsService from '../../services/AgentsService';
import CompaniesService from '../../services/CompaniesService';
import { changeStatus } from '../Orders/components/RegistryStatus';
import { DWN_URL } from '../../http';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  width: 100%;
  margin: 16px auto;
  padding: 20px 20px;
  background: #fff;
  min-height: calc(100vh - 165px);
`

const ButtonsFlex = styled(ButtonsWrapper)`
    diplay: flex;
    justify-content: space-between;
    background-color: #001529;
    padding: 16px 0;
`

const IconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`

const { RangePicker } = DatePicker;

const Registry = ({ openNotification, socket }) => {

    const [registry, setRegistry] = useState('');
    const [openDocks, setOpenDocs] = useState(false);
    const [openedRegistry, setOpenRegistry] = useState('');
    const [rowSelection, setRowSelection] = useState({});
    const [currentTableData, setCurrentTableData] = useState();
    const [currentDateRange, setCurrentDateRange] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);
    const paymentTypes = useRef();

    const roomName = `Registry ${userCompany}`;

    const getRegistry = useCallback( async (data) => {
        let res;
        if (!data) {
            res = await RegistryService.get({ company_id: userCompany });
        } else {
            res = data;
        };
        const payData = await CompaniesService.get({ company_id: userCompany });
        paymentTypes.current = payData.data;
        setRegistry(res.data)
        setCurrentTableData(res.data.filter(el => el.paymentType === paymentTypes.current[0]._id))
    },[userCompany] )

    const getSocketConnect = useCallback( async () => {
        socket.on('update', async (data) => {
            await getRegistry();
        });
    }, [getRegistry, socket] )

    useEffect(() => {
        getRegistry();
        socket.emit('enterRoom', roomName);
        getSocketConnect();
        document.title = "Бухгалтерия - ГрузДок - сервис учета грузоперевозок"
        return () => {
            socket.emit('leaveRoom', roomName);
        };
    }, [getRegistry, getSocketConnect, roomName, socket])





    const closeOpenDocks = () => {
        setOpenDocs(false)
    }

    const setDateRange = async (value) => {
        setRowSelection({});
        setCurrentDateRange({});
        if (value) {
            const dateStart = new Date(value[0].$d);
            const dateEnd = new Date(value[1].$d);
            dateStart.setHours(0, 0, 0, 0);
            dateEnd.setHours(23, 59, 0, 0);
            const orders = await RegistryService.get({
                date: {
                    $gte: dateStart, // Больше или равно начальной дате
                    $lte: dateEnd,    // Меньше или равно конечной дате
                }

            });
            setCurrentDateRange({ dateStart, dateEnd });
            getRegistry(orders)
        } else {
            setRowSelection({});
            getRegistry();
        }
    }

    const setOrdersToDocks = async (data) => {
        const reg = await RegistryService.get({ _id: data })
        const res = await AgentsService.get({ shortName: reg.data[0].agent.shortName })
        reg.data[0].agent[0] = res.data;
        reg.data[0].company = paymentTypes.current.find(el => el._id === reg.data[0].paymentType);
        setOpenRegistry(reg.data[0])
        setOpenDocs(true);
    }


    const setStatus = async (type) => {
        for (const item of selectedRowKeys) {
            const reg = registry.find(el => el._id === item)
            await changeStatus(item, type, reg.orders);
        }
        await getRegistry();
        setSelectedRowKeys([]);
        socket.emit('message', {
            text: 'update',
            room: `Registry ${userCompany}`,
            id: `${socket.id}${Math.random()}`,
            socketID: socket.id,
        });

        socket.emit('message', {
            text: 'update',
            room: `Orders ${userCompany}`,
            id: `${socket.id}${Math.random()}`,
            socketID: socket.id,
        });
    }

    const onChangeTab = (key) => {
        setCurrentTableData(registry.filter(el => el.paymentType === paymentTypes.current[key]))
        setRowSelection({});
    };

    const exportOneC = async () => {
        if (currentDateRange && Object.keys(currentDateRange).length > 0) {
            const arr = Object.keys(rowSelection);
            if (arr.length > 0) {
                const sendArr = [];
                for (const item of arr) {
                    if (currentTableData[item] !== undefined) {
                        sendArr.push(currentTableData[item])
                    }
                }
                const res = await DocksService.createExport({ dates: currentDateRange, registries: sendArr, company: currentTableData[0].paymentType });
                if (res?.data?.url) {
                    const link = document.createElement('a');
                    link.download = 'Example-PDF-File';
                    link.href = `${DWN_URL}/files/${res.data.url}`;
                    link.click();
                }
                setRowSelection({});
            } else {
                openNotification('Ошибка!', 'Ничего не выбрано', 'error')
            }

        } else {
            openNotification('Ошибка!', 'Не выбраны даты', 'error')
        }
    }

    const deleteRegistry = async (id) => {
        const deletedReg = registry.find(el => el._id === id)
        await RegistryService.delete({ _id: id, orders: deletedReg.orders})
        socket.emit('message', {
            text: 'update',
            room: `Orders ${userCompany}`,
            id: `${socket.id}${Math.random()}`,
            socketID: socket.id,
        });
        await getRegistry();
    }

    let tabs = null;

    if (paymentTypes.current) {
        tabs = paymentTypes.current.map((type, index) => {
            if (registry.length > 0) {
                const data = registry.filter(el => el.paymentType === type._id).map(el => { return { ...el, agent: { _id: el.agent._id, shortName: el.agent.shortName }, inn: el.agent.inn, kpp: el.agent.kpp, paymentType: el.agent.paymentType, key: el._id } })
                return {
                    key: index,
                    label: type.shortName,
                    children: registry &&
                        <RegistryTable
                            data={data}
                            socket={socket}
                            deleteRegistry={deleteRegistry}
                            setOrdersToDocks={setOrdersToDocks}
                            getRegistry={getRegistry}
                            company={type}
                            setSelectedRowKeys={setSelectedRowKeys}
                            selectedRowKeys={selectedRowKeys}
                        />
                }
            } else {
                return {
                    key: index,
                    label: type.shortName,
                }
            }
        })
    }

    return (
        <>
            <ButtonsFlex>
                <ButtonsWrapper>
                    <Tooltip title="Счет оплачен">
                        <IconButton onClick={() => setStatus('Paid')} style={{ 'color': 'rgb(58, 147, 80)' }} icon={<HandThumbsUpFill />}></IconButton>
                    </Tooltip>
                    <Tooltip title="Обратить внимание">
                        <IconButton onClick={() => setStatus('Attention')} style={{ 'color': '#dcbc41' }} icon={<ExclamationOctagonFill />}></IconButton>
                    </Tooltip>
                    <Tooltip title="Убрать статус">
                        <IconButton onClick={() => setStatus('Created')} style={{ 'color': '#33363b' }} icon={<App />}></IconButton>
                    </Tooltip>
                </ButtonsWrapper>
                <ButtonsWrapper>
                    <ConfigProvider locale={ru_RU}>
                        <RangePicker onChange={setDateRange} />
                    </ConfigProvider>
                </ButtonsWrapper>
                <ButtonsWrapper>
                    {/* <Tooltip title="Скачать счет">
                        <IconButton onClick={() => setStatus('InvoicePaid')} style={{ 'color': 'rgb(58, 147, 80)' }} icon={<HandThumbsUpFill />}></IconButton>
                    </Tooltip> */}
                    <Tooltip title="Экспорт в 1с">
                        <IconButton onClick={exportOneC} style={{ 'color': '#001529' }} icon={<EmojiExpressionlessFill />}></IconButton>
                    </Tooltip>
                </ButtonsWrapper>
            </ButtonsFlex>

            <Wrapper>

                {
                    registry.length > 0 && paymentTypes.current ?
                        <Tabs defaultActiveKey="1" items={tabs} onChange={onChangeTab} type="card" /> : null
                }

                <Modal
                    title="Формирование документов"
                    open={openDocks}
                    onCancel={closeOpenDocks}
                    footer={null}
                    destroyOnClose={true}
                    width='100%'
                    style={{ top: 10 }}
                    afterClose={getRegistry}
                >
                    <DocksFiles closeOpenDocks={closeOpenDocks} registry={registry} setOpenRegistry={setOpenRegistry} openedRegistry={openedRegistry} setOrdersToDocks={setOrdersToDocks} userCompany={userCompany} openNotification={openNotification}/>
                </Modal>
            </Wrapper>
        </>

    );
};

export default Registry;