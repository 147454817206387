import React from 'react';
import { Tabs } from 'antd';
import AddOfficial from './AddOfficial';
import AddPhysical from './AddPhysical';
import { useSelector } from 'react-redux';

const AddNewCompany = ({ setOpen }) => {

    const userCompany = useSelector((state) => state.userInfo.user.user.company_id);

    const items = [
        {
            key: '1',
            label: 'ООО или ИП',
            children: <AddOfficial setOpen={setOpen} userCompany={userCompany}/>,
        },
        {
            key: '2',
            label: 'Физ. лицо',
            children: <AddPhysical setOpen={setOpen} userCompany={userCompany}/>,
        },
    ];

    return (
        <>
            <Tabs defaultActiveKey="1" items={items} />
        </>
    );
};

export default AddNewCompany;