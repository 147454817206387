import $api from '../http';

export default class AuthService {
    static async login(email, password){
        try {
            return await $api.post('/login', {email, password});
        } catch(e) {
            // console.log(e)
        }
    }

    static async registration({email, password, name, surName, role, company_id}){
        try {
            return await $api.post('/registration', {email, password, name, surName, role, company_id})
        } catch(e) {
            console.log(e)
        }
    }

    static async logout(){
        try {
            return $api.post('/logout')
        } catch(e) {
            console.log(e)
        }
    }

    static async checkAuth(){
        try {
            return await $api.get(`/refresh`, {withCredentials: true});
        } catch(e) {
            console.log(e)
        }
    }

    static async updateProfileApi(apiStatistics, apiStandart, email){
        try {
            return await $api.post('/update', {apiStatistics, apiStandart, email})
        } catch(e) {
            console.log(e)
        }
    }
}